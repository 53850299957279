<template>
    <div id="oidc-error">
        <div v-if="error" style="color:red">{{error}} - {{clientId}}</div>
        <div>Hãy thử xóa cache trình duyệt và thử lại.</div>
        <div>Bấm tổ hợp phím: <b>Ctrl Shift Del</b>, cửa sổ hiện ra, làm như hướng dẫn:</div>
        <div><bnd-web-static-img src="id/remove-cache.png" alt="" /></div>
        <a href="javascript:void(0)" @click="$router.go(-1)">Quay lại</a>
    </div>
</template>
<script type="text/javascript">
    export default {
        data() {
            return { error: '', clientId: '' };
        },
        async created() {
            const query = window.location.search;
            const errorIdQuery = query && query.toLowerCase().indexOf('?errorid=') == 0 && query;

            const response = await fetch(`${this.$api.ACCOUNT_GET_ERROR}${errorIdQuery}`, {
                credentials: 'include',
            });
            const data = await response.json();
            this.error = data.result.errorDescription;
            this.clientId = data.result.clientId;
        },
    };
</script>
<style>
    #oidc-error { width: 600px; text-align: center; margin: 100px auto; padding: 30px; border: solid 1px red; }
        #oidc-error a { margin-top: 20px; }
        #oidc-error img { max-width: 100%; margin-top: 10px; }
</style>
