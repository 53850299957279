<template>
    <div class="wrap" id="app" v-if="currentUser && !initializing">
        <bnd-web-auth/>
        <!-- Bỏ điền sẵn user, pass vào textbox -->
        <div  style="opacity:0; visibility:hidden; position:absolute">
            <input type="text" name="mail" style="opacity:0">
            <input type="text" name="username" style="opacity:0">
            <input type="password" name="password" style="opacity:0">
        </div>
        <bnd-web-header />
      

       
        <!-- Main -->
        <div class="main-page">
            <div class="layout-main-page">
                <div class="block-sidebar-left">
                    <bnd-web-left-menu />
                    <!-- All Product -->
                    <bnd-web-product />
                </div>
                <router-view></router-view>
                <div class="wp-toolbar-right">
                    <!-- All Tools -->
                    <!--Todo: Tạm ẩn-->
                    <!--<bnd-web-toolbar />-->
                </div>
            </div>
        </div>
      

       

        <!-- Footer -->
        <bnd-web-popup-footer />

        <bnd-web-to-top />
    </div>
</template>
<script>
    import { mapMutations, mapActions } from 'vuex';

    export default {
        data() {
            return {
                initializing: true
            }
        },
        async created() {
            this.$auth.getProfile().then((profile) => {
                this.setUserInfo(profile);
            });
            this.initSystem().then(() => {
                this.initializing = false;
            });
            this.removeStorageData();
            this.clearOIDC();
        },
        methods: {
            ...mapMutations({ setUserInfo: 'user/updateInfo' }),
            ...mapActions('system', ['initSystem']),
            clearOIDC() {
                try {
                    if (typeof (window) != "undefined") {
                        if (window.oidcInterval)
                            clearInterval(window.oidcInterval);
                        window.oidcInterval = setInterval(() => {
                            this.removeStorageData();
                        }, 60 * 1000)
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            removeStorageData() {
                if (typeof (localStorage) != "undefined") {
                    var keys = Object.keys(localStorage)
                    if (keys) {
                        keys = keys.filter(k => k.startsWith('oidc') && !k.startsWith('oidc.user'))
                        for (let k of keys) {
                            try {
                                let val = localStorage.getItem(k);
                                if (val) {
                                    val = JSON.parse(val);
                                    if (val) {
                                        let created = new Date(val.created * 1000);
                                        let now = new Date();
                                        if (this.getMinutesBetweenDates(created, now) > 60) {
                                            localStorage.removeItem(k);
                                            console.log('remove item: ' + k);
                                        }
                                    }
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }
            },
            getMinutesBetweenDates(startDate, endDate) {
                const diff = endDate.getTime() - startDate.getTime();
                return (diff / 60000);
            }
        },
    };
</script> 
