module.exports = {
    block: 'Id',
    systemApiEndpoint: "https://man.vitanedu.com/api/system",
    fileApiEndpoint: "https://man.vitanedu.com/api/system",
    commonDataApiEndpoint: "https://man.vitanedu.com/api/common-data",
    realTimeEndpoint: "https://rt.vitanedu.com/systemHub",
    paymentUrl: "https://vitanedu.com/checkout",
    paymentApiUrl: "https://vitanedu.com",
    messengerApiUrl: "https://vitanedu.com",
    cdnUrl: "https://cdn.vitanedu.net",
    blockDomain: {
        //11: 'https://vitanedu.com' //Contest
    }
};
