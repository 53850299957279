<template>
    <ul class="pagination" v-if="pageCount > 1">
        <li v-if="firstLastButton" :class="[pageClass, firstPageSelected() ? disabledClass : '']">
            <a @click="selectFirstPage()" @keyup.enter="selectFirstPage()" :class="pageLinkClass" :tabindex="firstPageSelected() ? -1 : 0" v-html="firstButtonText"></a>
        </li>

        <li v-if="!(firstPageSelected() && hidePrevNext)" :class="[prevClass, firstPageSelected() ? disabledClass : '']">
            <a @click="prevPage()" @keyup.enter="prevPage()" :class="prevLinkClass" :tabindex="firstPageSelected() ? -1 : 0">
                <svg viewBox="64 64 896 896" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg>
            </a>
        </li>

        <li v-for="page in pages" :class="[pageClass, page.selected ? activeClass : '', page.disabled ? disabledClass : '', page.breakView ? breakViewClass: '']">
            <a v-if="page.breakView" :class="[pageLinkClass, breakViewLinkClass]" tabindex="0"><slot name="breakViewContent">{{ breakViewText }}</slot></a>
            <a v-else-if="page.disabled" :class="pageLinkClass" tabindex="0">{{ page.content }}</a>
            <a v-else @click="handlePageSelected(page.index + 1)" @keyup.enter="handlePageSelected(page.index + 1)" :class="{pageLinkClass:selected===page.index+1, unSelect:selected!==page.index+1}" tabindex="0">{{ page.content }}</a>
        </li>

        <li v-if="!(lastPageSelected() && hidePrevNext)" :class="[nextClass, lastPageSelected() ? disabledClass : '']">
            <a @click="nextPage()" @keyup.enter="nextPage()" :class="nextLinkClass" :tabindex="lastPageSelected() ? -1 : 0" >
                <svg viewBox="64 64 896 896" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
            </a>
        </li>

        <li v-if="firstLastButton" :class="[pageClass, lastPageSelected() ? disabledClass : '']">
            <a @click="selectLastPage()" @keyup.enter="selectLastPage()" :class="pageLinkClass" :tabindex="lastPageSelected() ? -1 : 0">
                <svg viewBox="64 64 896 896" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            value: {
                type: Number
            },
            pageSize: {
                type: Number,
                required: true
            },
            total: {
                type: Number,
                required: true
            },
            forcePage: {
                type: Number
            },
            clickHandler: {
                type: Function,
                default: () => { }
            },
            pageRange: {
                type: Number,
                default: 5
            },
            marginPages: {
                type: Number,
                default: 1
            },
            prevText: {
                type: String,
                default: '<'
            },
            nextText: {
                type: String,
                default: '>'
            },
            breakViewText: {
                type: String,
                default: '…'
            },
            containerClass: {
                type: String
            },
            pageClass: {
                type: String
            },
            pageLinkClass: {
                type: String
            },
            prevClass: {
                type: String
            },
            prevLinkClass: {
                type: String
            },
            nextClass: {
                type: String
            },
            nextLinkClass: {
                type: String
            },
            breakViewClass: {
                type: String
            },
            breakViewLinkClass: {
                type: String
            },
            activeClass: {
                type: String,
                default: 'active'
            },
            disabledClass: {
                type: String,
                default: 'disabled'
            },
            noLiSurround: {
                type: Boolean,
                default: false
            },
            firstLastButton: {
                type: Boolean,
                default: false
            },
            firstButtonText: {
                type: String,
                default: 'First'
            },
            lastButtonText: {
                type: String,
                default: 'Last'
            },
            hidePrevNext: {
                type: Boolean,
                default: false
            }
        },
        beforeUpdate() {
            if (this.forcePage === undefined) return
            if (this.forcePage !== this.selected) {
                this.selected = this.forcePage
            }
        },
        computed: {
            pageCount() {
                return parseInt(((this.total - 1) / this.pageSize)) + 1;
            },
            selected: {
                get: function () {
                    return this.value || this.innerValue
                },
                set: function (newValue) {
                    this.innerValue = newValue
                }
            },
            pages: function () {
                let items = {}
                if (this.pageCount <= this.pageRange) {
                    for (let index = 0; index < this.pageCount; index++) {
                        let page = {
                            index: index,
                            content: index + 1,
                            selected: index === (this.selected - 1)
                        }
                        items[index] = page
                    }
                } else {
                    const halfPageRange = Math.floor(this.pageRange / 2)

                    let setPageItem = index => {
                        let page = {
                            index: index,
                            content: index + 1,
                            selected: index === (this.selected - 1)
                        }

                        items[index] = page
                    }

                    let setBreakView = index => {
                        let breakView = {
                            disabled: true,
                            breakView: true
                        }

                        items[index] = breakView
                    }

                    // 1st - loop thru low end of margin pages
                    for (let i = 0; i < this.marginPages; i++) {
                        setPageItem(i);
                    }

                    // 2nd - loop thru selected range
                    let selectedRangeLow = 0;
                    if (this.selected - halfPageRange > 0) {
                        selectedRangeLow = this.selected - 1 - halfPageRange;
                    }

                    let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                    if (selectedRangeHigh >= this.pageCount) {
                        selectedRangeHigh = this.pageCount - 1;
                        selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                    }

                    for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                        setPageItem(i);
                    }

                    // Check if there is breakView in the left of selected range
                    if (selectedRangeLow > this.marginPages) {
                        setBreakView(selectedRangeLow - 1)
                    }

                    // Check if there is breakView in the right of selected range
                    if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                        setBreakView(selectedRangeHigh + 1)
                    }

                    // 3rd - loop thru high end of margin pages
                    for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                        setPageItem(i);
                    }
                }
                return items
            }
        },
        data() {
            return {
                innerValue: 1,
            }
        },
        methods: {
            handlePageSelected(selected) {
                if (this.selected === selected) return

                this.innerValue = selected
                this.$emit('input', selected)
                this.$emit('change', selected)
            },
            prevPage() {
                if (this.selected <= 1) return

                this.handlePageSelected(this.selected - 1)
            },
            nextPage() {
                if (this.selected >= this.pageCount) return

                this.handlePageSelected(this.selected + 1)
            },
            firstPageSelected() {
                return this.selected === 1
            },
            lastPageSelected() {
                return (this.selected === this.pageCount) || (this.pageCount === 0)
            },
            selectFirstPage() {
                if (this.selected <= 1) return

                this.handlePageSelected(1)
            },
            selectLastPage() {
                if (this.selected >= this.pageCount) return

                this.handlePageSelected(this.pageCount)
            }
        }
    }
</script>

<style scoped>
    .pagination {
        display: inline-block;
        padding-left: 0;
        margin: 0;
        border-radius: 5px;
    } 

        .pagination > li {
            display: inline;
        }

            .pagination > li > a, .pagination > li > span {
                position: relative;
                float: left;
                width: 35px;
                height: 35px;
                line-height: 33px;
                background: transparent;
                border-radius: 5px;
                margin-right: 5px;
                text-align: center;
                -webkit-transition: all .3s;
                -o-transition: all .3s;
                transition: all .3s;
                background: #f1f1f1;
                border: 1px solid #f1f1f1;
                cursor: pointer;
            }

            .pagination > li:first-child > a, .pagination > li:first-child > span {
                margin-left: 0;
            }

            .pagination > li > a:hover, .pagination > li > span:hover {
                z-index: 3;
                border-color: #1890ff;
            }

        .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
      
        }
            .pagination > .active > a {
                z-index: 2;
                border-color: #1890ff;
                cursor: pointer;
            }

        .pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
            background: #f6f5f5;
            border: 0;
            cursor: not-allowed;
        }
 
</style>
