<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box type2">
                    <div class="box-head">
                        <h2>{{ $tBnd("phone_manager") }}</h2>
                    </div>
                    <ul class="box-ct lst-ct lst-manager mt-2">
                        <li v-for="item in listphone"
                            :key="item.id"
                            v-bind:class="{
                'is-verified': item.isConfirmed,
                'itm-txt': !item.isConfirmed,
              }">
                            <span class="itm-txt">{{ item.name }}</span>
                            <span class="email-default" v-if="item.isPrimary">{{$tBnd("phone_primary") }}</span>
                            <div class="lst-btn d-flex ct-lst-btn" v-else>
                                <a href="javascript:void(0):"
                                   v-if="item.isConfirmed"
                                   class="itm-link">
                                    <div class="form-check form-check-inline checkbox-check">
                                        <input class="form-check-input"
                                               v-model="item.allowLogin"
                                               type="checkbox" @change="changeAllowLogin(item)"
                                               :id="`fixed-addr_${item.id}`" />
                                        <label class="form-check-label" :for="`fixed-addr_${item.id}`">
                                            {{ $tBnd("allow_login") }}
                                        </label>
                                    </div>
                                </a>
                                <!--Todo: Tạm khóa xác thực-->
                                <!--<a href="javascript:void(0):"
                                   @click="showVerifyPopup(item.name)"
                                   v-if="!item.isConfirmed"
                                   class="itm-link">{{ $tBnd("phone_verify") }}</a>-->
                                <!--<a href="javascript:void(0):"
                                   @click="setPhonePrimary(item.id)"
                                   v-else
                                   class="itm-link">{{ $tBnd("set_phone_primary") }}</a>-->
                                <a href="javascript:void(0);"
                                   class="btn-delete"
                                   @click="showDelete(item)">{{$tBnd("delete")}}</a>
                            </div>
                        </li>
                    </ul>
                    <a class="add-new-addr mt-3"  v-if="!listphone || listphone.length <= $constants.commonConfig.MaximumEmailOrPhone"
                       href="javascript:void(0):"
                       @click="showCreatephone"><i class="plus">+</i> {{ $tBnd("add_phone") }}</a>
                </div>
            </div>
        </div>
        <modalcustom v-if="showCreate">
            <div class="modal fade popup show" slot="content">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <!-- HTML của phần thêm phone mới -->
                        <div class="modal-header justify-content-center">
                            <h5 class="modal-title">{{ $tBnd("add_phone") }}</h5>
                        </div>
                        <div class="modal-body type2">
                            <div class="custom-input mt-3 mb-2">
                                <input type="phone"
                                       v-model="phone"
                                       id="phone"
                                       name="phone"
                                       class="form-control ipt"
                                       @keyup.enter="Createphone"
                                       :class="{ 'is-invalid': submitted && $v.phone.$error }" />
                                <div v-if="submitted && $v.phone.$error"
                                     class="invalid-feedback">
                                    <span v-if="!$v.phone.required">
                                        {{ $tBnd("phone_required") }}
                                    </span>
                                </div>
                                <div v-else-if="invalidPhoneNumber"
                                     class="invalid-feedback">
                                    <span>
                                        {{ $tBnd("invalid_phone_number") }}
                                    </span>
                                </div>
                                <label for="addphone" class="lbl">
                                    {{ $tBnd("phone_add_lbl") }}
                                </label>
                            </div>
                            <!--Todo: Tạm khóa tính năng xác thực qua sđt-->
                            <!--<span class="txt">{{ $tBnd("phone_note") }}</span>-->
                        </div>
                        <div class="modal-footer justify-content-around">
                            <button type="button"
                                    class="btn btn-light"
                                    data-dismiss="modal"
                                    @click="showCreatephone">
                                {{ $tBnd("phone_cancel") }}
                            </button>
                            <button type="button"
                                    class="btn btn-complete"
                                    @click="Createphone">
                                {{ $tBnd("phone_save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modalcustom>
        <!--Todo: tạm khóa xác thực sđt-->
        <modalcustom v-if="showVerify && false">
            <div class="modal fade popup show" slot="content">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- HTML của phần xác thực phone vừa thêm -->
                        <div class="modal-header justify-content-center">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                {{ $tBnd("phone_verify_opt") }}
                            </h5>
                        </div>
                        <div class="modal-body type2">
                            <span class="txt"> {{ $tBnd("phone_verify_note") }}</span>
                            <form class="ipt-code">
                                <v-otp-input ref="otpInput"
                                             input-classes="otp-input"
                                             separator=""
                                             :num-inputs="6"
                                             :should-auto-focus="true"
                                             :is-input-num="true"
                                             @on-complete="handleOnComplete" />
                            </form>
                            <div class="mt-1">
                                <span v-if="countSecond > 0">{{ $tBnd("otp_resend_in") }}: </span>

                                <em class="txt-time" v-if="countSecond > 0">
                                    {{ countDownText }}
                                </em>
                                <button type="button"
                                        class="resend-code"
                                        @click="sendOtp"
                                        v-if="countSecond <= 0">
                                    {{ $tBnd("phone_resend_code") }}
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-around">
                            <button type="button"
                                    class="btn btn-light"
                                    @click="closeVerifyPopup">
                                {{ $tBnd("phone_remind_later") }}
                            </button>
                            <button type="button"
                                    class="btn btn-complete"
                                    @click="activephone"
                                    v-show="otp && otp.length == 6">
                                {{ $tBnd("phone_ccomplete") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modalcustom>
        <modalcustom v-if="showDeleteOTP">
            <div class="modal fade popup show" slot="content">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- HTML của phần xác thực phone vừa thêm -->
                        <div class="modal-header justify-content-center">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                {{ $tBnd("phone_verify_opt") }}
                            </h5>
                        </div>
                        <div class="modal-body type2">
                            <span class="txt"> {{ $tBnd("phone_verify_note") }}</span>
                            <form class="ipt-code">
                                <v-otp-input ref="otpInput"
                                             input-classes="otp-input"
                                             separator=""
                                             :num-inputs="6"
                                             :should-auto-focus="true"
                                             :is-input-num="true"
                                             @on-complete="handleOnComplete" />
                            </form>
                            <div class="mt-1">
                                <span v-if="countSecond > 0">{{ $tBnd("otp_resend_in") }}: </span>

                                <em class="txt-time" v-if="countSecond > 0">
                                    {{ countDownText }}
                                </em>
                                <button type="button"
                                        class="resend-code"
                                        @click="sendOtp"
                                        v-if="countSecond <= 0">
                                    {{ $tBnd("phone_resend_code") }}
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-around">
                            <button type="button"
                                    class="btn btn-light"
                                    @click="closeDeletePopup">
                                {{ $tBnd("phone_remind_later") }}
                            </button>
                            <button type="button" class="btn btn-complete" @click="deleteOtp" v-show="otp && otp.length == 6">
                                {{ $tBnd("phone_ccomplete") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modalcustom>
    </main>
</template>

<script>
    const phoneConst = 1;
    import { required } from "vuelidate/lib/validators";
    import modalcustom from "../../../components/common/modalcustom.vue";
    export default {
        components: { modalcustom },
        metaInfo: {
            title: 'Điện thoại',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                isLoaded: false,
                listphone: [],
                showVerify: false,
                showCreate: false,
                phone: null,
                otp: null,
                submitted: false,
                countSecond: 0,
                showDeleteOTP: false,
                item: {},
                invalidPhoneNumber: false
            };
        },
        validations: {
            // otp: {
            //   required,
            // },
            phone: {
                required
            },
        },
        computed: {
            countDownText() {
                if (!this.countSecond) return "";
                else {
                    var html = "";
                    var s = this.countSecond % 60;
                    var m = Math.floor(this.countSecond / 60)

                    if (m < 10)
                        m = `0${m}`
                    if (s < 10)
                        s = `0${s}`
                    html = `${m}:${s}`;
                    return html;
                }
            }
        },
        created() {
            this.getPhone();
        },
        methods: {
            getPhone() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.EMAIL_PHONE_GET_ALL}?type=1`)
                    .then((data) => {
                        this.listphone = data.result;
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            Createphone() {
                this.submitted = true;
                this.$v.$touch();
                this.invalidPhoneNumber = this.invalidPhone(this.phone)
                if (!this.phone) {
                    return null
                }
                if (!this.$v.$invalid && !this.invalidPhoneNumber) {
                    const $loading = this.$loading.show();
                    // Check phone/phone đã có hay chưa?
                    this.$axios
                        .get(
                            `${this.$api.EMAIL_PHONE_CHECKEXISTED}?userPhoneEmailName=${this.phone}`
                        )
                        .then((data) => {
                            $loading.hide();
                            if (data.result) {
                                this.$error(this.$tBnd("phone_already"));
                            } else {
                                // Tạo mới
                                var formData = {
                                    type: phoneConst,
                                    Name: this.phone,
                                    isConfirmed: false,
                                };
                                this.$axios
                                    .post(this.$api.EMAIL_PHONE_CREATE, formData)
                                    .then((data) => {
                                        this.getPhone();
                                        this.$success(this.$tBnd("phone_success"));
                                    })
                                    .catch((ex) => {
                                        $loading.hide();
                                    });
                                this.showCreate = false;
                                this.showVerifyPopup();
                            }
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                } else {
                    this.$error(this.$tBnd("error_format_phone"));
                }
            },
            closeVerifyPopup() {
                this.showVerify = false;
            },
            showVerifyPopup(name) {
                if (name) {
                    this.phone = name;
                }
                this.showVerify = !this.showVerify;
                this.sendOtp();
            },
            showCreatephone() {
                this.phone = '';
                this.submitted = false;
                this.showCreate = !this.showCreate;
            },
            sendOtp() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.EMAIL_PHONE_SEND_OTP}?EmailPhoneName=${this.phone}`)
                    .then((data) => {
                        $loading.hide();
                        this.countSecond = data.result.times;
                        if (!this.isCountDowning)
                            this.countDownTimer();
                    }).catch((ex) => {
                        console.log(ex);
                        $loading.hide();
                    });
            },
            countDownTimer() {
                if (this.countSecond > 0) {
                    setTimeout(() => {
                        this.countSecond--;
                        this.countDownTimer();
                    }, 1000);
                    this.isCountDowning = true;
                } else {
                    this.isCountDowning = false;
                }
            },
            activephone() {
                const $loading = this.$loading.show();
                var formData = {
                    UserPhoneEmailName: this.phone,
                    Otp: this.otp + "",
                };
                this.$axios
                    .post(this.$api.EMAIL_PHONE_ACTIVE_PHONE, formData)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("phone_success_verify"));
                        this.closeVerifyPopup();
                        this.getPhone();
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            handleOnComplete(value) {
                this.otp = value;
            },
            handleOnComplete(value) {
                this.otp = value;
            },
            showDelete(item) {
                this.$confirm(
                    this.$tBnd("confirm_delete"),
                    this.$tBnd("confirm"),
                    this.$tBnd("confirm_cancel")
                ).then(() => {
                    if (item.isConfirmed) {
                        this.showDeleteOTP = true;
                        this.phone = item.name;
                        this.item = item;
                        this.sendOtp();
                    } else {
                        this.deleteItem(item);
                    }
                });
            },
            deleteItem(item) {
                const $loading = this.$loading.show();
                this.$axios
                    .delete(`${this.$api.EMAIL_PHONE_DELETE}?userPhoneEmailId=${item.id}`)
                    .then((response) => {
                        $loading.hide();
                        this.$success(this.$tBnd("useremail_noti_delete"));
                        this.getPhone();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            deleteOtp() {
                const $loading = this.$loading.show();
                var formData = {
                    PhoneEmailName: this.phone,
                    Otp: this.otp + "",
                };
                this.$axios
                    .post(this.$api.EMAIL_PHONE_DELETE_OTP, formData)
                    .then((data) => {
                        vm.getPhone();
                        $loading.hide();
                        this.$success(this.$tBnd("useremail_noti_delete"));
                        this.showDeleteOTP = false;
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            closeDeletePopup() {
                this.showDeleteOTP = false;
            },
            setPhonePrimary(id) {
                this.$confirm(
                    this.$tBnd("confirm_set_phone_primary"),
                    this.$tBnd("confirm"),
                    this.$tBnd("confirm_cancel")
                ).then(() => {
                    const $loading = this.$loading.show();
                    this.$axios
                        .put(`${this.$api.EMAIL_PHONE_SET_PRIMARY}?id=${id}`)
                        .then((data) => {
                            $loading.hide();
                            this.$success(this.$tBnd("update_success"));
                            this.getPhone();
                        })
                        .catch((ex) => {
                            this.$error(ex.response.data.responseException.exceptionMessage);
                            $loading.hide();
                        });
                });

            },
            changeAllowLogin(item) {
                const $loading = this.$loading.show();
                this.$axios
                    .put(`${this.$api.EMAIL_PHONE_SET_ALLOW_LOGIN}?id=${item.id}&isAllow=${item.allowLogin}`)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("update_success"));
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            }
        },
    };
</script>

<style scoped>
    .popup .modal-dialog {
        width: 400px;
        height: 304px;
    }
</style>
