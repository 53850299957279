<template>
    <div>
    </div>
</template>
<script type="text/javascript">
    export default {
        mounted() {
            this.$auth.signinSilentCallback();
        },
    };
</script>
