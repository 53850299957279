<template>
    <main class="content-page">
        <div>
            <div class="container">
                <div class="main-ct">
                    <div class="box type2">
                        <div class="box-head">
                            <h2>{{ $tBnd("email_manager") }}</h2>
                        </div>
                        <ul class="box-ct lst-ct lst-manager mt-2">
                            <li v-for="item in listEmail"
                                :key="item.id"
                                class="is-verified">
                                <div class="block-content-is-verified">
                                    <h3 class="name-is-verified">
                                        {{ item.name }}<span class="icon-stick" v-if="item.isConfirmed"><bnd-web-static-img src="id/images/icon-stick.svg" alt=""></bnd-web-static-img></span>
                                        <span class="txt-email-auto" v-if="isEmailAutoGenerate(item.name)">{{$tBnd("email_auto_generate")}}</span>
                                    </h3>
                                    <div class="lst-opt-verified" v-if="!isEmailAutoGenerate(item.name)">

                                        <div class=" lst-btn-opt-verified lst-btn" v-if="item.isPrimary">
                                            <span class="email-default">{{$tBnd("email_primary") }}</span>
                                        </div>
                                        <div class=" lst-btn-opt-verified lst-btn" v-else>
                                            <a href="javascript:void(0):"
                                               v-if="item.isConfirmed"
                                               class="itm-link">
                                                <div class="form-check form-check-inline checkbox-check">
                                                    <input class="form-check-input"
                                                           v-model="item.allowLogin"
                                                           type="checkbox" @change="changeAllowLogin(item)"
                                                           :id="`fixed-addr_${item.id}`" />
                                                    <label class="form-check-label" :for="`fixed-addr_${item.id}`">
                                                        {{ $tBnd("allow_login") }}
                                                    </label>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" v-if="!item.isConfirmed" class="itm-link" @click="showVerifyPopup(item.name)">{{ $tBnd("email_verify") }}</a>
                                            <a href="javascript:void(0):"
                                               @click="setEmailPrimary(item.id)"
                                               v-else
                                               class="itm-link">{{ $tBnd("set_email_primary") }}</a>
                                            <a href="javascript:void(0);" class="btn-delete" @click="showDelete(item)">{{$tBnd('delete')}}</a>
                                        </div>

                                    </div>
                                </div>
                                <!--<span class="itm-txt">{{ item.name }}</span>
                                <span class="email-default" v-if="item.isPrimary">{{$tBnd("email_primary") }}</span>
                                <div class="lst-btn d-flex ct-lst-btn" v-else>
                                    <a href="javascript:void(0):"
                                       v-if="item.isConfirmed"
                                       class="itm-link">
                                        <div class="form-check form-check-inline checkbox-check">
                                            <input class="form-check-input"
                                                   v-model="item.allowLogin"
                                                   type="checkbox" @change="changeAllowLogin(item)"
                                                   :id="`fixed-addr_${item.id}`" />
                                            <label class="form-check-label" :for="`fixed-addr_${item.id}`">
                                                {{ $tBnd("allow_login") }}
                                            </label>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0):"
                                       @click="showVerifyPopup(item.name)"
                                       v-if="!item.isConfirmed"
                                       class="itm-link">{{ $tBnd("email_verify") }}</a>
                                    <a href="javascript:void(0):"
                                       @click="setEmailPrimary(item.id)"
                                       v-else
                                       class="itm-link">{{ $tBnd("set_email_primary") }}</a>
                                    <a href="javascript:void(0);"
                                       class="btn-delete"
                                       @click="showDelete(item)">Xoá</a>
                                </div>-->
                            </li>
                        </ul>
                        <a class="add-new-addr mt-3" v-if="!listEmail || listEmail.length <= $constants.commonConfig.MaximumEmailOrPhone" href="javascript:void(0):" @click="showCreateEmail"><i class="plus">+</i> {{ $tBnd("add_email") }}</a>
                    </div>
                </div>
            </div>
            <modalcustom v-if="showCreate">
                <div class="modal fade popup show" slot="content">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <!-- HTML của phần thêm email mới -->
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title">{{ $tBnd("add_email") }}</h5>
                            </div>
                            <div class="modal-body type2">
                                <div class="custom-input mt-3 mb-2">
                                    <input type="email"
                                           v-model="email"
                                           id="email"
                                           name="email"
                                           class="form-control ipt"
                                           :class="{ 'is-invalid': submitted && $v.email.$error }" />
                                    <div v-if="submitted && $v.email.$error"
                                         class="invalid-feedback">
                                        <span v-if="!$v.email.required">
                                            {{
                      $tBnd("email_required")
                                            }}
                                        </span>
                                        <span v-if="!$v.email.email">
                                            {{
                      $tBnd("email_invalid")
                                            }}
                                        </span>
                                    </div>
                                    <label for="addEmail" class="lbl">
                                        {{
                    $tBnd("email_add_lbl")
                                        }}
                                    </label>
                                </div>
                                <span class="txt">{{ $tBnd("email_note") }}</span>
                            </div>
                            <div class="modal-footer justify-content-around">
                                <button type="button"
                                        class="btn btn-light"
                                        data-dismiss="modal"
                                        @click="showCreateEmail">
                                    {{ $tBnd("email_cancel") }}
                                </button>
                                <button type="button"
                                        class="btn btn-complete"
                                        @click="CreateEmail">
                                    {{ $tBnd("email_save") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </modalcustom>
            <modalcustom v-if="showVerify">
                <div class="modal fade popup show" slot="content">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <!-- HTML của phần xác thực email vừa thêm -->
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title" id="exampleModalLongTitle">
                                    {{ $tBnd("email_verify_opt") }}
                                </h5>
                            </div>
                            <div class="modal-body type2">
                                <span class="txt"> {{ $tBnd("email_verify_note") }}</span>
                                <form class="ipt-code">
                                    <v-otp-input ref="otpInput"
                                                 input-classes="otp-input"
                                                 separator=""
                                                 :num-inputs="6"
                                                 :should-auto-focus="true"
                                                 :is-input-num="true"
                                                 @on-complete="handleOnComplete"
                                                 @on-change="handleOnChange" />
                                </form>
                                <div class="mt-1">
                                    <span v-if="countSecond > 0">{{ $tBnd("otp_resend_in") }}: </span>

                                    <em class="txt-time" v-if="countSecond > 0">
                                        {{  countDownText}}
                                    </em>
                                    <button type="button"
                                            class="resend-code"
                                            @click="sendOtp"
                                            v-if="countSecond <= 0">
                                        {{ $tBnd("email_resend_code") }}
                                    </button>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-around">
                                <button type="button"
                                        class="btn btn-light"
                                        @click="closeVerifyPopup">
                                    {{ $tBnd("email_remind_later") }}
                                </button>
                                <button type="button"
                                        class="btn btn-complete"
                                        @click="activeEmail" v-show="otp && otp.length == 6">
                                    {{ $tBnd("email_ccomplete") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </modalcustom>
            <modalcustom v-if="showDeleteOTP">
                <div class="modal fade popup show" slot="content">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title" id="exampleModalLongTitle">
                                    {{ $tBnd("email_verify_opt") }}
                                </h5>
                            </div>
                            <div class="modal-body type2">
                                <span class="txt"> {{ $tBnd("email_verify_note") }}</span>
                                <form class="ipt-code">
                                    <v-otp-input ref="otpInput"
                                                 input-classes="otp-input"
                                                 separator=""
                                                 :num-inputs="6"
                                                 :should-auto-focus="true"
                                                 :is-input-num="true"
                                                 @on-complete="handleOnComplete"
                                                 @on-change="handleOnChange" />
                                </form>
                                <div class="mt-1">
                                    <span v-if="countSecond > 0"> {{ $tBnd("otp_resend_in") }}: </span>
                                    <em class="txt-time" v-if="countSecond > 0">
                                        {{ countDownText}}
                                    </em>
                                    <button type="button"
                                            class="resend-code"
                                            @click="sendOtp"
                                            v-if="countSecond <= 0">
                                        {{ $tBnd("email_resend_code") }}
                                    </button>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-around">
                                <button type="button"
                                        class="btn btn-light"
                                        @click="closeDeletePopup">
                                    {{ $tBnd("email_remind_later") }}
                                </button>
                                <button type="button"
                                        class="btn btn-complete"
                                        @click="deleteOtp(item)" v-show="otp && otp.length == 6">
                                    {{ $tBnd("email_ccomplete") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </modalcustom>
        </div>
    </main>
</template>

<script>
    const emailConst = 2;
    import { required, email } from "vuelidate/lib/validators";
    import modalcustom from "../../../components/common/modalcustom.vue";
    export default {
        components: { modalcustom },
        metaInfo: {
            title: 'Email',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                isLoaded: false,
                listEmail: [],
                showVerify: false,
                showCreate: false,
                email: null,
                otp: null,
                submitted: false,
                countSecond: 0,
                showDeleteOTP: false,
                item: {},
                isCountDowning: false
            };
        },
        validations: {
            // otp: {
            //   required,
            // },
            email: {
                required,
                email,
            },
        },
        computed: {
            countDownText() {
                if (!this.countSecond) return "";
                else {
                    var html = "";
                    var s = this.countSecond % 60;
                    var m = Math.floor(this.countSecond / 60)

                    if (m < 10)
                        m = `0${m}`
                    if (s < 10)
                        s = `0${s}`
                    html = `${m}:${s}`;
                    return html;
                }
            }
        },
        created() {
            this.getEmail();
        },
        methods: {
            getEmail() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.EMAIL_PHONE_GET_ALL}?type=2`)
                    .then((data) => {
                        this.listEmail = data.result;
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            CreateEmail() {
                if (!this.email) {
                    return this.$error(this.$tBnd('error_email_empty'));
                }
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    // Check email/phone đã có hay chưa?
                    this.$axios
                        .get(
                            `${this.$api.EMAIL_PHONE_CHECKEXISTED}?userPhoneEmailName=${this.email}`
                        )
                        .then((data) => {
                            $loading.hide();
                            if (data.result) {
                                this.$error(this.$tBnd("email_already"));
                            } else {
                                // Tạo mới
                                var formData = {
                                    type: emailConst,
                                    Name: this.email,
                                    isConfirmed: false,
                                };
                                this.$axios
                                    .post(this.$api.EMAIL_PHONE_CREATE, formData)
                                    .then((data) => {
                                        this.getEmail();
                                        this.$success(this.$tBnd("email_success"));
                                        $loading.hide();
                                    })
                                    .catch((ex) => {
                                        $loading.hide();
                                    });
                                this.showCreate = false;
                                this.showVerifyPopup();
                            }
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                }
            },
            closeVerifyPopup() {
                this.showVerify = false;
            },
            showVerifyPopup(name) {
                if (name) {
                    this.email = name;
                }
                this.showVerify = !this.showVerify;
                this.sendOtp();
            },
            showCreateEmail() {
                this.showCreate = !this.showCreate;
                this.email = null;
                this.submitted = false;
            },
            sendOtp() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.EMAIL_PHONE_SEND_OTP}?emailPhoneName=${this.email}`)
                    .then((data) => {
                        $loading.hide();
                        this.countSecond = data.result.times;
                        if (!this.isCountDowning)
                            this.countDownTimer();
                    }).catch((ex) => {
                        console.log(ex);
                        $loading.hide();
                    });
            },
            countDownTimer() {
                if (this.countSecond > 0) {
                    setTimeout(() => {
                        this.countSecond--;
                        this.countDownTimer();
                    }, 1000);
                    this.isCountDowning = true
                } else {
                    this.isCountDowning = false;
                }
            },
            activeEmail() {
                if (!this.otp) {
                    return this.$error(this.$tBnd('please_enter_verification_code'));
                }
                const $loading = this.$loading.show();
                var formData = {
                    PhoneEmailName: this.email,
                    Otp: this.otp,
                };
                this.$axios
                    .post(this.$api.EMAIL_PHONE_ACTIVE_EMAIL, formData)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("email_success_verify"));
                        this.closeVerifyPopup();
                        this.getEmail();
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            setEmailPrimary(id) {
                this.$confirm(
                    this.$tBnd("confirm_set_email_primary"),
                    this.$tBnd("confirm"),
                    this.$tBnd("confirm_cancel")
                ).then(() => {
                    const $loading = this.$loading.show();
                    this.$axios
                        .put(`${this.$api.EMAIL_PHONE_SET_PRIMARY}?id=${id}`)
                        .then((data) => {
                            $loading.hide();
                            this.$success(this.$tBnd("update_success"));
                            this.getEmail();
                        })
                        .catch((ex) => {
                            this.$error(ex.response.data.responseException.exceptionMessage);
                            $loading.hide();
                        });
                });

            },
            handleOnComplete(value) {
                this.otp = value;
            },
            handleOnChange(value) {
                this.otp = value;
            },
            showDelete(item) {
                this.$confirm(
                    this.$tBnd("confirm_delete"),
                    this.$tBnd("confirm"),
                    this.$tBnd("confirm_cancel")
                ).then(() => {
                    //if (item.isConfirmed) {
                    //    this.showDeleteOTP = true;
                    //    this.email = item.name;
                    //    this.item = item;
                    //    this.sendOtp();
                    //} else {
                    this.deleteItem(item);
                    //}
                });
            },
            deleteItem(item) {
                var $loading = this.$loading.show();
                this.$axios
                    .delete(`${this.$api.EMAIL_PHONE_DELETE}?userPhoneEmailId=${item.id}`)
                    .then((response) => {
                        $loading.hide();
                        this.$success(this.$tBnd("useremail_noti_delete"));
                        this.getEmail();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            deleteOtp() {
                const $loading = this.$loading.show();
                var formData = {
                    PhoneEmailName: this.email,
                    Otp: this.otp,
                };
                this.$axios
                    .post(this.$api.EMAIL_PHONE_DELETE_OTP, formData)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("useremail_noti_delete"));
                        this.showDeleteOTP = false;
                        //  this.getEmail();
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            closeDeletePopup() {
                this.showDeleteOTP = false;
            },
            changeAllowLogin(item) {
                const $loading = this.$loading.show();
                this.$axios
                    .put(`${this.$api.EMAIL_PHONE_SET_ALLOW_LOGIN}?id=${item.id}&isAllow=${item.allowLogin}`)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("update_success"));
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            isEmailAutoGenerate(name) {
                return name ? name.includes('@vitanedu.com') : false
            }
        },
    };
</script>

<style scoped>
    .popup .modal-dialog {
        width: 400px;
        height: 304px;
    }
</style>
