<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box identity-box">
                    <div class="box-head">
                        <h2>{{$tBnd('notification-config')}}</h2>
                        <div class="btn-right-box-head">
                            <div class="switch-btn">
                                <label class="switch">
                                    <input type="checkbox" v-model="swtichAll" @change="changeAll">
                                    <span class="slider"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="box-ct" v-for="(block,indexBlock) in dataGroup" :key="indexBlock">
                        <div class="box-child" style="padding:20px">
                            <div class="box-head">
                                <div class="item-products-bnd-id" :class="`item-color-${(blocks.find(o=>o.id===block[0].blockId))?(blocks.find(o=>o.id===block[0].blockId)).name.replace('Vitan','').toLowerCase():'learn'}`">
                                    <div class="logo-item-products-id" v-if="indexBlock && indexBlock!='0'">
                                        <div class="icon">
                                            <bnd-web-static-img :src="`template-bnd-layout/img-fix/products-${indexBlock}.svg`" alt=""></bnd-web-static-img>
                                        </div>
                                    </div>
                                    <div class="name-info-sp-id" v-if="blocks.find(o=>o.id===block[0].blockId)">
                                        <h2 class="name">Vitan<span class="">{{(blocks.find(o=>o.id===block[0].blockId)).name.replace('Vitan','')}}</span></h2>
                                        <h3 class="name-small">{{(blocks.find(o=>o.id===block[0].blockId)).title}} </h3>
                                    </div>
                                    <div class="name-info-sp-id" v-else>
                                        <h2 class="name">{{$tBnd('general_notification')}}</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="box-ct lst-ct lst-expand">
                                <ul>
                                    <li class="itm-expand" :class="{'on': expandId == `${index}_${indexBlock}`}" v-for="(m, index) in block" :key="index">
                                        <div class="tit-expand" @click="expand(`${index}_${indexBlock}`)">
                                            <button type="button" class="btn-expand">
                                                <strong>{{m.moduleName}}</strong>
                                            </button>
                                        </div>
                                        <div class="ct-expand">
                                            <ul class="lst-expand">
                                                <li class="itm d-flex flex-column ">
                                                    <div class="txt-detail">
                                                        <bnd-web-static-img src="id/icon-email-l.svg" alt="" class="img" />
                                                        <span class="txt">{{$tBnd('notification_by_email')}}</span>
                                                        <div class="switch-btn">
                                                            <label class="switch reverse">
                                                                <input type="checkbox" value="email" v-model="m.notificationGroup" @change="changeItem">
                                                                <span class="slider"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="itm d-flex align-items-center">
                                                    <div class="txt-detail">
                                                        <bnd-web-static-img src="id/icon-box.svg" alt="" class="img" />
                                                        <span class="txt">{{$tBnd('notification_by_web')}}</span>
                                                        <div class="switch-btn">
                                                            <label class="switch reverse">
                                                                <input type="checkbox" value="web" v-model="m.notificationGroup" @change="changeItem">
                                                                <span class="slider"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="itm d-flex align-items-center">
                                                    <div class="txt-detail">
                                                        <bnd-web-static-img src="id/icon-phone-tb.svg" alt="" class="img" />
                                                        <span class="txt">{{$tBnd('notification_by_app')}}</span>
                                                        <div class="switch-btn">
                                                            <label class="switch reverse">
                                                                <input type="checkbox" value="app" v-model="m.notificationGroup" @change="changeItem">
                                                                <span class="slider"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                    <div class="btn-area mt-4 pt-3">
                        <button type="button" class="btn btn-update" @click="save">{{$tBnd('update')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<style>
    .switch.reverse input + .slider { background-color: #663300 }
    .switch.reverse input:checked + .slider { background-color: #E2E7ED }
    .lst-expand .switch.reverse input + .slider:before { -webkit-transform: translateX(15px); -ms-transform: translateX(15px); transform: translateX(15px); }
    .lst-expand .switch.reverse input:checked + .slider:before { -webkit-transform: none; -ms-transform: none); transform: none; }
</style>
<script>
    import _ from 'lodash'
    export default {
        metaInfo: {
            title: 'Cấu hình thông báo',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                expandId: 0,
                moduleConfig: null,
                swtichAll: true,
                allFlatform: ['email', 'web', 'app']
            };
        },
        computed: {
            dataGroup() {
                var result = this.moduleConfig ? _.groupBy(this.moduleConfig, (o) => { return o.blockId }) : [];
                return result;
            }
        },
        watch: {
        },
        methods: {
            save() {
                const $loading = this.$loading.show();
                this.$axios.post(this.$api.USER_NOTIFICATION_SAVE_CONFIG, this.moduleConfig)
                    .then(() => {
                        $loading.hide();
                        this.$success(this.$tBnd('info_noti_info_success'));
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            getConfig() {
                const $loading = this.$loading.show();
                this.$axios.get(this.$api.USER_NOTIFICATION_GET_CONFIG)
                    .then((res) => {
                        $loading.hide();
                        this.moduleConfig = res.result;
                        //set switch all
                        this.moduleConfig.forEach(item => {
                            if (!item.notificationGroup) {
                                item.notificationGroup = [];// this.allFlatform
                            }
                        })
                        var count = _.countBy(this.moduleConfig, (item) => {
                            return item.notificationGroup.length === 0
                        })
                        this.swtichAll = count.true && count.true == this.moduleConfig.length ? true : false
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            getCheckGroup(item, group) {
                var result = item.notificationGroup ? !item.notificationGroup.includes(group) : false;
                if (!item.notificationGroup) {
                    item.notificationGroup = this.allFlatform
                }
                return result;
            },
            expand(id) {
                if (this.expandId == id)
                    this.expandId = -1;
                else
                    this.expandId = id;
            },
            changeAll(event) {
                if (event.target.checked) {
                    this.moduleConfig.forEach(item => {
                        item.notificationGroup = []
                    })
                } else {
                    this.moduleConfig.forEach(item => {
                        item.notificationGroup = ['email', 'web', 'app']
                    })
                }

            },
            changeItem() {
                var count = _.countBy(this.moduleConfig, (item) => {
                    return item.notificationGroup.length === 0;
                })
                this.swtichAll = count.true && count.true == this.moduleConfig.length ? true : false
            }
        },
        created() {
            this.getConfig();
        }
    };
</script>

<style>
</style>
