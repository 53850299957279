import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
if (!process.env.VUE_ENV) {
    Vue.mixin({
        data() {
            return {
                ww: window.innerWidth
            };
        },
        computed: {

        },
        methods: {
            ...mapActions(['updateTrigger']),
            handleWindowResize() {
                this.ww = window.innerWidth;
            },
            toCamelCase(str) {
                return str
                    .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
                    .replace(/\s/g, '')
                    .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
            },
            validateEmail(inputText) {
                var mailformat = /^\S+@\S+\.\S+$/;
                if (inputText.match(mailformat)) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        mounted: function () {
            window.addEventListener('resize', this.handleWindowResize);
            if (this.scope) document.body.classList.add(this.scope);
        },
        beforeDestroy: function () {
            window.removeEventListener('resize', this.handleWindowResize);
        },
        destroyed() {
            if (this.scope) document.body.classList.remove(this.scope);
        }
    });
}
