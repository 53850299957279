<template>
    <div>
        <input :type="showPass ? 'text': 'password'" id="ipt-password" class="ipt" v-model.trim="password" v-on:keydown.enter="$emit('enter')"  autocomplete="new-password">
        <label for="ipt-password" class="lbl" v-if="labelName">
            {{labelName}} <span class="important" v-if="requied">*</span>
            <div class="block-info-hd" v-if="showGuide">
                <div class="icon-hd">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0002 1.11133C8.24217 1.11133 6.52359 1.63265 5.06182 2.60938C3.60005 3.5861 2.46074 4.97435 1.78796 6.59859C1.11518 8.22282 0.93915 10.0101 1.28213 11.7344C1.62511 13.4586 2.47169 15.0425 3.71483 16.2856C4.95796 17.5287 6.54181 18.3753 8.26609 18.7183C9.99036 19.0613 11.7776 18.8853 13.4019 18.2125C15.0261 17.5397 16.4143 16.4004 17.3911 14.9386C18.3678 13.4768 18.8891 11.7583 18.8891 10.0002C18.8891 7.64274 17.9526 5.38181 16.2856 3.71482C14.6186 2.04783 12.3577 1.11133 10.0002 1.11133ZM10.0002 17.778C8.46192 17.778 6.95817 17.3218 5.67912 16.4672C4.40007 15.6126 3.40317 14.3978 2.81449 12.9766C2.22581 11.5554 2.07178 9.99159 2.37189 8.48285C2.672 6.97411 3.41276 5.58824 4.5005 4.5005C5.58824 3.41276 6.97411 2.67199 8.48285 2.37189C9.99159 2.07178 11.5554 2.22581 12.9767 2.81449C14.3979 3.40317 15.6126 4.40007 16.4672 5.67912C17.3218 6.95816 17.778 8.46192 17.778 10.0002C17.778 12.063 16.9586 14.0413 15.4999 15.4999C14.0413 16.9586 12.063 17.778 10.0002 17.778Z" fill="#414B5B"></path>
                        <path d="M10.1612 4.95564C9.55919 4.94569 8.96236 5.06846 8.41314 5.31522C7.86392 5.56199 7.37579 5.92669 6.98344 6.38342C6.92893 6.43393 6.88511 6.49487 6.85458 6.56262C6.82404 6.63037 6.80741 6.70356 6.80566 6.77786C6.80563 6.84641 6.81938 6.91426 6.8461 6.97738C6.87282 7.0405 6.91197 7.09761 6.96121 7.14529C7.01045 7.19298 7.06878 7.23027 7.13273 7.25496C7.19667 7.27964 7.26493 7.29121 7.33344 7.28897C7.48021 7.28533 7.62054 7.22801 7.72789 7.12786C8.02481 6.77991 8.39359 6.50041 8.80886 6.3086C9.22413 6.11679 9.67602 6.01722 10.1334 6.01675C11.4446 6.01675 12.2612 6.86119 12.2612 7.91119V7.93897C12.2612 9.16675 11.2834 9.85008 9.739 9.96675C9.67646 9.96916 9.61514 9.98476 9.55904 10.0125C9.50295 10.0403 9.45335 10.0796 9.41349 10.1278C9.37363 10.1761 9.34441 10.2322 9.32775 10.2925C9.31108 10.3528 9.30734 10.416 9.31678 10.4779V12.0056C9.33059 12.1432 9.39517 12.2706 9.4979 12.363C9.60063 12.4555 9.73413 12.5063 9.87233 12.5056H9.93344C10.071 12.4918 10.1984 12.4272 10.2908 12.3245C10.3833 12.2218 10.4341 12.0883 10.4334 11.9501V10.8056C12.1001 10.5723 13.4501 9.69453 13.4501 7.87231V7.84453C13.4334 6.17786 12.1334 4.95564 10.1612 4.95564Z" fill="#414B5B"></path>
                        <path d="M9.87804 15.2502C10.2616 15.2502 10.5725 14.9393 10.5725 14.5558C10.5725 14.1722 10.2616 13.8613 9.87804 13.8613C9.49451 13.8613 9.18359 14.1722 9.18359 14.5558C9.18359 14.9393 9.49451 15.2502 9.87804 15.2502Z" fill="#414B5B"></path>
                    </svg>
                </div>
                <div class="box-content-hd">
                    <div class="content-hd">
                        <ol>
                            <!--<li>({{$tBnd('recommend')}})</li>-->
                            <li>1. {{$tBnd('use_at_least_6_characters')}}</li> <!--Sử dụng ít nhất 6 ký tự-->
                            <li>2. {{$tBnd('contains_at_least_1_digit')}}</li> <!--Chứa ít nhất 1 chữ số-->
                            <li>3. {{$tBnd('contains_at_least_1_special_character')}}</li> <!--Chứa ít nhất 1 ký tự viết hoa-->
                        </ol>
                    </div>
                </div>
            </div>
        </label>
        <i :class="{'icon-hiden': showPass, 'icon-show': !showPass}" @click="showPass = !showPass"></i>
    </div>
</template>

<script>
    export default {
        name: 'InputPass',
        props: {
            label: {
                type: String,
                default: ''
            },
            value: {

                type: String,
                default: ''
            },
            requied: {
                type: Boolean,
                default: false
            },
            showGuide: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                password: '',
                showPass: false,
                labelName: ''
            };
        },
        watch: {
            password(val) {
                this.$emit('input', val);
            }
        },
        created() {
            if (this.value != this.password) this.password = this.value;
            if (!this.label) this.labelName = this.$tBnd('password');
            else this.labelName = this.label
        }
    };
</script>
