<template>
    <main role="main">
        <section class="jumbotron text-center">
            <div class="container">
                <h1 class="jumbotron-heading">
                    Active phone
                </h1>
            </div>
        </section>

        <div class="album py-5 bg-light">
            <div class="container">
                <!--start step 1-->
                <div v-if="step == 1" class="row">
                    <div class="col-md-4 col-centered">
                        <h4>{{ this.dataActivePhone.newphone }}</h4>
                        <div class="wrap-input100 m-b-20">
                            <b>{{ $tBnd("please_enter_otp_code") }}</b>
                        </div>
                        <div class="wrap-input100 m-b-20">
                            <input id="Otp"
                                   v-model="dataActivePhone.otp"
                                   class="input100"
                                   type="text"
                                   :placeholder="$tBnd('otp_input')"
                                   maxlength="6">
                            <span class="focus-input100" />
                        </div>
                        <div class="otp-time m-b-20">
                            <label v-if="seconds > 0"
                                   class="mb-3">{{ $tBnd("time_remaining") }}: <b>{{ seconds }}s</b></label>
                            <a v-else
                               href="javascript:;"
                               @click="ResendOTP">{{ $tBnd("register_resend_otp") }}</a>
                        </div>
                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn"
                                    type="button"
                                    @click="ActivePhone">
                                {{ $tBnd("update") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--end step 1-->
                <!--start step 2-->
                <div v-if="step == 2" class="row">
                    <div class="col-md-4 col-centered">
                        <div class="wrap-input100 m-b-20">
                            <b>Kích hoạt số điện thoại mới thành công</b>
                        </div>
                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn"
                                    type="button"
                                    @click="GoHome">
                                {{ $tBnd("go_home") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--end step 2-->
            </div>
        </div>
    </main>
</template>
<style>
    .col-centered { float: none; margin: 0 auto; }
</style>
<script>
    export default {
        layout: 'manager',
        middleware: 'authenticated',
        metaInfo: {
            title: 'Kích hoạt số điện thoại',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                step: 1,
                seconds: 0,
                dataActivePhone: {
                    newphone: '',
                    otp: '',
                },
            };
        },
        created() {
            this.ResendOTP();
            const $loading = this.$loading.show();
            this.$axios
                .get(this.$api.ACCOUNT_GET_UPDATE_INFO)
                .then((response) => {
                    this.dataActivePhone.newphone = response.data.phoneNumber;
                    $loading.hide();
                })
                .catch((ex) => {
                    console.log(ex);
                    $loading.hide();
                });
        },
        methods: {
            ActivePhone() {                
                if (!this.dataActivePhone.otp) {
                    return this.$error(this.$tBnd('otp_is_required'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_ACTIVE_PHONE, this.dataActivePhone)
                    .then((response) => {
                        $loading.hide();
                        this.step = 2;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            ResendOTP() {
                this.$api.resentOTP(this);
            },
            GoHome() {
                this.$router.push('/');
            },
        },
    };
</script>
