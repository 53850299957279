<template>
    <div id="iframe-page">
        <bnd-web-auth />
        <router-view v-if="!initializing"></router-view>
    </div>
</template>
<script>
    import { mapMutations, mapActions } from 'vuex';

    export default {
        data() {
            return {
                initializing: true
            }
        },
        created() {
            if (this.$route.query.token) {
                this.$axios.get(this.$api.ACCOUNT_LOGIN_TOKEN, { params: { token: this.$route.query.token } }).then(() => {
                    //Nếu trong iframe & có tham số callback thì gọi hàm callback
                    if (this.$route.query.cb && window.self !== window.top) {
                        window.parent.postMessage(this.$route.query.cb, window.document.referrer);
                    } else {
                        if (this.$route.path === "/app")
                            this.$router.push("/")
                        else
                            window.location = this.$route.path;
                    }
                })
            }
            else {
                this.getProfile();
                this.initSystem().then(() => {
                    this.initializing = false;
                });
            }
        },
        methods: {
            ...mapMutations({ setUserInfo: 'user/updateInfo' }),
            ...mapActions('system', ['initSystem']),
            renewToken() {
                this.$auth.renewToken().then((profile) => {
                    this.getProfile();
                }).catch((err) => {
                    console.log(err);
                });
            },
            getProfile() {
                this.$auth.getProfile(false).then((profile) => {
                    if (profile) {
                        this.setUserInfo(profile);
                        this.$auth.getAcessToken().then((token) => {
                            this.$axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                        });
                    }
                    else
                        this.renewToken();
                }).catch((err) => {
                    console.log(err);
                    this.renewToken();
                });
            }
        },
    };
</script>
<style>
    #iframe-page .content-page, #iframe-page .container { padding: 0 !important; min-height: unset !important; }
    #iframe-page .box { border-radius: unset !important }
    #iframe-page .btn-area { margin-top: 0 !important }
</style>
