<template>
  <span class="recaptcha-rendered"></span>
</template>
<script>
import { loadRecaptchaSdk, validate } from "./helpers.js";
export default {
  name: "Recaptcha",
  props: {
    siteKey: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      default: "submit",
    },
  },
  mounted() {
    loadRecaptchaSdk(this.siteKey).then(() => {
      this.doValidate();
    });
  },
  methods: {
    doValidate() {
      validate(this.siteKey, this.action)
        .then((response) => {
          this.$emit("validate", response);
        })
        .catch((error) => {
          this.$emit("error", error);
        });
    },
  },
};
</script>
