<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <!-- {{client_configs}} -->
                <!-- [D] Thêm class "edit" vào class "box" khi click vào nút Sửa -->
                <div class="box box-general-info edit">
                    <div class="box-head" v-if="!iframe">
                        <div class="back-steps-id" v-if="isEdit">
                            <a href="javascript:void(0):" @click="isEdit = false">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="13"
                                     viewBox="0 0 17 13"
                                     fill="none">
                                    <path d="M5.75452 1.07142C5.98093 0.845009 6.33737 0.845009 6.56378 1.07142C6.78254 1.29018 6.78254 1.65427 6.56378 1.87252L2.46037 5.97592H16.0011C16.3167 5.97592 16.5757 6.22681 16.5757 6.54246C16.5757 6.8581 16.3167 7.11715 16.0011 7.11715H2.46037L6.56378 11.2129C6.78254 11.4393 6.78254 11.8039 6.56378 12.0222C6.33737 12.2486 5.98093 12.2486 5.75452 12.0222L0.679695 6.94734C0.460935 6.72858 0.460935 6.36449 0.679695 6.14624L5.75452 1.07142Z"
                                          fill="#414B5B"></path>
                                </svg>
                            </a>
                        </div>
                        <h2>{{ $tBnd("info_title") }}</h2>
                        <button type="button"
                                class="btn-edit"
                                @click="editProfile"
                                v-if="!isEdit">
                            {{ $tBnd("info_edit") }}
                        </button>
                    </div>
                    <div class="box-ct">
                        <div class="block-avatar-ct">
                            <div class="avt-area">
                                <template v-if="!isEdit">
                                    <bnd-web-file-preview v-if="userInfo.avatar"
                                                          :fileUrl="userInfo.avatar"
                                                          :itemWidth="100"
                                                          :itemHeight="100" />
                                    <bnd-web-static-img src="id/@avata-nodata.png"
                                                        class="w-100"
                                                        v-else />
                                </template>
                                <template v-else>
                                    <bnd-web-file-preview v-if="userInfoEdit.avatar"
                                                          :fileUrl="userInfoEdit.avatar"
                                                          :itemWidth="100"
                                                          :itemHeight="100" />
                                    <bnd-web-static-img src="id/@avata-nodata.png"
                                                        class="w-100"
                                                        v-else />
                                    <button type="button" class="upload-avt" @click="showUploadAvatar">
                                        <span class="icon-upload"></span>
                                    </button>
                                </template>
                                
                            </div>
                        </div>
                        <ul class="general-info-idBnd" v-show="!isEdit">
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_fullname") }}:</div>
                                <div class="ct">{{`${userInfo.firstName} ${userInfo.lastName}`}}</div>
                            </div>
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_gender") }}:</div>
                                <div class="ct">{{ userInfo.gender_text }}</div>
                            </div>
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_birthdate") }}:</div>
                                <div class="ct">
                                    {{ userInfo.birthdate | moment("DD/MM/YYYY") }}
                                </div>
                            </div>
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_identification") }}:</div>
                                <div class="ct">{{ userInfo.identification }}</div>
                            </div>
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_marital_status") }}:</div>
                                <div class="ct">{{ userInfo.maritalStatus_text }}</div>
                            </div>
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_language") }}:</div>
                                <div class="ct">{{ userInfo.language_text }}</div>
                            </div>
                            <div class="item-general">
                                <div class="title">{{ $tBnd("info_id") }}:</div>
                                <div class="ct">{{ userInfo.id }}</div>
                            </div>
                            <!--todo: Tạm khóa--> 
                            <router-link v-if="false" to="/account/profile" class="other-info-link">{{$tBnd("other_info_profile")}}</router-link>
                        </ul>
                        <ul class="row lst-itm-info pb-0" v-show="isEdit">
                            <li class="col-sm-6">
                                <div class="custom-input"
                                     :class="{
                    'is-err': submitted && !$v.userInfoEdit.firstName.required,
                  }">
                                    <!-- [D] Khi chuyển sang màn hình sửa, xoá thuộc tính readonly -->
                                    <input type="text"
                                           id="ipt-name"
                                           class="ipt"
                                           v-model="userInfoEdit.firstName"
                                           :readonly="!isEdit" />
                                    <label for="ipt-name" class="lbl">
                                        {{ $tBnd("info_firstname") }}
                                        <span class="obl">*</span>
                                    </label>
                                </div>
                                <div class="txt-mess ml-0 text-left"
                                     v-if="submitted && !$v.userInfoEdit.firstName.required">
                                    {{ $tBnd("info_lastname_required") }}
                                </div>
                            </li>
                            <li class="col-sm-6">
                                <div class="custom-input"
                                     :class="{
                    'is-err': submitted && !$v.userInfoEdit.lastName.required,
                  }">
                                    <!-- [D] Khi chuyển sang màn hình sfửa, xoá thuộc tính readonly -->
                                    <input type="text"
                                           id="ipt-lastname"
                                           class="ipt"
                                           v-model="userInfoEdit.lastName"
                                           :readonly="!isEdit" />
                                    <label for="ipt-last" class="lbl">{{ $tBnd("info_lastname") }} <span class="obl">*</span></label>
                                </div>
                                <div class="txt-mess ml-0 text-left"
                                     v-if="submitted && !$v.userInfoEdit.lastName.required">
                                    {{ $tBnd("info_firstname_required") }}
                                </div>
                            </li>
                            <bnd-web-date-drop v-model="userInfoEdit.birthdate"
                                               min="1900"
                                               :max="new Date().getFullYear() - 3 + ''"
                                               :isRequired="true" :displayText="[$tBnd('date_of_birth'),$tBnd('month_of_birth'),$tBnd('year_of_birth')]"
                                               :disabled="!isEdit" classObject="col-sm-4 col-xl-3" />
                            <li class="col-sm-6 col-xl-3">
                                <bnd-web-select-ul :options="genderOptions"
                                                   code="value"
                                                   label="name"
                                                   :displayText="$tBnd('info_gender')"
                                                   v-model="userInfoEdit.gender"
                                                   :showSearch="false"
                                                   :disabled="!isEdit"
                                                   :isRequired="true"
                                                   :isSubmitted="submitted" />
                                <div class="txt-mess ml-0 text-left"
                                     v-if="submitted && !$v.userInfoEdit.gender.required">
                                    {{ $tBnd("info_gender_required") }}
                                </div>
                            </li>
                            <li class="col-sm-6">
                                <div class="custom-input" :class="{
                    'is-err': submitted && (!$v.userInfoEdit.identification.minLengthValue||!$v.userInfoEdit.identification.maxLengthValue)
                  }">
                                    <!-- [D] Khi chuyển sang màn hình sửa, xoá thuộc tính readonly -->
                                    <input type="text"
                                           id="ipt-identification"
                                           class="ipt"
                                           v-model="userInfoEdit.identification"
                                           :readonly="!isEdit" />
                                    <label for="ipt-identification" class="lbl">
                                        {{ $tBnd("info_identification") }}
                                    </label>
                                </div>
                                <div class="txt-mess ml-0 text-left"
                                     v-if="submitted && (!$v.userInfoEdit.identification.minLengthValue||!$v.userInfoEdit.identification.maxLengthValue)">
                                    {{ $tBnd("invalid_identification") }}
                                </div>
                            </li>
                            <li class="col-sm-6">
                                <div class="custom-input">
                                    <bnd-web-select-ul id="ipt-marital-status"
                                                       :options="maritalStatusOptions"
                                                       code="value"
                                                       label="name"
                                                       :displayText="$tBnd('info_marital_status')"
                                                       v-model="userInfoEdit.maritalStatus"
                                                       :showSearch="false"
                                                       :disabled="!isEdit"
                                                       :height="400"
                                                       :isSubmitted="submitted" />
                                    <label for="ipt-marital-status" class="lbl">
                                        {{ $tBnd("info_marital_status") }}
                                    </label>
                                </div>
                            </li>
                            <li class="col-sm-6 col-xl-6">
                                <!-- [D] Thêm class "open" vào class "selbox"  để mở selectbox -->
                                <bnd-web-select-ul :options="languageOptions"
                                                   code="code"
                                                   label="name"
                                                   :displayText="$tBnd('info_language')"
                                                   v-model="userInfoEdit.languageId"
                                                   :showSearch="languageOptions && languageOptions.length > 10"
                                                   :disabled="!isEdit"
                                                   :isRequired="true"
                                                   :isSubmitted="submitted" />
                                <div class="txt-mess ml-0 text-left"
                                     v-if="submitted && !$v.userInfoEdit.languageId.required">
                                    {{ $tBnd("info_language_required") }}
                                </div>
                            </li>
                            <li class="col-sm-6 col-xl-6">
                                <!-- [D] Thêm class "open" vào class "selbox"  để mở selectbox -->
                                <bnd-web-select-ul :options="timezoneOptions"
                                                   code="value"
                                                   label="text"
                                                   :displayText="$tBnd('info_timezone')"
                                                   v-model="userInfoEdit.timezone"
                                                   :showSearch="timezoneOptions && timezoneOptions.length > 10"
                                                   :disabled="!isEdit"
                                                    :height="300"
                                                   :isSubmitted="submitted" />
                                <div class="txt-mess ml-0 text-left"
                                     v-if="submitted && !$v.userInfoEdit.languageId.required">
                                    {{ $tBnd("info_language_required") }}
                                </div>
                            </li>
                        </ul>
                        <div class="btn-area" v-if="isEdit">
                            <button type="button"
                                    class="btn btn-update"
                                    @click="updateGeneralInfo">
                                {{ $tBnd("info_button_update") }}
                            </button>
                            <button type="button" class="btn button-cancel-info" @click="isEdit = false" v-if="!iframe">
                                {{ $tBnd("cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="box box-acc-link" v-if="!iframe">
                    <div class="box-head">
                        <h2>{{ $tBnd("info_social_media_link") }}</h2>
                    </div>
                    <ul class="box-ct lst-ct">
                        <!-- <li>
                          <div class="info-link d-flex align-items-center">
                            <bnd-web-static-img src="id/logo-zalo.svg" alt="" />
                            <span class="txt-tit">Zalo</span>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="itm-link"
                            @click="showLinkTo('zalo_link')"
                            >{{ $tBnd("info_link") }}</a
                          >
                        </li> -->
                        <li>
                            <template v-if="facebook_link">
                                <div class="info-link d-flex align-items-center">
                                    <bnd-web-static-img src="id/logo-fb.svg" alt="" />
                                    <span class="txt-tit">Facebook ({{facebook_link}})</span>
                                </div>

                                <a href="javascript:;"
                                   @click="logoutFacebook"
                                   class="itm-link">{{ $tBnd("info_logout_link") }}</a>
                            </template>
                            <template v-else>
                                <facebook-login :app-id="client_configs.facebookAppId"
                                                @login="loginViaFacebook"
                                                loginLabel="linking_facebook"
                                                :isLinking="true">   
                                </facebook-login>
                            </template>

                        </li>
                        <li>
                            <template v-if="google_link">
                                <div class="info-link d-flex align-items-center">
                                    <bnd-web-static-img src="id/logo-google.svg" alt="" />
                                    <span class="txt-tit">Google ({{google_link}})</span>
                                </div>
                                <a href="javascript:;"
                                   @click="logoutGoogle"
                                   class="itm-link"
                                   v-show="google_link">{{ $tBnd("info_logout_link") }}</a>

                            </template>
                            <template v-else>
                                <google-login :client-id="client_configs.googleClientId"
                                              :api-key="client_configs.googleAPIKey"
                                              @login="addLinkGoogle">
                                </google-login>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <modalcustom v-if="showLink">
            <div class="modal fade popup show" slot="content">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <!-- HTML của phần thêm email mới -->
                        <div class="modal-header justify-content-center">
                            <h5 class="modal-title">{{ $tBnd("info_link_to_title") }}</h5>
                        </div>
                        <div class="modal-body type2">
                            <div class="custom-input mt-3 mb-2">
                                <input type="ClaimValue"
                                       v-model="currentClaim.ClaimValue"
                                       id="ClaimValue"
                                       name="ClaimValue"
                                       class="form-control" />
                                <label for="addEmail" class="lbl">
                                    {{
                  $tBnd("info_link_to_lbl")
                                    }}
                                </label>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-around">
                            <button type="button"
                                    class="btn btn-light"
                                    data-dismiss="modal"
                                    @click="hideLinkTo">
                                {{ $tBnd("email_cancel") }}
                            </button>
                            <button type="button"
                                    class="btn btn-complete"
                                    @click="updateUserClaim">
                                {{ $tBnd("email_save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modalcustom>
        <bnd-web-cropper v-if="isUploadAvatar && userInfoEdit" :src="userInfoEdit.originalAvatar" v-model="userInfoEdit.originalAvatar" :alwayUpload="true" @done="closeUploadAvatar" @close="closeUploadAvatar" title="Chọn ảnh đại diện" />
    </main>
</template>
<script>
    import { required, minLength, maxLength } from "vuelidate/lib/validators";
    import timezone from '@/constants/timezones.json'
    import {
        loadFbSdk,
        fbLogout,
    } from "../../../components/openid/facebook/helpers.js";
    import modalcustom from "../../../components/common/modalcustom.vue";
    import googleLogin from "../../../components/openid/google/google-login";
    import facebookLogin from "../../../components/openid/facebook/facebook-login";

    export default {
        props: ['iframe'],
        metaInfo: {
            title: 'Thông tin chung',
            titleTemplate: '%s | VitanID'
        },
        components: {
            modalcustom,
            facebookLogin,
            googleLogin
        },
        data() {
            return {
                isLoaded: false,
                isEdit: false,
                userInfo: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    birthdate: null,
                    gender: null,
                    languageId: null,
                    userClaims: null,
                    gender_text: null,
                    maritalStatus_text: null,
                    language_text: null,
                    birthdate_text: null,
                    timezone: null,
                },
                userInfoEdit: {},
                google_link: null,
                google_link_claim: null,
                facebook_link_claim: null,
                facebook_link: null,
                showLink: false,
                currentClaim: {},
                genderOptions: [],
                maritalStatusOptions: [],
                languageOptions: [],
                timezoneOptions: timezone,
                dayOptions: [],
                submitted: false,
                client_configs: {},
                isUploadAvatar: false
            };
        },
        validations: {
            userInfoEdit: {
                firstName: { required },
                lastName: { required },
                languageId: { required },
                gender: { required },
                identification: { minLengthValue: minLength(8), maxLengthValue: maxLength(12) }
            },
        },
        created() {
            this.client_configs = this.appSettings.configs;
            this.languageOptions = this.appSettings.languages;
            this.genderOptions = this.appSettings.gender.map((e) => {
                return {
                    value: e.id,
                    name: this.$tBnd(e.name),
                };
            });
            this.maritalStatusOptions = this.appSettings.maritalStatus ? this.appSettings.maritalStatus.map((e) => {
                return {
                    value: e.id,
                    name: this.$tBnd(e.name),
                };
            }) : [];
            this.getUserInfo();
        },
        mounted() {
            loadFbSdk(this.client_configs.facebookAppId).then((response) => { });
        },
        methods: {
            getUserInfo() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_GET_PROFILE)
                    .then((data) => {
                        this.userInfo = data.result;
                        var default_language = data.result.userClaims.find(
                            (x) => x.claimType == this.appSettings.userClaimType.DefaultLanguage
                        );
                        if (default_language) {
                            this.userInfo.languageId = default_language.claimValue;
                        }
                        var default_timezone = data.result.userClaims.find(
                            (x) => x.claimType == this.appSettings.userClaimType.DefaultTimezone
                        );
                        if (default_timezone) {
                            this.userInfo.timezone = default_timezone.claimValue;
                        }
                        this.facebook_link_claim = data.result.userClaims.find(
                            (x) => x.claimType == this.appSettings.userClaimType.FacebookLink
                        );
                        if (this.facebook_link_claim) {
                            this.facebook_link = this.facebook_link_claim.claimValue;
                        }
                        this.google_link_claim = data.result.userClaims.find(
                            (x) => x.claimType == this.appSettings.userClaimType.GoogleLink
                        );
                        if (this.google_link_claim) {
                            this.google_link = this.google_link_claim.claimValue;
                        }
                        this.mappingText();
                        $loading.hide();
                        if (this.iframe)
                            this.editProfile()
                        // Convert Ngôn ngữ
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            mappingText() {
                if (this.userInfo.languageId) {
                    this.userInfo.language_text = this.languageOptions.find(
                        (x) => x.code === this.userInfo.languageId
                    ).name;
                }
                if (this.userInfo.birthdate) {
                    this.userInfo.birthdate_text = new Date(this.userInfo.birthdate)
                        .toJSON()
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/");
                }
                if (this.userInfo.gender) {
                    var gender_value = this.genderOptions.find(
                        (x) => x.value === this.userInfo.gender
                    ).name;
                    this.userInfo.gender_text = this.$tBnd(gender_value);
                }
                if (this.userInfo.maritalStatus) {
                    var maritalStatus_value = this.maritalStatusOptions.find(
                        (x) => x.value === this.userInfo.maritalStatus
                    ).name;
                    this.userInfo.maritalStatus_text = this.$tBnd(maritalStatus_value);
                }
            },
            updateGeneralInfo() {
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    this.$axios
                        .put(this.$api.USER_UPDATE_INFO, this.userInfoEdit)
                        .then((data) => {
                            // this.getEmail();
                            this.$success(this.$tBnd("info_noti_info_success"));
                            $loading.hide();
                            this.userInfo = { ...this.userInfoEdit }
                            this.mappingText();
                            if (typeof (window) != undefined && this.iframe)
                                window.flutter_inappwebview.callHandler('updateSuccess', 'info');
                            else
                                this.isEdit = false;
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                }
            },
            updateUserClaim(userClaim, callback) {
                const $loading = this.$loading.show();
                this.$axios
                    .put(this.$api.USER_UPDATE_CLAIM, userClaim)
                    .then((data) => {
                        // this.getEmail();
                        this.$success(this.$tBnd("info_noti_info_success"));
                        $loading.hide();
                        callback();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            editProfile() {
                this.userInfoEdit = { ...this.userInfo }
                if (!this.userInfoEdit.gender)
                    this.userInfoEdit.gender = null
                this.isEdit = true;
            },
            showLinkTo(type) {
                let claim = this.userInfo.userClaims.find((x) => x.claimType == type);
                if (claim) {
                    this.currentClaim = claim;
                } else {
                    this.currentClaim = {
                        ClaimType: type,
                        ClaimValue: null,
                    };
                }
                this.showLink = true;
            },
            hideLinkTo() {
                this.showLink = false;
            },
            loginViaFacebook(info) {
                var vm = this;
                if (info && info.token && info.email && info.id) {
                    var currentClaim = {
                        ClaimType: this.appSettings.userClaimType.FacebookLink,
                        ClaimValue: info.email,
                    };
                    this.$axios.post(this.$api.USER_CREATE_CLAIM, currentClaim).then(res => {
                        vm.facebook_link = info.email;
                    }).catch(er => {
                        this.$error(this.$tBnd("error_linking_by_other_user"));
                    })
                } else {
                    this.$error(this.$tBnd("login_invalid_external_info"));
                }
            },
            addLinkGoogle(info) {
                var vm = this;
                var ggInfo = this.parseJwt(info.credential)
                if (info && ggInfo.email && ggInfo.sub) {
                    var currentClaim = {
                        ClaimType: this.appSettings.userClaimType.GoogleLink,
                        ClaimValue: ggInfo.email,
                    };
                    this.$axios.post(this.$api.USER_CREATE_CLAIM, currentClaim).then(res => {
                        vm.google_link = ggInfo.email;
                    }).catch(er => {
                        this.$error(this.$tBnd("error_linking_by_other_user"));
                    })
                } else {
                    this.$error(this.$tBnd('login_invalid_external_info"'));
                }
            },
            parseJwt(token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            },
            logoutGoogle() {
                this.$confirm(
                    this.$tBnd("address_confirm_delete"),
                    this.$tBnd("address_confirm_confirm"),
                    this.$tBnd("address_confirm_cancel")
                ).then(() => {
                    google.accounts.id.revoke(this.google_link, done => {
                        console.log('google.accounts.id.revoke',done);
                    });
                    this.$axios.put(`${this.$api.ACCOUNT_CANCEL_LINKING}?claimId=${this.google_link_claim.id}&providerName=Google`).then(res => {
                        this.$success(this.$tBnd("remove_link_google_success"));
                        this.google_link = '';
                    }).catch((ex) => {
                        console.log(ex);
                    });

                    //var auth2 = gapi.auth2.getAuthInstance();
                    //auth2.signOut().then(function () {
                    //    var currentClaim = {
                    //        ClaimType: vm.appSettings.userClaimType.GoogleLink,
                    //        ClaimValue: "",
                    //    };
                    //    vm.updateUserClaim(currentClaim, () => {
                    //        vm.google_link = "";
                    //    });
                    //});
                    //auth2.disconnect();
                });
            },
            logoutFacebook() {
                var vm = this;
                this.$confirm(
                    this.$tBnd("address_confirm_delete"),
                    this.$tBnd("address_confirm_confirm"),
                    this.$tBnd("address_confirm_cancel")
                ).then(() => {
                    this.$axios.put(`${this.$api.ACCOUNT_CANCEL_LINKING}?claimId=${this.facebook_link_claim.id}&providerName=Facebook`).then(res => {
                        this.$success(this.$tBnd("remove_link_facebook_success"));
                        this.facebook_link = '';
                    }).catch((ex) => {
                        console.log(ex);
                    });
                    FB.getLoginStatus(function (response) {
                        if (response && response.status === "connected") {
                            FB.logout(function (response) { 
                            });
                        }
                    });
                });
            },
            showUploadAvatar() {
                this.isUploadAvatar = true;
            },
            closeUploadAvatar(obj) {
                if (obj) {
                    this.userInfoEdit.avatar = obj;
                    //Bắn event cho app
                    if (typeof (window) != "undefined" && window.flutter_inappwebview) {
                        window.flutter_inappwebview.callHandler('change_avatar', '');
                        return;
                    }
                }
                this.isUploadAvatar = false;
            },
        },
    };
</script>

<style scoped>
    .popup .modal-dialog {
        width: 400px;
        height: 304px;
    }
</style>
