<template>
  <div>
    <section class="jumbotron text-center">
      <div class="container">
        <h1 class="jumbotron-heading">
          {{$tBnd('default_language')}}
        </h1>
      </div>
    </section>
    <div class="album py-5 bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 border">
            <div class="container-login 100-form-btn">
              <div class="row p-4 recovery-item">
                <div class="form-group col-12">
                  <label>{{$tBnd("language_want_use")}}</label>
                  <select
                    v-model="value"
                    class="form-control"
                  >
                    <option
                      :value="item.code"
                      v-for="item in supportedLocales"
                      :key="item.code"
                    >{{item.name}}</option>
                  </select>
                </div>
                <div class="col-12 text-right">
                  <router-link
                    to="/"
                    class="btn btn-danger mr-3"
                  >
                    {{ $tBnd("cancel") }}
                  </router-link>
                  <button
                    class="btn btn-primary"
                    @click="save()"
                  >{{$tBnd('update')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'manager',
  data() {
    return {
      value: '',
    };
  },
  created() {
    this.getUserClaim();
  },
  methods: {
    save() {
      const $loading = this.$loading.show();
      this.$axios
        .post(this.$api.ACCOUNT_UPDATE_CLAIM, {
          claimType: 'default_language',
          claimValue: this.value,
        })
        .then((data) => {
          $loading.hide();
          this.$success(this.$tBnd('update_success'));
        })
        .catch((ex) => {
          $loading.hide();
        });
    },
    getUserClaim() {
      const $loading = this.$loading.show();
      this.$axios
        .get(this.$api.ACCOUNT_GET_CLAIM)
        .then((data) => {
          $loading.hide();
          var userClaim = data.result;
          var obj = userClaim.find((k) => k.type == 'default_language');
          if (obj) this.value = obj.value;
        })
        .catch((ex) => {
          $loading.hide();
        });
    },
  },
};
</script>

<style scoped>
.recovery-item {
  cursor: pointer;
}

.recovery-item:hover {
  background: #eeeeee;
  color: #0558be;
}
</style>
