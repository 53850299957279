export const SUPPORTED_LOCALES = [{
    code: 'vi-VN',
    base: '',
    name: 'Tiếng Việt',
    translations: './vi.json',
    dateFormat: 'DD/MM/YYYY',
    fullDateFormat: 'DD/MM/YYYY HH:mm'
}, {
    code: 'en-US',
    base: '/en-us',
    name: 'English',
    translations: './en.json',
    dateFormat: 'MM/DD/YYYY',
    fullDateFormat: 'MM/DD/YYYY HH:mm'
}, ]
