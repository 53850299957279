<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box acti-history">
                    <div class="box-head">
                        <h2>{{$tBnd('activity')}}</h2>
                    </div>
                    <template v-if="total">
                        <ul class="box-ct lst-ct mt-2">
                            <li class="d-flex" v-for="item in listData" :key="item.id">
                                <div class="avt-area">
                                    <bnd-web-avatar :src="currentUser.avatar" :username="currentUser.full_name || currentUser.name" class="w-100" :size="64" />
                                </div>
                                <div class="itm-txt d-flex flex-column justify-content-center">
                                    <span><em>{{currentUser.full_name || currentUser.name}}</em> <span v-html="item.actionText"></span></span>
                                    <span>{{item.createdDate | moment(fullDateFormat)}}</span>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-area mt-4">
                            <button type="button" class="btn-view-more" v-if="total > objData.pageIndex * objData.pageSize" @click="pageChange()">{{$tBnd('view_more')}}</button>
                        </div>
                        <!--<div class="text-right">
        <vue-pagination :total="total" :pageSize="objData.pageSize" v-model="objData.pageIndex" class="mt-2 " @change="pageChange" />
    </div>-->
                    </template>
                    <p v-else style="margin-top:10px; color:#808080">{{$tBnd('no_data')}}</p>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
        layout: 'manager',
        metaInfo: {
            title: 'Nhật ký hoạt động',
            titleTemplate: '%s | VitanID'
        },
        middleware: 'authenticated',
        data() {
            return {
                objData: {
                    pageIndex: 1,
                    pageSize: 10,
                },
                total: 0,
                listData: []
            };
        },
        created() {
            this.getData();
        },
        methods: {
            pageChange() {
                this.objData.pageIndex++;
                this.getData();
            },
            getData() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_GET_ACTIVITYLOG, { params: this.objData })
                    .then((response) => {
                        $loading.hide();
                        if (this.objData.pageIndex > 1)
                            this.listData.push(...response.result.data);
                        else
                            this.listData = response.result.data;
                        this.total = response.result.totalRows;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        this.$error(error);
                    });
            }
        },
    };
</script>
