<template>
    <main class="signup-page ct-signup" v-if="!initializing">
        <router-view></router-view>
    </main>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        data() {
            return {
                initializing: true,
                internalInterval: null
            }
        },
        methods: {
            ...mapActions('system', ['initSystem']),
            clearOIDC() {
                try {
                    if (typeof (window) != "undefined") {
                        if (window.oidcInterval)
                            clearInterval(window.oidcInterval);
                        window.oidcInterval = setInterval(() => {
                            this.removeStorageData();
                        }, 60 * 1000)
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            getMinutesBetweenDates(startDate, endDate) {
                const diff = endDate.getTime() - startDate.getTime();
                return (diff / 60000);
            },
            removeStorageData() {
                if (typeof (localStorage) != "undefined") {
                    var keys = Object.keys(localStorage)
                    if (keys) {
                        keys = keys.filter(k => k.startsWith('oidc') && !k.startsWith('oidc.user'))
                        for (let k of keys) {
                            try {
                                let val = localStorage.getItem(k);
                                if (val) {
                                    val = JSON.parse(val);
                                    if (val) {
                                        let created = new Date(val.created * 1000);
                                        let now = new Date();
                                        if (this.getMinutesBetweenDates(created, now) > 60) {
                                            localStorage.removeItem(k);
                                            console.log('remove item: ' + k);
                                        }
                                    }
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }
            }
        },
        created() {
            this.initSystem().then(() => {
                this.initializing = false;
            });
        },
        mounted() {
            if (window.self !== window.top) {
                parent.postMessage({
                    type: 'vitan-login-loaded'
                }, '*');

                let lastEmitHeight = 0;
                this.internalInterval = setInterval(() => {
                    let h = document.body.scrollHeight
                    let he = document.getElementById('real-height-check');
                    if (he) {
                        h = he.offsetHeight + 50
                    }
                    if (lastEmitHeight != h) {
                        lastEmitHeight = h;
                        parent.postMessage({
                            type: 'vitan-login-resize',
                            height: h
                        }, '*');
                    }
                }, 100)
            }
            this.removeStorageData();
            this.clearOIDC();
        },
        beforeDestroy() {
            if (this.internalInterval)
                clearInterval(this.internalInterval);
        }
    };
</script>
