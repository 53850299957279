<template>
    <div style="margin:100px auto; text-align:center;">
        <div class="lds-dual-ring"></div>
    </div>
</template>
<style>
    .lds-dual-ring { display: inline-block; width: 80px; height: 80px; }
        .lds-dual-ring:after { content: " "; display: block; width: 64px; height: 64px; margin: 8px; border-radius: 50%; border: 6px solid #6e4c40; border-color: #6e4c40 transparent #6e4c40 transparent; animation: lds-dual-ring 1.2s linear infinite; }

    @keyframes lds-dual-ring {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>
<script type="text/javascript">
    export default {
        created() {
            if (this.$route.query.token) {
                this.$axios.get(this.$api.ACCOUNT_LOGIN_TOKEN, { params: { token: this.$route.query.token } }).then(() => {
                    //Nếu trong iframe & có tham số callback thì gọi hàm callback
                    if (this.$route.query.cb && window.self !== window.top) {
                        window.parent.postMessage(this.$route.query.cb, window.document.referrer);
                    } else {
                        this.$router.push("/")
                        //window.location = this.$route.path;
                    }
                })
            } else {
                this.$router.push("/")
            }
        },
    };
</script>
