<template>
    <div style="margin:50px auto; text-align:center;">
        <h1 style="text-align:center"><span style="font-size:20pt"><span style="font-family:Arial"><span style="color:#000000">Hướng dẫn sửa lỗi kh&ocirc;ng đăng nhập được từ Facebook/Google</span></span></span></h1>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Khi bấm v&agrave;o đường dẫn được chia sẻ qua Zalo, Messenger&hellip;, Nếu bạn tiến h&agrave;nh đăng nhập VitanEdu bằng Facebook/ Google bạn c&oacute; thể nhận được th&ocirc;ng b&aacute;o lỗi như sau:</span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/ZYbnTwjydoGUHUlVrmWbXkxaF0gZk5jw2PQ98RlMrSaRsu_HnglBjEi_3VYaelsLNjMkNj_f-2N8CG7usJWugzcqn3Dr8zTbrUvGRANbBAcqLiJekfOmr2bnZe7cWFk15tC5BDMUnBsha4lkANQ" /></span></span></span></p>

        <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Hoặc lỗi tương tự g&acirc;y cho bạn kh&ocirc;ng thể đăng nhập được v&agrave;o VitanEdu.</span></span></span></p>

        <p>&nbsp;</p>

        <p><span style="font-size:17pt"><span style="font-family:Arial"><span style="color:#000000">C&aacute;ch khắc phục như sau:</span></span></span></p>

        <p>&nbsp;</p>

        <ol>
            <li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Bấm v&agrave;o n&uacute;t 3 chấm g&oacute;c tr&ecirc;n b&ecirc;n phải</span></span></span></li>
        </ol>

        <p style="margin-left:48px"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/wVvuGTLnt3TT0wj60tcdnwbE_nJGtpR579JMasxSKi-5nlzFZCcL_WI7IPnV--J86Ob0kdvO5BKe_gKojDGiUiU1WQ6G9p_I7OGlgJvK9hiHoInkn7vU6y-t8culIAW5bD5YbJXJ7-UifbaGekg" /></span></span></span></p>

        <p>&nbsp;</p>

        <ol start="2">
            <li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Chọn Mở bằng tr&igrave;nh duyệt (Open in Browser)</span></span></span></li>
        </ol>

        <p style="margin-left:48px"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/KlSwLXEd9izuvMKFQXESMnrDUngayDf401gcbf4izXF0ZklHVQKFGNr5iDGAcL-OLki7F5M-PKbOvSxQidiuE8MYD3hTgsee7CKaWlH26XNEUUP4AneYmQ4Br51F3kgX7D5Bq95w7jY_vMiMkdk" /></span></span></span></p>

        <ol start="3">
            <li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Tiến h&agrave;nh thao t&aacute;c đăng nhập lại b&igrave;nh thường.</span></span></span></li>
        </ol>

        <p>&nbsp;</p>

    </div>
</template>
<style>

</style>
<script type="text/javascript">
    export default {
    };
</script>
