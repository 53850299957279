<template>
    <div class="content-page home-page">
        <div class="container">
            <div class="main-ct">
                <div class="overview-info">
                    <div class="user-avt">
                        <bnd-web-avatar :src="currentUser.avatar" :username="currentUser.full_name || currentUser.name" :size="100" />
                    </div>
                    <strong class="txt-tit"> {{ $tBnd('welcome') }} {{currentUser.full_name || currentUser.name}}</strong>
                    <span class="txt-desc">
                        {{ $tBnd('welcome_desc') }}
                        <a :href="appSettings.configs.helpDomain" class="txt-link" target="_blank">
                            {{ $tBnd('looking_for_more_information') }}
                        </a>
                    </span>
                </div>
                <div class="box-info">
                    <ul class="row lst-itm">
                        <template v-for="(item, index) in listMenu.sub">
                            <li class="col-md-6" :key="index" v-if="item.description">
                                <div class="box">
                                    <router-link :to="getUrl(item)" class="itm-link">
                                        <strong class="txt-tit">
                                            <span class="line-color line-brown"></span>   {{$tBnd(item.staticKey)}}
                                        </strong>
                                        <p class="txt-desc">
                                            {{$tBnd(item.description)}}
                                        </p>
                                    </router-link>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="other-info">
                    <div class="box">
                        <div class="box-head">
                            <h2>  {{$tBnd('you_looking_for_more_information')}}</h2>
                        </div>
                        <ul class="box-ct lst-ct type2">
                            <!--<li>
                                <a href="javascript:void(0);" class="itm-link">
                                    <bnd-web-static-img src="id/icon-compass.svg" alt="" />
                                    <span class="txt-tit">Tìm kiếm trong cài đặt</span>
                                </a>
                            </li>-->
                            <li>
                                <a :href="appSettings.configs.helpDomain" class="itm-link" target="_blank">
                                    <bnd-web-static-img src="id/icon-email-b.svg" alt="" />
                                    <span class="txt-tit">{{$tBnd('center_support')}}</span>
                                </a>
                            </li>
                            <li>
                                <a :href="`${appSettings.configs.publicDomain}/about`"  target="_blank" class="itm-link">
                                    <bnd-web-static-img src="id/icon-intro.svg" alt="" />
                                    <span class="txt-tit">{{$tBnd('intro')}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="txt-comment d-flex align-items-center">
                    <p>
                        {{$tBnd('note_page_index')}}
                        <a :href="appSettings.configs.helpDomain" class="txt-link" target="_blank">
                            {{ $tBnd('looking_for_more_information') }}
                        </a>
                    </p>
                    <bnd-web-static-img src="id/img-security.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        layout: 'manager',
        metaInfo: {
            title: 'Quản lý tài khoản',
            titleTemplate: '%s | VitanID'
        },
        middleware: 'authenticated',
        data() {
            return {
                isActiveEmail: false,
                isActivePhone: false
            };
        },
        computed: {
            listMenu() {
                if (this.$store.state.bndSystem)
                    return this.$store.state.bndSystem.menuList.find(k => k.id == 0);
                return [];
            }
        },
        methods: {
            getUrl(item) {
                if (item.url) return item.url;
                if (item.sub && item.sub[0]) return item.sub[0].url;
                return "";
            }
        },
        created() {
            const $loading = this.$loading.show();
            this.$axios
                .get(this.$api.ACCOUNT_GET_ACTIVE_DATA)
                .then((response) => {
                    if (response.data.isOk) {
                        this.isActiveEmail = response.data.isActiveEmail;
                        this.isActivePhone = response.data.isActivePhone;
                    } else {
                        this.$error(this.responseData.msg);
                    }
                    $loading.hide();
                })
                .catch((ex) => {
                    $loading.hide();
                });
        }
    };
</script>
