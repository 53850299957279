<template>
    <div class="card" v-if="objData">
        <div class="card-body">
            <div class="card-text">
                <h3>{{getDeviceTypeName(objData.item.deviceType)}}</h3>
                <p>{{objData.item.deviceName}}</p>
                <p>{{$tBnd("first_login")}}: <b>{{objData.item.loginTime | moment(fullDateFormat)}}</b></p>
            </div>
        </div>
        <div class="card-footer">
            <ul>
                <li></li>
                <li>Ip: <b>{{objData.item.ip}}</b></li>
                <li>
                    {{$tBnd("recent_activity")}}: <b>
                        <span class=""
                              v-if="objData.minutes > 60">{{objData.item.loginTime | moment(fullDateFormat)}}</span>
                        <span v-else-if="objData.minutes == 0">{{$tBnd("Unknow")}}</span>
                        <span v-else>{{objData.minutes > 1 ? objData.minutes : 1  }} {{$tBnd("minute")}}</span>
                    </b>
                </li>
                <li v-if="browserList && browserList.length > 0">
                    {{$tBnd("browser")}}: <b>
                        <span class="mr-2"
                              :key="browser"
                              v-for="browser in browserList">
                            <i class="mr-1 text-primary"
                               :class="`fab fa-${objData.browser.toLowerCase()}`"></i>{{objData.browser}}
                        </span>
                    </b>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { getDeviceTypeName } from '../../../constants/device';
    export default {
        props: ['id'],
        data() {
            return {
                objData: null,
                minutes: 0,
                browserList: [],
            };
        },
        methods: {
            getDeviceTypeName(deviceType) {
                return getDeviceTypeName(deviceType);
            },
            getDetail() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_DEVICE_DETAIL + '?id=' + this.id)
                    .then((data) => {
                        this.objData = data.result;
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
        },
        created() {
            this.getDetail();
        },
    };
</script>

<style>
</style>
