<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box">
                    <div class="box-head">
                        <h2> {{$tBnd("devices")}}</h2>
                    </div>
                    <ul class="box-ct lst-ct lst-manager mt-2">
                        <li class="itm-device" :class="{'is-window': item.deviceType == 1}" v-for="item in devices" :key="item.id">
                            <div class="itm-txt d-flex flex-column justify-content-center position-relative">
                                <strong @click="detail(item.id)" class="cursor-pointer">{{getDeviceTypeName(item.deviceType)}} {{item.deviceName?item.deviceName:`[${$tBnd('unknow')}]`}} - <span>{{item.locationRegion || `[${$tBnd('location_uknow')}]`}}</span></strong>
                                <span v-if="item.isCurrentDevice" class="mt-2"><em class="txt-current">{{$tBnd("current_device")}}</em></span>
                                <template v-else>
                                    <span class="mt-2"
                                          v-if="item.minutes > 60">{{item.lastActionTime |  moment(fullDateFormat)}}</span>
                                    <span class="mt-2" v-else-if="!item.minutes">{{$tBnd("Unknow")}}</span>
                                    <span class="mt-2" v-else>{{item.minutes > 1 ? item.minutes : 1  }} {{$tBnd("minute")}}</span>
                                    <a href="javascript:void(0);" class="itm-link" @click="logoutDevice(item.id)">{{$tBnd("logout")}}</a>
                                </template>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="box">
                    <div class="box-head">
                        <h2> {{$tBnd("device_logout")}}</h2>
                    </div>
                    <ul class="box-ct lst-ct lst-manager mt-2">
                        <li class="itm-device" :class="{'is-window': item.deviceType == 1}" v-for="item in deviceLogOuts" :key="item.id">
                            <div class="itm-txt d-flex flex-column justify-content-center position-relative">
                                <strong>{{getDeviceTypeName(item.deviceType)}} {{item.deviceName  || $tBnd("Unknow")}} - <span>{{item.locationRegion ||`[${$tBnd('location_uknow')}]`}}</span></strong>
                                <span class="mt-2"
                                      v-if="item.minutes > 60">{{item.lastActionTime |  moment(fullDateFormat)}}</span>
                                <span class="mt-2" v-else-if="!item.minutes">{{$tBnd("Unknow")}}</span>
                                <span class="mt-2" v-else>{{item.minutes > 1 ? item.minutes : 1  }} {{$tBnd("minute")}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <modal v-if="popupOpen"
               id="modal-add"
               :footer="false"
               :width="400"
               :title="$tBnd('device_detail')"
               @close="popupOpen = false">
            <div slot="body">
                <deviceItem :id="id" />
            </div>
        </modal>
    </main>
</template>

<script>
    import deviceItem from './detail';
    import { getDeviceTypeName } from '@/constants/device';
    export default {
        layout: 'manager',
        metaInfo: {
            title: 'Quản lý thiết bị',
            titleTemplate: '%s | VitanID'
        },
        components: { deviceItem },
        data() {
            return {
                userDevices: [],
                popupOpen: false,
                id: 0
            };
        },
        computed: {
            devices() {
                return this.userDevices.filter((c) => !c.isLogOut);
            },
            deviceLogOuts() {
                return this.userDevices.filter((c) => c.isLogOut);
            },
        },
        created() {
            this.getListDevice();
        },
        methods: {
            getListDevice() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_DEVICE_LIST)
                    .then((data) => {
                        $loading.hide();
                        this.userDevices = data.result;
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            updateLogOut(item) {
                item.isLogOut = true;
            },
            getDeviceTypeName(deviceType) {
                return getDeviceTypeName(deviceType);
            },
            logoutDevice(deviceId) {
                this.$confirm(this.$tBnd('confirm_logout')).then(() => {
                    const $loading = this.$loading.show();
                    this.$axios
                        .get(this.$api.USER_LOGOUT_DEVICE + '?deviceId=' + deviceId)
                        .then((data) => {
                            $loading.hide();
                            this.$success(this.$tBnd('logout_success'));
                            this.getListDevice();
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                });
            },
            detail(id) {
                this.id = id;
                this.popupOpen = true;
            }
        },
    };
</script>

<style>
    .cursor-pointer {
        cursor:pointer
    }
</style>
