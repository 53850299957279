<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box type2 identity-box">
                    <div class="box-head">
                        <h2>{{$tBnd('kyc')}}</h2>
                    </div>
                    <div class="box-ct lst-ct mt-4">
                        <!-- [D] Màn 4.3.1 Xác minh danh tính thì dùng đoạn code này -->
                        <div class="txt-noti is-verified" v-if="kycStatus == $constants.KYC_STATUS.Wait">
                            <span class="itm-txt">{{$tBnd('account_awaiting_identity_verification')}}</span>
                            <div class="upload-img-id">
                                <ul class="row mb-5 pb-5">
                                    <li class="col-md-6 mb-3">
                                        <a href="javascript:;" class="box-upload upload-front d-flex flex-column justify-content-center align-items-center">
                                            <img :src="kyc_front" v-if="kyc_front" height="266" />
                                        </a>
                                    </li>
                                    <li class="col-md-6 mb-3">
                                        <a href="javascript:;" class="box-upload upload-front d-flex flex-column justify-content-center align-items-center">
                                            <img :src="kyc_back" v-if="kyc_back" height="266" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="txt-noti is-verified" v-else-if="kycStatus == $constants.KYC_STATUS.Verified">
                            <span class="itm-txt">{{$tBnd('account_verified_identity')}}</span>
                        </div>
                        <!-- [D] Màn 4.3.2 Xác minh danh tính thì dùng đoạn code này -->
                        <template v-else>
                            <div class="txt-noti" v-if="kycStatus == $constants.KYC_STATUS.Refuse">
                                <span class="itm-txt">{{$tBnd('refuse_kyc_account')}} </span>
                            </div>
                            <div class="txt-noti" v-else>
                                <span class="itm-txt">
                                    {{$tBnd('unverified_account')}}
                                    <!--<a href="javascript:;" class="txt-link">{{$tBnd('verify_now')}}</a>-->
                                </span>
                            </div>

                            <!-- [D] Màn 4.3.3 Upload xác minh danh tính thì dùng đoạn code này -->
                            <div class="upload-img-id">
                                <ul class="row mb-5 pb-5">
                                    <li class="col-md-6 mb-3">
                                        <bnd-web-file-upload v-model="kyc_front" :extSlot="true" exts=".gif, .jpg, .png, .jpeg">
                                            <a href="javascript:;" class="box-upload upload-front d-flex flex-column justify-content-center align-items-center">
                                                <img :src="kyc_front" v-if="kyc_front" height="266" />
                                                <template v-else>
                                                    <bnd-web-static-img src="id/icon-add.svg" />
                                                    <span>{{$tBnd('upload_front')}}</span>
                                                </template>
                                            </a>
                                        </bnd-web-file-upload>
                                    </li>
                                    <li class="col-md-6 mb-3">
                                        <bnd-web-file-upload v-model="kyc_back" :extSlot="true" exts=".gif, .jpg, .png, .jpeg">
                                            <a href="javascript:;" class="box-upload upload-front d-flex flex-column justify-content-center align-items-center">
                                                <img :src="kyc_back" v-if="kyc_back" height="266" />
                                                <template v-else>
                                                    <bnd-web-static-img src="id/icon-add.svg" />
                                                    <span>{{$tBnd('upload_back_side')}}</span>
                                                </template>
                                            </a>
                                        </bnd-web-file-upload>
                                    </li>
                                </ul>
                                <div class="btn-area btn-position mt-4">
                                    <button type="button" class="bnt-fix btn btn-update" @click="save">{{$tBnd('update')}}</button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <bnd-web-upload :width="700" @done="closeUpload" @close="closeUpload" v-if="isUpload" block="USER" />
    </main>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        metaInfo: {
            title: 'Xác minh danh tính',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                kycStatus: 0,
                isPasswordValid: false,
                password: '',
                isUpload: false,
                kyc_front: '',
                kyc_back: '',
                claimType: ''
            };
        },
        methods: {
            ...mapActions('user', ['logoutAll']),
            save() {
                if (!this.kyc_front || !this.kyc_back) {
                    return this.$error(this.$tBnd('please_upload_your_identity_captcha'));
                }
                var data = [];
                data.push({ 'claimType': 'kyc_front', 'claimValue': this.kyc_front })
                data.push({ 'claimType': 'kyc_back', 'claimValue': this.kyc_back })
                const $loading = this.$loading.show();
                this.$axios.post(this.$api.ACCOUNT_UPDATE_LIST_CLAIM, data)
                    .then(() => {
                        $loading.hide();
                        this.$success(this.$tBnd('info_noti_info_success'));
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            showUpload(claimType) {
                this.claimType = claimType;
                this.isUpload = true;
            },
            closeUpload(file) {
                if (file)
                    this[this.claimType] = file[0].fileUrl;
                this.isUpload = false;
            },
            checkKyc() {
                const $loading = this.$loading.show();
                this.$axios.get(this.$api.USER_GET_CHECK_KYC)
                    .then((res) => {
                        $loading.hide();
                        this.kycStatus = res.result;
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            getClaim() {
                const $loading = this.$loading.show();
                this.$axios.get(this.$api.ACCOUNT_GET_CLAIM)
                    .then((res) => {
                        $loading.hide();
                        var data = res.result;
                        if (data) {
                            this.kyc_back = data.find(k => k.type == "kyc_back");
                            this.kyc_front = data.find(k => k.type == "kyc_front");
                            if (this.kyc_back)
                                this.kyc_back = this.kyc_back.value;
                            if (this.kyc_front)
                                this.kyc_front = this.kyc_front.value;
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            }
        },
        created() {
            this.checkKyc();
            this.getClaim();
        }
    };
</script>

<style>
</style>
