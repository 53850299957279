<template>
    <main class="content-page manager-profile">
        <div class="container">
            <div class="main-ct">
                <div class="box-info">
                    <ul class="row lst-itm lst-profile" v-click-outside="hideSelect">

                        <li class="col-sm-6 col-xl-4"
                            v-for="item in listUserProfile"
                            :key="item.id">
                            <div class="
                  box
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  position-relative
                ">

                                <div class="avt-box">
                                    <bnd-web-file-preview v-if="item.avatar"
                                                          :fileUrl="item.avatar"
                                                          :itemWidth="100"
                                                          :itemHeight="100" />
                                    <bnd-web-static-img src="id/@avata-nodata.png"
                                                        alt=""
                                                        v-else />
                                </div>
                                <strong class="txt-name"> <a href="javascript:void(0):" @click="showProfile(item.id)">{{ item.displayName }}</a></strong>
                                <span class="txt-desc">{{ item.role }}</span>
                                <span class="note-user-normal" v-if="item.isDefault">{{$tBnd("userProfile_default_account") }}</span>
                                <div class="dropdown">
                                    <button type="button"
                                            class="btn-drop"
                                            @click="showTaskBar(item.id)">
                                        <bnd-web-static-img src="id/dot-vertical.svg" alt="" />
                                    </button>
                                    <!-- [D] Thêm class "show" vào class "drop-menu" khi click vào "btn-top" để hiện dropdown -->
                                    <div class="drop-menu"
                                         :class="{ show: item.id == currentId }">
                                        <a href="javascript:void(0);"
                                           class="drop-itm d-flex align-items-center"
                                           @click="editProfile(item.id)">
                                            <bnd-web-static-img src="id/icon-edit.svg" alt="" />
                                            <span> {{ $tBnd("userProfile_edit") }}</span>
                                        </a>
                                        <a href="javascript:void(0);"
                                           class="drop-itm d-flex align-items-center"
                                           @click="useProfile(item.id)">
                                            <bnd-web-static-img src="id/icon-doccument.svg" alt="" />
                                            <span>{{ $tBnd("userProfile_use") }}</span>
                                        </a>
                                        <a href="javascript:void(0);"
                                           class="drop-itm delete-itm d-flex align-items-center"
                                           @click="deleteProfile(item.id)">
                                            <bnd-web-static-img src="id/icon-recyclebin.svg" alt="" />
                                            <span>{{ $tBnd("userProfile_delete") }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="col-sm-6 col-xl-4">
                            <div class="
                  box
                  add-new-profile
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  position-relative
                ">
                                <span class="tit">{{ $tBnd("userProfile_add") }}</span>
                                <div class="avt-box">
                                    <bnd-web-static-img src="id/@avata-nodata.png" alt="" />
                                </div>
                                <div class="custom-input">
                                    <form autocomplete="on">
                                        <input type="text"
                                               id="ipt-name"
                                               class="ipt"
                                               v-model="createProfile.displayName" />
                                    </form>

                                    <label for="ipt-name" class="lbl">
                                        {{
                    $tBnd("userProfile_edit_displayname")
                                        }}
                                    </label>
                                </div>
                                <button type="button" class="btn btn-sm" @click="addProfile">
                                    {{ $tBnd("userProfile_btn_add") }}
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import vClickOutside from "v-click-outside";
    export default {
        directives: {
            clickOutside: vClickOutside.directive,
        },
        layout: "manager",
        metaInfo: {
            title: 'Hồ sơ',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                listUserProfile: [],
                createProfile: { name: null, role: null },
                submitted: false,
                currentId: null,
            };
        },
        created() {
            this.getData();
        },
        validations: {
            createProfile: {
                displayName: { required },
            },
        },
        methods: {
            getData() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_PROFILE_GET_ALL)
                    .then((data) => {
                        $loading.hide();
                        this.listUserProfile = data.result;
                        if (this.listUserProfile && this.listUserProfile.length) {
                            var df = this.listUserProfile.find(k => k.isDefault);
                            if (df)
                                this.$router.push("profile/" + df.id);
                            else
                                this.$router.push("profile/" + this.listUserProfile[0].id);
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            addProfile() {
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    this.$axios
                        .post(this.$api.USER_PROFILE_CREATE, this.createProfile)
                        .then((data) => {

                            $loading.hide();
                            this.$success(this.$tBnd("noti_add"));
                            this.getUserInfo();
                            this.currentId = null;
                            this.$router.push("profile/" + data.result);
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                } else
                    this.$error(this.$tBnd("profile_name_required"));
            },
            editProfile(id) {
                this.$router.push("profile/" + id + "?isEdit=true");
            },
            showProfile(id) {
                this.$router.push("profile/" + id);
            },
            deleteProfile(id) {
                this.$confirm(
                    this.$tBnd("confirm_delete"),
                    this.$tBnd("confirm"),
                    this.$tBnd("cancel")
                ).then(() => {
                    const $loading = this.$loading.show();
                    this.$axios
                        .delete(`${this.$api.USER_PROFILE_DELETE}?userProfileId=${id}`)
                        .then((data) => {
                            $loading.hide();
                            this.$success(this.$tBnd("noti_delete"));
                            this.getUserInfo();
                            this.currentId = null;
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                });
            },
            useProfile(id) {
                this.submitted = true;
                const $loading = this.$loading.show();
                this.$axios
                    .put(`${this.$api.USER_PROFILE_USE}?userProfileId=${id}`)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("noti_use"));
                        this.currentId = null;
                        this.getUserInfo();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            showTaskBar(id) {
                if (this.currentId === id) {
                    this.currentId = null;
                } else {
                    this.currentId = id;
                }
            },
            hideSelect() {
                this.currentId = null;
            },
        },
    };
</script>

<style scoped>
</style>
