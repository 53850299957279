import Vue from 'vue';
import apiUrl from '../constants/api';

export const API_URL = 'https://id.vitanedu.com';

Vue.prototype.$api = {

    ...apiUrl,

    getApiError: (ex) => {
        let errorData = ex.response.data.responseException.exceptionMessage;
        if (!errorData.errors) {
            if (errorData.responseException)
                return errorData.responseException.exceptionMessage
            else
                return errorData;
        }

        let msg = '';
        const exceptionMessage = errorData.errors;
        if (exceptionMessage) {
            const arr = [];
            for (const property in exceptionMessage) {
                arr.push(exceptionMessage[property]);
            }
            msg = arr.join(',');
        }
        return msg;
    },
    getApiErrorObject: (ex) => JSON.parse(getApiError(ex)),

    // convert from form-data to x-www-form-urlencoded
    transformRequest: (jsonData = {}) => Object.entries(jsonData)
        .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join('&'),

    resentOTP: (context) => {
        const $loading = context.$loading.show();
        context.$axios
            .post(context.$api.ACCOUNT_RESEND_OTP, {
                Username: context.$store.state.user.info.name,
            })
            .then((response) => {
                $loading.hide();
                context.seconds = response.data.times;
                context.$api.setTimerOTP(context);
            })
            .catch((ex) => {
                $loading.hide();
                const error = context.$api.getApiError(ex);
                if (error.error === 'invalid_grant') {
                    context.$error(context.$t(error.error_description));
                } else {
                    context.$error(error);
                }
            });
    },

    setTimerOTP: (context) => {
        context.intervalTimer = setInterval(() => {
            if (context.seconds <= 0) clearInterval(context.intervalTimer);
            context.seconds--;
        }, 1000);
    },
};
