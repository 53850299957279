<template>
    <div class="block-box-choose-profile"
         :class="{
      active: isOpen,
    }">
        <div class="title-block-choose-profile" @click="toggle($event)">
            <div class="flex-title">
                <slot name="header">
                    <span class="item-title">Nội dung 1 </span>
                </slot>
            </div>
            <div class="icon-right-block">
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="7"
                     height="13"
                     viewBox="0 0 7 13"
                     fill="none">
                    <path d="M7 6.12502C7 6.34456 6.91597 6.56408 6.74825 6.73146L1.46804 11.9987C1.13215 12.3338 0.587565 12.3338 0.251814 11.9987C-0.0839379 11.6638 -0.0839379 11.1206 0.251814 10.7855L4.92404 6.12502L0.251976 1.46448C-0.0837752 1.12941 -0.0837752 0.586323 0.251976 0.251421C0.587728 -0.0838069 1.13231 -0.0838069 1.4682 0.251421L6.74842 5.51857C6.91616 5.68604 7 5.90556 7 6.12502Z"
                          fill="#414B5B"></path>
                </svg>
            </div>
        </div>
        <slot name="body">
            <div class="content-block-choose-profile"></div>
        </slot>
    </div>
</template>
<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isOpen: this.show,
            };
        },
        watch: {
            'show'(val) {
                if (this.isOpen != val)
                    this.isOpen = val;
            }
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
                this.$emit('input', this.isOpen)
            },
        },
    };
</script>

