/* global window, FB, document */

const fields = ['id', 'name', 'first_name', 'middle_name', 'last_name', 'gender', 'locale', 'languages', 'link',
    'age_range', 'birthday', 'cover', 'education', 'email', 'hometown', 'location', 'picture', 'quotes', 'website', 'work'].join(',');
export function loadFbSdk(appId, version) {
    return new Promise((resolve) => {
        window.fbAsyncInit = function () { // eslint-disable-line func-names
            FB.init({
                appId,
                xfbml: true,
                cookie: true,
                version: 'v3.1',
                autoLogAppEvents: true,
            });
            FB.AppEvents.logPageView();
            resolve('FB SDK Loaded!');
        };
        (function (d, s, id) { // eslint-disable-line func-names
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return resolve('FB SDK Loaded!'); }
            const js = d.createElement(s); js.id = id;
            js.src = '//connect.facebook.net/vi_VN/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}

export function getLoginStatus() {
    return new Promise((resolve) => {
        window.FB.getLoginStatus((responseStatus) => {
            resolve(responseStatus);
        });
    });
}

//export function fblogin(options) {
//    return new promise((resolve) => {
//        window.fb.login((response) => {
//            if (response.authresponse) {
//                fb.api('/me', 'get', { fields }, (rs) => {
//                    rs.token = response.authresponse.accesstoken;
//                    resolve(rs);
//                });
//            }
//        }, options);
//    });
//}
export function fbLogin(options) {
    return new Promise((resolve) => {
        window.FB.getLoginStatus((response) => {
            if (response.status !== 'connected') {
                return window.FB.login((response1) => {
                    window.FB.api('/me', 'get', { fields }, (rs) => {
                        rs.token = response1.authresponse.accesstoken;
                        resolve(rs);
                    });
                }, options);
            } else {
                window.FB.api('/me', 'get', { fields }, (rs) => {
                    rs.token = response.authResponse.accessToken;
                    resolve(rs);
                });
            }
        });
    });
}
export function fbLogout() {
    return new Promise((resolve) => {
        window.FB.logout((response) => { resolve(response); });
    });
}
