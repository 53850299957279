<template>
    <a href="javascript:;"
       class="resend-authen-code disable"
       v-if="otpSeconds > 0">
        {{ $tBnd("otp_resend_in") }}
        <span class="countdown" v-if="otpSeconds > 0">{{ text }}</span>
    </a>
    <a href="javascript:;"
       class="resend-authen-code"
       @click="sendCode"
       v-else>
        {{isSendOtp ? $tBnd("register_resend_otp") : $tBnd("send_code")}}
        <span class="countdown" v-if="otpSeconds > 0">{{ text }}</span>
    </a>
</template>

<script>
    export default {
        name: 'OtpCountDown',
        props: {
            seconds: {
                type: Number,
                default: 0
            },
            isSendOtp: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            text() {
                if (!this.otpSeconds) return "";
                else {
                    var html = "";
                    var s = this.otpSeconds % 60;
                    var m = Math.floor(this.otpSeconds / 60)

                    if (m < 10)
                        m = `0${m}`
                    if (s < 10)
                        s = `0${s}`
                    html = `${m}:${s}`;
                    return html;
                }
            }
        },
        data() {
            return {
                otpSeconds: 0,
                intervalTimer: null
            };
        },
        watch: {
            seconds() {
                this.otpSeconds = this.seconds;
                if (!this.intervalTimer)
                    this.setTimerOTP();
            }
        },
        created() {
            this.otpSeconds = this.seconds;
            this.setTimerOTP();
        },
        methods: {
            sendCode() {
                this.$emit("sendCode")
            },
            setTimerOTP() {
                this.otpSeconds = this.seconds;
                this.intervalTimer = setInterval(() => {
                    if (this.otpSeconds <= 0) {
                        clearInterval(this.intervalTimer);
                        this.intervalTimer = null;
                    }
                    this.otpSeconds--;
                }, 1000);
            },
        },
        beforeDestroy() {
            if (this.intervalTimer)
                clearInterval(this.intervalTimer);
        }
    };
</script>
