/* global process */
import Vue from 'vue';
import axios from 'axios';
import VueCookie from 'vue-cookie';
import { API_URL } from './api';
import { cookieConstant } from '../constants/cookie';
import Auth from './auth';
import nuxtConfig from '../../nuxt.config';

const authSettings = new Auth().getSetting();
axios.defaults.baseURL = API_URL;

function setToken(accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}
function setTimezone(timezone) {
    axios.defaults.headers.common['Timezone'] = timezone;
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon';
if (timezone) { axios.defaults.headers.common['VitanEdu-Local-Time-Zone-Iana'] = timezone; }


//const accessToken = VueCookie.get(cookieConstant.ACCESSTOKEN);
//if (accessToken) setToken(accessToken);

//axios.setToken = (accessToken) => {
//    setToken(accessToken);
//};

let acessToken = null;
if (typeof (localStorage) !== 'undefined') {
    try {
        const oidcUser = JSON.parse(localStorage.getItem(`oidc.user:${authSettings.authority}:${authSettings.client_id}`) || null);
        if (oidcUser) { acessToken = oidcUser.access_token; }
    } catch (e) {

    }
}
if (acessToken) { setToken(acessToken); }

axios.setHeader = (newLocale) => {
    axios.defaults.headers.common['Accept-Language'] = newLocale;
};

var apiRewrite = {
    '/api/checkout': {
        target: `${nuxtConfig.paymentApiUrl}/api/checkout`
    },
    '/api/messenger': {
        target: `${nuxtConfig.messengerApiUrl}/api/messenger`
    }
}

axios.interceptors.request.use((config) => {
    if (config.url) {
        for (const p in apiRewrite) {
            if (config.url.lastIndexOf(p) !== -1) {
                config.url = apiRewrite[p].target + config.url.split(p)[1];
            }
        }
    }
    return config;
})

axios.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    return Promise.reject(error);
});

export default axios;

Vue.prototype.$axios = axios;
Vue.use(axios);
