<template>
    <div>
        <div id="myModal"
             :class="'modal fade ' + cls"
             tabindex="-1"
             role="dialog"
             aria-labelledby="myModalLabel"
             aria-hidden="true"
             style="display:block"
             @click.self="close()">
            <div :class="modalCls" :style="style">
                <div class="modal-content">
                    <div v-if="header" class="modal-header">
                        <h5 id="myModalLabel" class="modal-title mt-0" v-html="title" />
                        <button type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                                @click="forceClose">
                            <bnd-web-static-img src="id/close.png"/>
                        </button>
                    </div>
                    <div :class="`modal-body ${bodyCls}`">
                        <slot name="body">{{this.$tBnd('Chưa có nội dung. Thêm div slot="body" để thay thế!')}}</slot>
                    </div>
                    <div v-if="footer" class="modal-footer">
                        <slot name="footer">
                            <button type="button"
                                    class="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                    @click="forceClose">
                                {{this.$tBnd('Đóng')}}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
        <slot name="outside" />
    </div>
</template>
<style>
    @media (min-width: 600px) {
        .modal-huge {
            max-width: 100%;
        }
    }
</style>
<script>
    export default {
        name: 'Modal',
        props: {
            title: {
                type: String,
                default: 'Thông báo'
            },
            width: {
                type: Number,
                default: 600
            },
            height: {
                type: Number,
                default: 100
            },
            noCloseBackground: {
                type: Boolean,
                default: true
            },
            footer: {
                type: Boolean,
                default: true
            },
            header: {
                type: Boolean,
                default: true
            },
            size: {
                type: String,
                default: ''
            },
            bodyCls: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                cls: '',
                tempId: null
            };
        },
        computed: {
            style() {
                if (this.size != 'huge') return { 'max-width': this.width + 'px' || '600px' };
                return {};
            },
            modalCls() {
                let cls = 'modal-dialog modal-dialog-centered';
                if (this.size == 'large') cls += ' modal-lg';
                else if (this.size == 'huge') cls += ' modal-huge';
                return cls;
            }
        },
        mounted() {
            this.tempId = 'modal' + new Date().getTime();
            document.getElementsByTagName('html')[0].classList.add('modal-open');
            let backDrop = document.createElement('div');
            backDrop.setAttribute('id', this.tempId + 'popupBackdrop');
            backDrop.setAttribute('class', 'modal-backdrop fade');
            document.getElementsByTagName('body')[0].appendChild(backDrop);
            setTimeout(() => {
                this.cls = 'show';
                let b2 = document.getElementById(this.tempId + 'popupBackdrop');
                b2.className += ' show';
            }, 100);
        },
        destroyed() {
            var modalCount = document.getElementsByClassName('modal').length;
            if (modalCount == 0)
                document.getElementsByTagName('html')[0].classList.remove('modal-open');
            this.cls = '';
            let b2 = document.getElementById(this.tempId + 'popupBackdrop');
            if (b2) {
                b2.parentNode.removeChild(b2);
            }
        },
        methods: {
            close() {
                if (!this.noCloseBackground) {
                    this.forceClose();
                }
            },
            forceClose() {
                this.cls = '';
                setTimeout(() => {
                    this.$emit('close');
                }, 200);
            }
        }
    };
</script>
