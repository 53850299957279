<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box type2 verify-steps">
                    <div class="box-head">
                        <h2>{{$tBnd('2_step_verification')}}</h2>
                    </div>
                    <div class="box-ct">
                        <!-- Khi đã liên kết với ứng dụng -->
                        <ul class="lst-ct-sec ">
                            <li>
                                <h3 class="tit">{{$tBnd('used_authentication_2fa')}}</h3>
                                <div class="ct-desc position-relative">
                                    <!--Xác minh 2 bước để đảm bảo an toàn bảo mật-->
                                    <p class="txt-detail">{{$tBnd('2fa_verification_to_ensure_security')}}</p>
                                    <div class="switch-btn">
                                        <label class="switch">
                                            <input type="checkbox" v-model="enable2FA" @change="change2FA">
                                            <span class="slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="box type2 verify-steps" v-if="enable2FA">
                    <div class="box-head">
                        <h2>Xác thực qua ứng dụng Authenticator</h2>
                    </div>
                    <div class="box-ct">
                        <!-- Nội dung các bước xác thực -->
                        <ul class="lst-ct-sec" v-if="authenticatorDetails">
                            <li v-if="step == 0 && authenticatorDetails.hasAuthenticator">
                                <div class="ct-desc position-relative d-flex flex-column justify-content-center align-items-center mt-4">
                                    <div class="img-area"><bnd-web-static-img src="id/img-lock-security.svg" alt="" /></div>
                                    <p class="text-center">{{$tBnd('account_has_2_step_authentication_enabled')}}</p>
                                </div>
                                <div class="wp-btn-ggAuthen mt-4 mt-md-5">
                                    <!--<div class="item-btn">
                                        <button type="button" class="btn back" @click="openConfirm('off')">{{$tBnd("off")}}</button>
                                    </div>-->
                                    <div class="item-btn" style="margin:auto">
                                        <button type="button" class="btn" @click="openConfirm('reset')">{{$tBnd("reset_authenticator")}}</button>
                                    </div>
                                </div>
                            </li>
                            <li v-else>
                                <div class="about-ggAuthen mb-4 mb-lg-5">
                                    <div class="icon"><bnd-web-static-img src="id/gg-authen.png" alt="" /></div>
                                    <div class="text" v-html="$tBnd('google_authenticator_intro')"></div>
                                </div>

                                <div class="steps-connect-authenApp mb-4 mb-lg-5">
                                    <div class="item-nav-step-authenApp" :class="{'active': step == 1,'done': step > 1}">
                                        <div class="number mb-2">
                                            <span class="nb-st">B1</span>
                                            <span class="icon-check">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="38" viewBox="0 0 48 38" fill="none">
                                                    <path d="M18.3801 37.2679C17.9219 37.7378 17.2968 38 16.6474 38C15.9981 38 15.3729 37.7378 14.9147 37.2679L1.07704 23.1574C-0.359013 21.6933 -0.359013 19.3191 1.07704 17.8578L2.80972 16.0907C4.24622 14.6266 6.57218 14.6266 8.00823 16.0907L16.6474 24.8993L39.9918 1.09809C41.4283 -0.366031 43.7565 -0.366031 45.1903 1.09809L46.923 2.86511C48.359 4.32924 48.359 6.70295 46.923 8.16479L18.3801 37.2679Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="text">{{$tBnd("download_google_authenticator")}}</div>
                                    </div>
                                    <span class="line-connect " :class="{'done': step > 1}"></span>
                                    <div class="item-nav-step-authenApp" :class="{'active': step == 2,'done': step > 2}">
                                        <div class="number mb-2">
                                            <span class="nb-st">B2</span>
                                            <span class="icon-check">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="38" viewBox="0 0 48 38" fill="none">
                                                    <path d="M18.3801 37.2679C17.9219 37.7378 17.2968 38 16.6474 38C15.9981 38 15.3729 37.7378 14.9147 37.2679L1.07704 23.1574C-0.359013 21.6933 -0.359013 19.3191 1.07704 17.8578L2.80972 16.0907C4.24622 14.6266 6.57218 14.6266 8.00823 16.0907L16.6474 24.8993L39.9918 1.09809C41.4283 -0.366031 43.7565 -0.366031 45.1903 1.09809L46.923 2.86511C48.359 4.32924 48.359 6.70295 46.923 8.16479L18.3801 37.2679Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="text">{{$tBnd("confirm_password")}}</div>
                                    </div>
                                    <span class="line-connect" :class="{'done': step > 2}"></span>
                                    <div class="item-nav-step-authenApp" :class="{'active': step == 3,'done': step > 3}">
                                        <div class="number mb-2">
                                            <span class="nb-st">B3</span>
                                            <span class="icon-check">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="38" viewBox="0 0 48 38" fill="none">
                                                    <path d="M18.3801 37.2679C17.9219 37.7378 17.2968 38 16.6474 38C15.9981 38 15.3729 37.7378 14.9147 37.2679L1.07704 23.1574C-0.359013 21.6933 -0.359013 19.3191 1.07704 17.8578L2.80972 16.0907C4.24622 14.6266 6.57218 14.6266 8.00823 16.0907L16.6474 24.8993L39.9918 1.09809C41.4283 -0.366031 43.7565 -0.366031 45.1903 1.09809L46.923 2.86511C48.359 4.32924 48.359 6.70295 46.923 8.16479L18.3801 37.2679Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="text">{{$tBnd("add_key_to_google_authenticator")}}</div>
                                    </div>
                                    <span class="line-connect" :class="{'done': step > 3}"></span>
                                    <div class="item-nav-step-authenApp" :class="{'active': step == 4,'done': step > 4}">
                                        <div class="number mb-2">
                                            <span class="nb-st">B4</span>
                                            <span class="icon-check">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="38" viewBox="0 0 48 38" fill="none">
                                                    <path d="M18.3801 37.2679C17.9219 37.7378 17.2968 38 16.6474 38C15.9981 38 15.3729 37.7378 14.9147 37.2679L1.07704 23.1574C-0.359013 21.6933 -0.359013 19.3191 1.07704 17.8578L2.80972 16.0907C4.24622 14.6266 6.57218 14.6266 8.00823 16.0907L16.6474 24.8993L39.9918 1.09809C41.4283 -0.366031 43.7565 -0.366031 45.1903 1.09809L46.923 2.86511C48.359 4.32924 48.359 6.70295 46.923 8.16479L18.3801 37.2679Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="text">{{$tBnd("confirm_code_to_complete")}}</div>
                                    </div>
                                </div>

                                <div class="content-detail-step-ggAuthen">
                                    <div class="item-content-step-authenApp" v-if="step == 1">
                                        <div class="title-nb-step">
                                            <span class="line"></span>
                                            <span class="text">{{$tBnd('step1')}}</span>
                                            <span class="line"></span>
                                        </div>
                                        <div class="main-ct-step">
                                            <div class="ops-type-store mb-4">
                                                <div class="icon"><bnd-web-static-img src="id/ios-icon.png" alt="" /></div>
                                                <div class="ct">
                                                    <h3 class="ops">{{$tBnd('ios_users')}}</h3>
                                                    <span class="guide" v-html="$tBnd('ios_appstore')"></span>
                                                </div>
                                            </div>
                                            <div class="ops-type-store">
                                                <div class="icon"><bnd-web-static-img src="id/android-icon.png" alt="" /></div>
                                                <div class="ct">
                                                    <h3 class="ops">{{$tBnd('android_users')}}</h3>
                                                    <span class="guide" v-html="$tBnd('android_appstore')"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-content-step-authenApp" v-else-if="step == 2">
                                        <div class="title-nb-step">
                                            <span class="line"></span>
                                            <span class="text">{{$tBnd('step2')}}</span>
                                            <span class="line"></span>
                                        </div>
                                        <div class="main-ct-step">
                                            <div class="des-about-step mb-4">{{$tBnd('enter_your_login_password_to_verify')}}</div>
                                            <div class="form-pass-authen text-center">
                                                <div class="custom-input">
                                                    <input-password v-model="password" :label="$tBnd('password')" @enter="checkPassword" />
                                                </div>
                                                <router-link to="/forgot-password" class="txt-link mt-2">
                                                    {{ $tBnd("fogot_password") }}?
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-content-step-authenApp" v-else-if="step == 3">
                                        <div class="title-nb-step">
                                            <span class="line"></span>
                                            <span class="text">{{$tBnd('step3')}}</span>
                                            <span class="line"></span>
                                        </div>
                                        <div class="main-ct-step">
                                            <div class="des-about-step mb-4">{{$tBnd('google_authenticator_user_manual')}}</div>
                                            <div class="connect-qr-code" v-if="displayAuthenticator">
                                                <div class="qr mb-4">
                                                    <vue-qrcode :value="authenticatorUri" class="qrcode" :width="170" />
                                                </div>
                                                <div class="key text-center d-none">
                                                    <h3 class="title">{{$tBnd('lock_code')}}:</h3>
                                                    <div class="code-dt text-center">
                                                        <span class="txt">{{sharedKey.join('')}}</span>
                                                        <a href="javascript:;" class="copy" @click="copyKey">{{$tBnd('copy')}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-ct">
                                                <ul class="lst-ct-sec">
                                                    <li>
                                                        <h3 class="tit">{{$tBnd('backup_code_login')}}</h3>
                                                        <div class="ct-desc position-relative">
                                                            <p class="txt-detail">{{$tBnd('login_code_warning')}}</p>
                                                            <div class="lst-code" id="sharedKey">
                                                                <span class="txt-code" v-for="k in sharedKey" :key="k">{{k}}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-content-step-authenApp" v-else-if="step == 4">
                                        <div class="title-nb-step">
                                            <span class="line"></span>
                                            <span class="text">{{$tBnd('step4')}}</span>
                                            <span class="line"></span>
                                        </div>
                                        <div class="main-ct-step">
                                            <div class="des-about-step mb-4">{{$tBnd('google_authenticator_note_enter_code')}}</div>
                                            <div class="form-verf-code-google">
                                                <div class="custom-input">
                                                    <input type="text" id="verf-code" class="ipt" v-model="verificationCode">
                                                    <label for="verf-code" class="lbl">{{$tBnd('input_6digits')}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="wp-btn-ggAuthen mt-4 mt-md-5">
                                    <div class="item-btn">
                                        <button type="button" class="btn back" :disabled="step < 1" :style="{'opacity': step < 2 ? 0.3 : 1}" @click="prevStep">{{$tBnd('back')}}</button>
                                    </div>
                                    <div class="item-btn">
                                        <button type="button" class="btn" @click="nextStep">{{$tBnd('register_next_step')}}</button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCheckPass">
            <div class="modal fade popup show" id="popup-xt-security">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center">
                            <h5 class="modal-title">{{$tBnd("security_authentication")}}</h5>
                        </div>
                        <div class="modal-body type2">
                            <div class="custom-input mt-3 mb-2">
                                <input type="password" id="addPhoneNb" class="ipt" v-model="password">
                                <label for="addPhoneNb" class="lbl">{{$tBnd("enter_password_confirm")}}</label>
                            </div>
                            <span class="txt" v-if="false">Việc đặt lại ứng dụng xác thực mới này sẽ huỷ toàn bộ liên kết với ứng dụng trước đó</span>
                        </div>
                        <div class="modal-footer justify-content-around">
                            <button type="button" class="btn btn-light" data-dismiss="modal" @click="showCheckPass = false">{{$tBnd("cancel")}}</button>
                            <button type="button" class="btn btn-complete" @click="checkPassword(actionType)">{{$tBnd("confirm")}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal1633602150733popupBackdrop" class="modal-backdrop fade show"></div>
        </div>
    </main>
</template>

<script>
    import VueQrcode from 'vue-qrcode';
    export default {
        layout: 'manager',
        metaInfo: {
            title: 'Xác thực 2 bước',
            titleTemplate: '%s | VitanID'
        },
        components: {
            VueQrcode,
        },
        data() {
            return {
                authenticatorDetails: {},
                displayAuthenticator: false,
                recoveryCodes: [],
                verificationCode: '',
                authenticatorUri: '',
                sharedKey: [],
                authenticatorNeedsSetup: false,
                enable2FA: false,
                password: '',
                step: 0,
                showCheckPass: false,
                actionType: '',
                afterGetDetail: false
            };
        },
        created() {
            this.getauthenticatorDetails();
        },
        watch: {
            
        },
        methods: {
            prevStep() {
                if (this.step > 1)
                    this.step -= 1;
            },
            nextStep() {
                if (this.step == 2)
                    this.checkPassword();
                else if (this.step == 4)
                    this.verifyAuthenticator();
                else
                    this.step += 1;
            },
            onChange() {
                if (this.enable2FA) {
                    this.setupAuthenticator();
                } else
                    this.disable2Fa();
            },
            openConfirm(type) {
                this.actionType = type;
                this.showCheckPass = true;
            },
            checkPassword(actionType) {
                if (!this.password) {
                    return this.$error(this.$tBnd('error_password_empty'))
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_PASSWORD, { password: this.password })
                    .then(() => {
                        $loading.hide();
                        if (actionType == "off")
                            this.disable2Fa();
                        else if (actionType == "reset")
                            this.resetAuthenticator();
                        else {
                            this.step += 1;
                            this.setupAuthenticator();
                        }
                        this.showCheckPass = false;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        this.$error(this.$tBnd('invalid_password'));
                        this.showCheckPass = false;
                    });
            },
            getauthenticatorDetails() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.TWOFACTORAUTHENTICATION_DETAIL)
                    .then((data) => {
                        $loading.hide();
                        this.authenticatorDetails = data.result;
                        this.enable2FA = this.authenticatorDetails.twoFactorEnabled;
                        if (!this.authenticatorDetails.hasAuthenticator)
                            this.nextStep();
                        this.afterGetDetail = true;
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            setupAuthenticator() {
                this.authenticatorNeedsSetup = false;
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.TWOFACTORAUTHENTICATION_SETUP_AUTHENTICATOR)
                    .then((data) => {
                        $loading.hide();
                        this.sharedKey = data.result.sharedKey.split(' ');
                        this.authenticatorUri = data.result.authenticatorUri;
                        this.displayAuthenticator = true;
                        this.verificationCode = '';
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            verifyAuthenticator() {
                if (!this.verificationCode) {
                    return this.$error(this.$tBnd('please_enter_verification_code'))
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.TWOFACTORAUTHENTICATION_VERIFY_AUTHENTICATOR, {
                        verificationCode: this.verificationCode,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.authenticatorDetails.hasAuthenticator = true;
                        this.displayAuthenticator = false;
                        this.recoveryCodes = data.result;
                        this.authenticatorNeedsSetup = false;
                        this.step = 0;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        this.$error(this.$tBnd('authentication_code_is_incorrect'));
                    });
            },
            disable2Fa() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.TWOFACTORAUTHENTICATION_DISABLE2FA)
                    .then((data) => {
                        $loading.hide();
                        this.authenticatorDetails.twoFactorEnabled = false;
                        this.$success(this.$tBnd('update_success'));
                        this.getauthenticatorDetails();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            enable2FaMethod() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.TWOFACTORAUTHENTICATION_ENABLE2FA)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd('enable_2fa_success'));
                        this.authenticatorDetails = {};
                        this.step = 1;
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            resetRecoveryCode() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.TWOFACTORAUTHENTICATION_GENERATECODE)
                    .then((data) => {
                        $loading.hide();
                        this.recoveryCodes = data.result;
                        this.$success(this.$tBnd('update_success'));
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            resetAuthenticator() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.TWOFACTORAUTHENTICATION_RESET_AUTHENTICATOR)
                    .then((data) => {
                        $loading.hide();
                        this.recoveryCodes = [];
                        this.authenticatorDetails.twoFactorEnabled = false;
                        this.authenticatorNeedsSetup = true;
                        this.$success(this.$tBnd('update_success'));
                        this.getauthenticatorDetails();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            copyKey() {
                this.copyText(this.sharedKey.join(''));
            },
            change2FA(val) {
                if (this.afterGetDetail) {
                    if (val.target.checked) {
                        this.enable2FaMethod();
                    } else {
                        this.disable2Fa();
                    }
                }
            }
        },
    };
</script>
<style>
    #sharedKey .txt-code {
        width: 80px;
        height: 40px;
        margin: 4px;
        font-size: 18px;
        line-height: 40px;
    }
</style>
