<template>
    <main role="main">
        <section class="jumbotron text-center">
            <div class="container">
                <h1 class="jumbotron-heading">
                    {{$tBnd("change_email")}}
                </h1>
            </div>
        </section>

        <div class="album py-5 bg-light">
            <div class="container">
                <!--start step 1-->
                <div v-if="step == 1"
                     class="row">
                    <div class="col-md-4 col-centered">
                        <div class="form-label-group">
                            <h4>{{ this.$store.state.user.info.email }}</h4>
                        </div>
                        <div class="form-label-group">
                            <label for="NewEmail">{{ $tBnd('new_email') }}</label>
                            <input id="NewEmail"
                                   v-model="dataEmail.newemail"
                                   type="text"
                                   class="form-control"
                                   :placeholder="$tBnd('new_email')"
                                   required="">
                        </div>
                        <button class="btn btn-lg btn-primary btn-block mt-2 "
                                type="button"
                                @click="ChangeEmail">
                            {{ $tBnd('next') }}
                        </button>
                    </div>
                </div>
                <!--end step 1-->
                <!--start step 2-->
                <div v-if="step == 2"
                     class="row">
                    <div class="col-md-4 col-centered">
                        <div class="wrap-input100 m-b-20">
                            <b>{{ $tBnd("please_enter_otp_code") }}</b>
                        </div>
                        <div class="wrap-input100 m-b-20">
                            <input id="Otp"
                                   v-model="dataEmail.otp"
                                   class="input100"
                                   type="text"
                                   :placeholder="$tBnd('otp_input')"
                                   maxlength="6">
                            <span class="focus-input100" />
                        </div>
                        <div class="otp-time m-b-20">
                            <label v-if="seconds > 0"
                                   class="mb-3">{{ $tBnd("time_remaining") }}: <b>{{ seconds }}s</b></label>
                            <a v-else
                               href="javascript:;"
                               @click="ResendOTP">{{ $tBnd("register_resend_otp") }}</a>
                        </div>
                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn"
                                    type="button"
                                    @click="ChangeEmail">
                                {{ $tBnd("update") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--end step 2-->
                <!--start step 3-->
                <div v-if="step == 3"
                     class="row">
                    <div class="col-md-4 col-centered">
                        <div class="wrap-input100 m-b-20">
                            <b>Thay đổi địa chỉ email thành công</b>
                        </div>
                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn"
                                    type="button"
                                    @click="GoHome">
                                {{ $tBnd("go_home") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--end step 3-->
            </div>
        </div>
    </main>
</template>
<style>
    .col-centered { float: none; margin: 0 auto; }
</style>
<script>
    export default {
        layout: 'manager',
        middleware: 'authenticated',
        data() {
            return {
                step: 1,
                seconds: 0,
                dataEmail: {
                    newemail: '',
                    otp: '',
                },
                responseData: null,
            };
        },
        created() {
            console.log(this.$store.state.user);
        },
        methods: {
            ChangeEmail() {
                if (this.step == 2 && this.dataEmail.otp == '')
                    return this.$error(this.$tBnd('otp_is_required'));
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHANGE_EMAIL, this.dataEmail)
                    .then((data) => {
                        $loading.hide();
                        if (this.step === 1 && this.dataEmail.otp === '') {
                            this.step = 2;
                            this.seconds = data.result.times;
                            this.$api.setTimerOTP(this);
                        } else {
                            this.$success(this.$tBnd('change_email_success'));
                            this.step = 3;
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            ResendOTP() {
                this.$api.resentOTP(this);
            },
            GoHome() {
                this.$auth.signIn();
            },
        },
    };
</script>
