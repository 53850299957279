<template>
    <!--<a class="login100-social-item google" href="#" @click.stop.prevent="buttonClicked">
          <i :class="getIconCls" /><span />
      </a>-->
    <!--<button type="button"
            class="choice-google"
            @click.stop.prevent="buttonClicked">
        <slot name="body">
            <bnd-web-static-img src="id/logo-google-sm.svg" alt="" />
            <span class="txt-mxh">Google</span>
        </slot>
    </button>-->
    <div>
        <div id="g_id_onload"
             :data-client_id="ggClientId"
             data-context="signin"
             data-ux_mode="popup"
             data-auto_prompt="false"
             data-callback="callBackLoginGG">
        </div>
        <div class="g_id_signin"
             data-type="standard"
             data-shape="rectangular"
             data-theme="outline"
             data-text="signin with google"
             data-size="large"
             data-locale="vi"
             data-logo_alignment="left"
             data-width="200">
        </div>
    </div>
</template>
<script>
    //import { loadGoogleSdk, googleLogout, googleLogin } from "./helpers.js";
    export default {
        name: "GoogleLogin",
        props: {
            apiKey: {
                type: String,
                required: true,
            },
            clientId: {
                type: String,
                required: true,
            },
            loginLabel: {
                type: String,
                default: "",
            },
            loginOptions: {
                type: Object,
                default() {
                    return {
                        scope: "email",
                    };
                },
            },
        },
        data() {
            return {
                isWorking: false,
                isConnected: false,
                ggClientId: this.clientId
                //ggClientId: '928892817768-pg846ae972eqnclicqd4t8rmarq0unfc.apps.googleusercontent.com'
            };
        },
        created() {
            window.callBackLoginGG = this.handleCredentialResponse;
        },
        computed: {
        },
        mounted() {
            //loadGoogleSdk(this.apiKey, this.clientId).then((response) => {
            //    this.isConnected = true;
            //});
            this.loadJS();
        },
        methods: {
            //buttonClicked() {
            //    this.$emit("click");
            //    if (!this.isWorking) {
            //        if (this.isConnected) {
            //            this.logout();
            //        } else {
            //            this.login();
            //        }
            //    }
            //},
            //logout() {
            //    this.isWorking = true;
            //    googleLogout().then((response) => {
            //        this.isWorking = false;
            //        this.isConnected = false;
            //        this.$emit("logout", response);
            //    });
            //},
            login() {
                this.isWorking = true;
                googleLogin(this.loginOptions)
                    .then((response) => {
                        if (response.status === "connected") {
                            this.isConnected = true;
                        } else {
                            this.isConnected = false;
                        }
                        this.isWorking = false;
                        this.$emit("login", response);
                    })
                    .catch(() => {
                        this.isWorking = false;
                    });
            },
            handleCredentialResponse(response) {
                this.$emit('login', response);
            },
            loadJS() {
                const elOld = document.getElementById('sdkJs');
                if (elOld) {
                    elOld.remove();
                }
                var scriptTag = document.createElement('script');
                scriptTag.src = "https://accounts.google.com/gsi/client";
                scriptTag.id = "sdkJs";
                document.getElementsByTagName('head')[0].appendChild(scriptTag);
            }
        },
    };
</script>
