<template>
    <!--<a class="login100-social-item" href="#" @click.stop.prevent="buttonClicked">
          <i :class="getIconCls" /><span>{{ getButtonText }}</span>
      </a>-->

    <button type="button"
            class="choice-facebook ml-0"
            @click.stop.prevent="buttonClicked"
            :disabled="!isLoadedFbSdk">
        <slot name="body">
            <bnd-web-static-img src="id/logo-fb.svg" alt="" />
            <span class="txt-mxh" :class="{'ml-2':isLinking}">{{$tBnd(loginLabel)}}</span>
        </slot>
    </button>
</template>
<script>
    import { loadFbSdk, fbLogout, fbLogin } from "./helpers.js";
    export default {
        name: "FacebookLogin",
        props: {
            appId: {
                type: String,
                required: true,
            },
            version: {
                type: String,
                default: "v2.9",
            },
            logoutLabel: {
                type: String,
                default: "Thoát",
            },
            loginLabel: {
                type: String,
                default: "login_with_facebook",
            },
            loginOptions: {
                type: Object,
                default() {
                    return {
                        scope: "email",
                    };
                },
            },
            isLinking: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isWorking: false,
                isConnected: false,
                isLoadedFbSdk: false
            };
        },
        computed: {
            getButtonText() {
                return this.loginLabel;
            },
            getIconCls() {
                if (this.isWorking) {
                    return "fa fa-spinner fa-pulse";
                }
                return "fab fa-facebook-f";
            },
        },
        mounted() {
            this.isWorking = true;
            loadFbSdk(this.appId, this.version).then((response) => {
                this.isConnected = false;
                this.isWorking = false;
                this.$emit("sdk-loaded", {
                    isConnected: this.isConnected,
                    FB: window.FB,
                });
                this.isLoadedFbSdk=true
            });
        },
        methods: {
            buttonClicked() {
                this.$emit("click");
                try {
                    window.fbAsyncInit();
                } catch (ex) { }
                this.login();
            },
            logout() {
                this.isWorking = true;
                fbLogout().then((response) => {
                    this.isWorking = false;
                    this.isConnected = false;
                    this.$emit("logout", response);
                });
            },
            login() {
                //if (this.isFacebookApp()) {
                //    this.$info(this.$tBnd('message_login_with_facebook_app'))
                //    return;
                //}
                this.isWorking = true;
                fbLogin(this.loginOptions).then((response) => {
                    if (response.status === "connected") {
                        this.isConnected = true;
                    } else {
                        this.isConnected = false;
                    }
                    this.isWorking = false;
                    this.$emit("login", response);
                });
            },
            isFacebookApp() {
                var ua = navigator.userAgent || navigator.vendor || window.opera;
                return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
            }
        },
    };
</script>
