/* Định nghĩa đường dẫn các api */
const apiUrl = {
    CONFIG_GET: '/api/v1.0/Config',
    SYSTEM_INIT_DATA: '/api/v1.0/AppSetting/Init',

    //Acount
    ACCOUNT_REGISTER: '/api/v1.0/Account/Register',
    ACCOUNT_LOGIN: '/api/v1.0/Account/Login',
    ACCOUNT_LOGIN_EXTERNAL: '/api/v1.0/Account/LoginExternal',
    ACCOUNT_LOGOUT: '/api/v1.0/Account/Logout',
    ACCOUNT_LOGOUT_ALL: '/api/v1.0/Account/LogoutAll',
    ACCOUNT_CHECK_USERNAME_AND_SEND_OTP: '/api/v1.0/Account/CheckUserNameAndSendOTP',
    ACCOUNT_RESEND_OTP: '/api/v1.0/Account/ReSendOTP',
    ACCOUNT_CHECK_USERNAME: '/api/v1.0/Account/CheckUserName',
    ACCOUNT_CHECK_PROCESS_REGIST: '/api/v1.0/Account/checkUserProcessRegist',
    ACCOUNT_CHECK_OTP_ADD_USER: '/api/v1.0/Account/CheckRegistOTPAndAddUser',
    ACCOUNT_UPDATE_USER: '/api/v1.0/Account/Update',
    ACCOUNT_SET_FIRST_PASSWORD: '/api/v1.0/Account/SetFirstPassword',
    ACCOUNT_INFO: '/api/v1.0/connect/userinfo',
    ACCOUNT_FOGOT_PASSWORD: '/api/v1.0/Account/ForgotPassword',
    ACCOUNT_RESET_PASSWORD: '/api/v1.0/Account/ResetPassword',
    ACCOUNT_REQUEST_VERIFY_PROMPT: '/api/v1.0/Account/RequestVerifyPrompt',
    ACCOUNT_RESEND_OTP_FOGOT_PASSWORD: '/api/v1.0/Account/ResendOTPFogotPassword',
    ACCOUNT_CHECK_FOGOT_PASSWORD_OTP: '/api/v1.0/Account/CheckFogotPasswordOTP',
    ACCOUNT_CHECK_PROCESS_FOGOT_PASSWORD: '/api/v1.0/Account/CheckUserProcessFogotPassword',
    ACCOUNT_CHECK_RESET_PASSWORD_EXTENAL_REGISTER: '/api/v1.0/Account/CheckResetPasswordExtenalRegister',
    TWOFACTORAUTHENTICATION_DETAIL: '/api/v1.0/TwoFactorAuthentication/Details',
    TWOFACTORAUTHENTICATION_SETUP_AUTHENTICATOR: '/api/v1.0/TwoFactorAuthentication/SetupAuthenticator',
    TWOFACTORAUTHENTICATION_DISABLE2FA: '/api/v1.0/TwoFactorAuthentication/Disable2FA',
    TWOFACTORAUTHENTICATION_ENABLE2FA: '/api/v1.0/TwoFactorAuthentication/Enable2FA',
    TWOFACTORAUTHENTICATION_GENERATECODE: '/api/v1.0/TwoFactorAuthentication/GenerateRecoveryCodes',
    TWOFACTORAUTHENTICATION_VERIFY_AUTHENTICATOR: '/api/v1.0/TwoFactorAuthentication/VerifyAuthenticator',
    TWOFACTORAUTHENTICATION_RESET_AUTHENTICATOR: '/api/v1.0/TwoFactorAuthentication/ResetAuthenticator',
    ACCOUNT_LOGIN_2FA: '/api/v1.0/twoFactorAuthentication/login',
    ACCOUNT_LOGIN_2FA_PROMT: '/api/v1.0/twoFactorAuthentication/LoginByPromt',
    ACCOUNT_LOGIN_2FA_RECOVERY_CODE: '/api/v1.0/twoFactorAuthentication/loginWithRecovery',
    ACCOUNT_LOGIN_2FA_SEND_CODE: '/api/v1.0/twoFactorAuthentication/SendCode',
    ACCOUNT_CHECK_PASSWORD: '/api/v1.0/Account/CheckPassword',
    ACCOUNT_LOGIN_OTP_SUPPORT: '/api/v1.0/Account/GetLoginOTPSupport',
    ACCOUNT_LOGIN_CHECK: '/api/v1.0/Account/CheckAccount',
    ACCOUNT_LOGIN_BY_OTP: '/api/v1.0/Account/LoginByOTP',
    ACCOUNT_LOGIN_SEND_OTP: '/api/v1.0/Account/SendLoginOTP',
    ACCOUNT_CHANGE_EMAIL: '/api/v1.0/Account/ChangeEmail',
    ACCOUNT_ACTIVE_EMAIL: '/api/v1.0/Account/ActiveEmail',
    ACCOUNT_CHANGE_PHONE: '/api/v1.0/Account/ChangePhone',
    ACCOUNT_ACTIVE_PHONE: '/api/v1.0/Account/ActivePhone',
    ACCOUNT_GET_ACTIVE_DATA: '/api/v1.0/Account/getActiveData',
    ACCOUNT_GET_ERROR: '/api/v1.0/Account/Error',
    ACCOUNT_CHANGE_PASSWORD: '/api/v1.0/Account/ChangePassword',
    ACCOUNT_GET_QRCODE: '/api/v1.0/Account/GetLoginQRCodeImage',
    ACCOUNT_LOGIN_QRCODE: '/api/v1.0/Account/LoginUseQRCode',
    ACCOUNT_GETOTP_METHOD: '/api/v1.0/Account/GetOTPMethod',
    ACCOUNT_CANCEL_LINKING: '/api/v1.0/Account/CancelLinking',

    //UserManager
    USER_SAVE_PERMISSION: '/api/v1.0/UserMananger/SavePermission',
    USER_PERMISSION_GET_LIST: '/api/v1.0/UserMananger/GetListUserPermission',
    USER_GET_BY_ID: '/api/v1.0/UserMananger/GetById',
    USER_CHECK_REQUIRE_PASSWORD: '/api/v1.0/UserMananger/CheckRequirePassword',
    USER_SAVE: '/api/v1.0/UserMananger/Save',
    USER_SET_PASSWORD: '/api/v1.0/UserMananger/SetPassword',
    ACCOUNT_LOCK: '/api/v1.0/UserMananger/Lock',
    ACCOUNT_UNLOCK: '/api/v1.0/UserMananger/UnLock',
    ACCOUNT_GET_LIST: '/api/v1.0/UserMananger/GetList',
    ACCOUNT_UPDATE_CLAIM: '/api/v1.0/UserMananger/UpdateClaim',
    ACCOUNT_UPDATE_LIST_CLAIM: '/api/v1.0/UserMananger/UpdateListUserClaim',
    ACCOUNT_GET_CLAIM: '/api/v1.0/UserMananger/GetUserClaim',
    ACCOUNT_DELETE: '/api/v1.0/UserMananger/Delete',
    ACCOUNT_UPDATE_INFO: '/api/v1.0/UserMananger/UpdateInfo',
    ACCOUNT_GET_UPDATE_INFO: '/api/v1.0/UserMananger/GetUserInfo',
    ACCOUNT_UPDATE_TWOFACTOR: '/api/v1.0/UserMananger/UpdateTwoFactor',
    USER_GET_GENDERS: '/api/v1.0/UserMananger/GetGenders',
    USER_GET_ACTIVITYLOG: '/api/v1.0/UserMananger/GetActivityLog',
    USER_GET_CHECK_KYC: '/api/v1.0/UserMananger/CheckKyc',
    

    //User Device
    USER_DEVICE_LIST: '/api/v1.0/UserDevice/GetByUser',
    USER_DEVICE_DETAIL: '/api/v1.0/UserDevice/GetUserDevice',
    USER_LOGOUT_ALL: "/api/v1.0/UserDevice/LogOutAll",
    USER_LOGOUT_DEVICE: "/api/v1.0/UserDevice/LogOutByDevice",


    //Module
    MODULE_GET_LIST: '/api/v1.0/Module/GetAll',
    MODULE_GET_BY_ID: '/api/v1.0/Module/GetById',
    MODULE_SAVE: '/api/v1.0/Module/Save',
    MODULE_DELETE: '/api/v1.0/Module/Delete',
    MODULE_GET_LIST_WITH_TREE: '/api/v1.0/Module/GetListWithTree',

    //Group
    GROUP_GET_LIST: '/api/v1.0/Group/GetAll',
    GROUP_GET_BY_ID: '/api/v1.0/Group/GetById',
    GROUP_SAVE: '/api/v1.0/Group/Save',
    GROUP_DELETE: '/api/v1.0/Group/Delete',
    GROUP_GET_LIST_WITH_TREE: '/api/v1.0/Group/GetListWithTree',
    GROUP_SAVE_PERMISSION: '/api/v1.0/Group/SavePermission',
    GROUP_PERMISSION_GET_LIST: '/api/v1.0/Group/GetListGroupPermission',
    GROUP_GET_ALL_PERMISSION: '/api/v1.0/Group/GroupGetAllPermission',

    //Permission
    PERMISSION_GET_LIST: '/api/v1.0/Permission/GetList',

    CONFIG_LIST: '/api/v1.0/Config/GetConfigs',
    // Email Phone
    EMAIL_PHONE_GET_ALL: '/api/v1.0/UserPhoneEmail/GetAll',
    EMAIL_PHONE_CREATE: '/api/v1.0/UserPhoneEmail/Create',
    EMAIL_PHONE_CHECKEXISTED: '/api/v1.0/UserPhoneEmail/CheckExistedEmailPhone',
    EMAIL_PHONE_SEND_OTP: '/api/v1.0/Account/SendActiveOTP',
    EMAIL_PHONE_ACTIVE_EMAIL: '/api/v1.0/Account/ActiveEmailMulti',
    EMAIL_PHONE_ACTIVE_PHONE: '/api/v1.0/Account/ActivePhoneMulti',
    EMAIL_PHONE_DELETE: '/api/v1.0/UserPhoneEmail/Delete', // Xóa không có otp
    EMAIL_PHONE_DELETE_OTP: '/api/v1.0/Account/DeleteEmailPhone',
    EMAIL_PHONE_SET_PRIMARY: '/api/v1.0/UserPhoneEmail/SetPhoneEmailPrimary',
    EMAIL_PHONE_SET_ALLOW_LOGIN: '/api/v1.0/UserPhoneEmail/SetAllowLogin',

    // Address
    USER_ADDRESS_GET_ALL: '/api/v1.0/UserAddress/GetAll',
    USER_ADDRESS_CREATE: '/api/v1.0/UserAddress/Create',
    USER_ADDRESS_GET_BY_ID: '/api/v1.0/UserAddress/GetById',
    USER_ADDRESS_UPDATE: '/api/v1.0/UserAddress/Update',
    USER_ADDRESS_DELETE: '/api/v1.0/UserAddress/DELETE',
    USER_NAME_PHONE: '/api/v1.0/UserMananger/GetUserNameAndPhone',
    CHECK_DEFAULT_ADDRESS: '/api/v1.0/UserAddress/CheckDefaultAddress',
    // UserInfo
    USER_GET_PROFILE: '/api/v1.0/UserMananger/GetGeneralInfor',
    USER_UPDATE_INFO: '/api/v1.0/UserMananger/UpdateGeneralInfor',
    USER_UPDATE_CLAIM: '/api/v1.0/UserMananger/UpdateUserClaim',
    USER_REMOVE_CLAIM: '/api/v1.0/UserMananger/RemoveClaim',
    USER_CREATE_CLAIM: '/api/v1.0/UserMananger/CreateClaim',
    // UserProfile
    USER_PROFILE_GET_ALL: '/api/v1.0/UserProfile/GetAll',
    USER_PROFILE_CREATE: '/api/v1.0/UserProfile/Create',
    USER_PROFILE_GET_BY_ID: '/api/v1.0/UserProfile/GetById',
    USER_PROFILE_UPDATE: '/api/v1.0/UserProfile/Update',
    USER_PROFILE_DELETE: '/api/v1.0/UserProfile/DELETE',
    USER_PROFILE_USE: '/api/v1.0/UserProfile/UseAsDefault',
    USER_PROFILE_INFO: '/api/v1.0/UserProfile/GetUserProfile',

    USER_NOTIFICATION_GET_CONFIG: '/api/v1.0/UserNotificationConfig/GetConfig',
    USER_NOTIFICATION_SAVE_CONFIG: '/api/v1.0/UserNotificationConfig/Save',
    ACCOUNT_LOGIN_TOKEN: '/api/v1.0/Account/LoginByToken',

    //UserPoint
    USER_POINT_HISTORY: '/api/v1.0/Public/UserPoint/GetHistory',
    USER_POINT: '/api/v1.0/Public/UserPoint/GetPoint',

    // Payment
    GET_LIST_ORDER: '/api/checkout/v1.0/Order/GetMyListOrder',
    GET_DETAIL_ORDER: '/api/checkout/v1.0/Order/GetById',
    GET_COUNT_ORDER_BY_USERS: '/api/checkout/v1.0/Order/GetCountByUserIds',

    //
    GET_STATIC_LOGO: '/v1.0/public/StaticPage/GetByBlockAndCode?blockId=12&code=web-index'
};
export default apiUrl;
