<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box type2">
                    <div class="box-head">
                        <h2 v-if="!requirePasswor&& (currentUser.idp==='Google'||currentUser.idp==='Facebook')">{{$tBnd("set_password")}}</h2>
                        <h2 v-else>{{$tBnd("change_password")}}</h2>
                    </div>
                    <div class="box-ct">
                        <div class="row mb-5 pb-5" v-if="step == 1">
                            <div class="col-md-6 m-order2">
                                <ul class="row lst-itm-info">
                                    <li class="col-md-12" v-if="requirePasswor || (currentUser.idp!=='Google'&& currentUser.idp!=='Facebook')">
                                        <div class="custom-input">
                                            <input-password v-model="dataPassword.currentpassword" :label="$tBnd('current_password')" />
                                        </div>
                                    </li>
                                    <li class="col-md-12">
                                        <div class="custom-input">
                                            <input-password v-model="dataPassword.newpassword" :label="$tBnd('new_password')" :showGuide="true" />
                                        </div>
                                        <!--<span class="txt-mess">Yêu cầu tối thiểu 6 ký tự</span>-->
                                    </li>
                                    <li class="col-md-12">
                                        <!-- [D] Thêm class "is-err" vào class "custom-input" khi xuất hiện trạng thái error input-->
                                        <div class="custom-input">
                                            <input-password v-model="dataPassword.confirmnewpassword" :label="$tBnd('confirm_new_password')" />
                                        </div>
                                        <!--<span class="txt-mess">Mật khẩu không trùng khớp</span>-->
                                    </li>
                                    <li class="col-md-12">
                                        <button type="button" class="btn btn-update" @click="(requirePasswor || (currentUser.idp!=='Google'&& currentUser.idp!=='Facebook'))?changePassword():setFirstPassword()"> {{ $tBnd('next') }}</button>
                                    </li>
                                </ul>
                                <router-link class="txt-link mb-4" to="/forgot-password" v-if="!iframe && (requirePasswor || (currentUser.idp!=='Google'&& currentUser.idp!=='Facebook'))">
                                    {{ $tBnd("fogot_password") }}?
                                </router-link>
                            </div>
                            <div class="col-md-6">
                                <div class="box-gray">
                                    <div class="strength-password d-flex flex-wrap align-items-center">
                                        <strong>{{$tBnd('security_strength')}}</strong>
                                        <span class="itm-level" :class="{'is-pass': passwordStrength >= l }" v-for="l in 4"></span>
                                    </div>
                                    <div class="guide-lst mt-2">
                                        <ol>
                                            <!--<li>({{$tBnd('recommend')}})</li>-->
                                            <li>1. {{$tBnd('use_at_least_6_characters')}}</li> <!--Sử dụng ít nhất 6 ký tự-->
                                            <li>2. {{$tBnd('contains_at_least_1_digit')}}</li> <!--Chứa ít nhất 1 chữ số-->
                                            <li>3. {{$tBnd('contains_at_least_1_special_character')}}</li> <!--Chứa ít nhất 1 ký tự viết hoa-->
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5 pb-5" v-else>
                            <div class="col-md-6 m-order2">
                                <ul class="row lst-itm-info">
                                    <!--<li class="col-md-12" v-if="false">
                                        <div class="authen-changePass-idPage">
                                            <div class="custom-input selbox">
                                                <button type="button" class="btn-selbox ipt">Số điện thoại:0976123456</button>
                                                <label class="lbl">Nhận mã xác thực qua</label>
                                                <div class="selbox-lst-area scroll">
                                                    <ul class="selbox-lst">
                                                        <li><button type="button" class="sel-value">Số điện thoại: 0976123456</button></li>
                                                        <li><button type="button" class="sel-value">Email:demoemail@gmail.com</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <button class="resend-otp-chanegPass disable">Gửi mã <span class="countdown">(60s)</span></button>
                                        </div>
                                    </li>-->

                                    <li class="col-md-12">
                                        <!--<div class="custom-input">
                                            <input type="number" name="otp" id="authen-changePass" class="ipt" v-model="dataPassword.otp">
                                            <label for="authen-changePass" class="lbl">{{$tBnd('otp_input')}}</label>
                                            <a href="javascript:;" class="resend-authen-code disable" v-if="seconds > 0">
                                                {{ $tBnd("otp_resend_in") }}
                                                <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                                            </a>
                                            <a href="javascript:;" class="resend-authen-code" @click="resendOTP" v-else>
                                                {{$tBnd('register_resend_otp') }}
                                            </a>
                                        </div>-->
                                        <otpMethod v-model="dataPassword.otp" @changeMethod="changeMethod" />
                                    </li>
                                    <li class="col-md-12">
                                        <div class="out-device-changePass">
                                            <ul>
                                                <li class="form-check form-check-inline radio-check">
                                                    <input class="form-check-input" type="radio" name="addr-type" v-model="isLogout" :value="false" id="out-device-1" checked>
                                                    <label class="form-check-label" for="out-device-1"> {{ $tBnd("keep_login") }}</label>
                                                </li>

                                                <li class="form-check form-check-inline radio-check mt-2">
                                                    <input class="form-check-input" type="radio" name="addr-type" v-model="isLogout" :value="true" id="out-device-2">
                                                    <label class="form-check-label" for="out-device-2"> {{ $tBnd("logout_all") }}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="col-md-12">
                                        <button type="button" class="btn btn-update" @click="changePassword"> {{ $tBnd("update") }}</button>
                                        <a class="a-cancel" @click="cancel()"> {{ $tBnd("cancel") }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<style>
    .col-centered { float: none; margin: 0 auto; }
</style>
<script>
    import otpMethod from "@/components/common/otp-method";
    import { mapActions } from "vuex";
    export default {
        components: { otpMethod },
        props: ['iframe'],
        metaInfo: {
            title: 'Đổi mật khẩu',
            titleTemplate: `%s | VitanID`
        },
        layout: 'manager',
        middleware: 'authenticated',
        data() {
            return {
                step: 1,
                seconds: 0,
                dataPassword: {
                    currentpassword: '',
                    newpassword: '',
                    confirmnewpassword: '',
                    otp: '',
                },
                showCurrentPassword: true,
                responseData: null,
                isLogout: false,
                requirePasswor: true
            };
        },
        computed: {
            passwordStrength() {
                var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
                var mediumRegex = new RegExp("^(?=.{6,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
                var enoughRegex = new RegExp("(?=.{6,}).*", "g");
                var pwd = this.dataPassword.newpassword;
                if (pwd.length == 0) {
                    return 0;
                } else if (false == enoughRegex.test(pwd)) {
                    return 1;
                } else if (strongRegex.test(pwd)) {
                    if (pwd.length > 8) return 4;
                    return 3;
                } else if (mediumRegex.test(pwd)) {
                    return 2;
                } else {
                    return 1;
                }
                return 0;
            }
        },
        created() {
            this.checkRequirePassword();
            const $loading = this.$loading.show();
            this.$axios
                .get(this.$api.ACCOUNT_HAS_TEMP_PASSWORD)
                .then((data) => {
                    this.responseData = data.result;
                    if (this.responseData && this.responseData.isOk) {
                        this.showCurrentPassword = !this.responseData.data;
                    } else {
                        this.$error(this.responseData.msg);
                    }
                    $loading.hide();
                })
                .catch((ex) => {
                    console.log(ex);
                    $loading.hide();
                });
        },
        methods: {
            ...mapActions('user', ['logoutAll']),
            changeMethod(val) {
                this.dataPassword.OtpType = val;
            },
            changePassword() {
                if (this.step == 2) {
                    if (!this.dataPassword.otp)
                        return this.$error(this.$tBnd('please_enter_otp_code'));
                }
                if (!this.dataPassword.currentpassword) {
                    return this.$error(this.$tBnd('error_current_password_empty'))
                }
                if (!this.dataPassword.newpassword) {
                    return this.$error(this.$tBnd('error_new_password_empty'))
                } else {
                    if (!this.checkPassword(this.dataPassword.newpassword)) {
                        return this.$error(this.$tBnd('error_password_invalid'));
                    }
                }
                if (!this.dataPassword.confirmnewpassword) {
                    return this.$error(this.$tBnd('error_confirm_new_password_empty'))
                }
                if (this.dataPassword.newpassword !== this.dataPassword.confirmnewpassword) {
                    return this.$error(this.$tBnd('error_confirm_new_password'))
                }

                //if (!this.requirePasswor && (this.currentUser.idp === 'Google' || this.currentUser.idp === 'Facebook')) {
                //    this.dataPassword.isSetPassword = true;
                //}
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHANGE_PASSWORD, this.dataPassword)
                    .then((data) => {
                        $loading.hide();
                        //Nếu chưa xác thực 2 bước thì cho phép đổi mật khẩu luôn mà k cần nhập OTP
                        if (data.result.twoFactorDisabled) {
                            this.$success(this.$tBnd('change_password_success'));
                            this.step = 1;
                            if (typeof (window) != undefined && this.iframe)
                                window.flutter_inappwebview.callHandler('updateSuccess', 'change-password');
                            if (this.isLogout)
                                this.logoutAll();
                            return
                        }
                        if (this.step === 1 && this.dataPassword.otp === '') {
                            this.step = 2;
                            this.seconds = data.result.times;
                            this.$api.setTimerOTP(this);
                        }
                        else {
                            this.$success(this.$tBnd('change_password_success'));
                            this.step = 1;
                            if (typeof (window) != undefined && this.iframe)
                                window.flutter_inappwebview.callHandler('updateSuccess', 'change-password');
                            if (this.isLogout)
                                this.logoutAll();
                            else
                                this.$router.push('/');
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            setFirstPassword() {
                if (!this.dataPassword.newpassword) {
                    return this.$error(this.$tBnd('error_new_password_empty'))
                } else {
                    if (!this.checkPassword(this.dataPassword.newpassword)) {
                        return this.$error(this.$tBnd('error_password_invalid'));
                    }
                }
                if (!this.dataPassword.confirmnewpassword) {
                    return this.$error(this.$tBnd('error_confirm_new_password_empty'))
                }
                if (this.dataPassword.newpassword !== this.dataPassword.confirmnewpassword) {
                    return this.$error(this.$tBnd('error_confirm_new_password'))
                }
                const $loading = this.$loading.show();
                this.dataPassword.id = this.currentUser.sub
                var dataSubmit = { ...this.dataPassword }
                dataSubmit.id = this.currentUser.sub
                dataSubmit.password = this.dataPassword.newpassword
                this.$axios
                    .post(this.$api.ACCOUNT_SET_FIRST_PASSWORD, dataSubmit)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd('set_password_success'));
                        this.$router.push('/');
                    })
                    .catch(ex => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    })
            },
            resendOTP() {
                this.dataPassword.otp = "";
                this.$api.resentOTP(this);
            },
            cancel() {
                this.step = 1;
                this.dataPassword = {
                    currentpassword: '',
                    newpassword: '',
                    confirmnewpassword: '',
                    otp: ''
                }
            },
            checkRequirePassword() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.USER_CHECK_REQUIRE_PASSWORD}?id=${this.currentUser.sub}`)
                    .then((data) => {
                        $loading.hide();
                        this.requirePasswor = data.result;
                    })
                    .catch(ex => {
                        $loading.hide();
                        console.log(ex)
                    })
            }
        },
    };
</script>
