import Vue from 'vue';
import App from './App.vue';
import router from './router';
/*import { i18n } from './locales/config'*/
// plugins
import './plugins/cookie'
import './plugins/axios'
import './plugins/vue-loading-overlay'
import './plugins/api'
import './plugins/vue-toast-notification'
import './plugins/date-dropdown'
import './plugins/filter'
import './plugins/alert'

import VueGtag from 'vue-gtag'; //https://github.com/MatteoGabriele/vue-gtag
import VueMatomo from 'vue-matomo'; //https://github.com/AmazingDreams/vue-matomo

import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import Modal from './components/common/modal'
import TSelect from './components/common/tselect'
import InputPassword from './components/common/input-password'
import VuePagination from './components/common/vue-pagination'
import './common/common'
import Vuelidate from 'vuelidate'
import vClickOutside from 'v-click-outside'
import Fragment from 'vue-fragment';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

// mixin
//import './plugins/i18n'
import './plugins/common'
import BndUIWeb from '@hesinhthainghenghiep/bnd-ui-web'
import BndUIWebSSR from '@hesinhthainghenghiep/bnd-ui-web-ssr'
import BnDMessenger from '@hesinhthainghenghiep/bnd-messenger'
import './assets/css/bootstrap.min.css';
import '@hesinhthainghenghiep/bnd-ui-web-ssr/dist/bnd-ui-web-ssr.css';
import '@hesinhthainghenghiep/bnd-ui-web/dist/bnd-ui-web.css';
import '@hesinhthainghenghiep/bnd-messenger/dist/bnd-messenger.css';
import './assets/css/style.css';
import './assets/css/fix-style.css';
import './assets/css/custom.css';
import 'v-tooltip/dist/v-tooltip.css'
import OtpInput from "@bachdgvn/vue-otp-input";
import config from "../nuxt.config.js"
import VueMeta from 'vue-meta'
import VTooltip from 'v-tooltip'

// Vuex
import store from './store';
import AuthService from './plugins/auth.js';
import VueMoment from 'vue-moment';
import * as Constants from './constants/default';
import * as Fingerprint2 from 'fingerprintjs2'

Vue.prototype.$fingerprint = Fingerprint2;
Vue.prototype.$constants = Constants;
Vue.prototype.$auth = new AuthService();
Vue.prototype.$config = config;
Vue.config.productionTip = false;
Vue.component('modal', Modal);
Vue.component('tselect', TSelect);
Vue.use(VueLodash, lodash);
Vue.use(BndUIWeb);
Vue.use(BndUIWebSSR);
Vue.use(BnDMessenger);
Vue.use(Vuelidate);
Vue.use(vClickOutside);
Vue.use(VueMoment);
Vue.use(Fragment.Plugin);
Vue.use(vueCustomScrollbar)
Vue.component("v-otp-input", OtpInput);
Vue.component("input-password", InputPassword);
Vue.component("vue-pagination", VuePagination);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(VTooltip);




if (config.analytics && config.analytics.gtag) {
    Vue.use(VueGtag, {
        config: {
            id: config.analytics.gtag
        }
    }, router)
}

if (config.analytics && config.analytics.matomoSiteId && config.analytics.matomoHost) {
    Vue.use(VueMatomo, {
        host: config.analytics.matomoHost,
        siteId: config.analytics.matomoSiteId,
        router
    });
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
