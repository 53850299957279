<template>
    <div v-if="listTwoFactorTypes && listTwoFactorTypes.length">
        <bnd-web-select-ul :options="listTwoFactorTypes"
                           code="type"
                           label="label"
                           v-model="codeReceiveMethod" />
        <div class="custom-input mt-4" v-if="codeReceiveMethod!='prompt'">
            <input type="text" class="ipt" v-model.trim="twoFaCode">
            <label for="ipt-emailorphone" class="lbl">{{$tBnd('input_otp_code')}}</label>
            <template v-if="codeReceiveMethod != 'authenticator' && codeReceiveMethod != 'recoveryCode' && codeReceiveMethod!='app'">
                <otpCountDown @sendCode="sendCode()" :seconds="seconds" :isSendOtp="isSendOtp" :userName="userName"/>
            </template>
        </div>
    </div>
</template>

<script>
    import otpCountDown from "@/components/common/otp-countdown";
    export default {
        name: 'OtpMethod',
        components: { otpCountDown },
        props: {
            types: {
                type: Array,
                default: []
            },
            userName: {
                type: String,
                default: ""
            }
        },
        computed: {
            listTwoFactorTypes() {
                var list = [...this.twoFactorTypes]
                list.forEach(o => {
                    if (o.type == 'email') {
                        o.label = `${this.$tBnd(o.staticKey)}:${o.value}`
                    } else {
                        o.label = this.$tBnd(o.staticKey)
                    }
                })
                return list;
            }
        },
        data() {
            return {
                twoFaCode: '',
                isSendOtp: false,
                seconds: 0,
                codeReceiveMethod: '',
                twoFactorTypes: []
            };
        },
        watch: {
            twoFaCode(val) {
                this.$emit('input', val);
            },
            codeReceiveMethod() {
                this.twoFaCode = "";
                this.$emit('changeMethod', this.codeReceiveMethod);
            }
        },
        created() {
            if (!this.types || !this.types.length)
                this.getList();
            else {
                this.twoFactorTypes = this.types;
                this.codeReceiveMethod = this.twoFactorTypes[0].type;
            }
        },
        methods: {
            sendCode() {
                if (this.isLoginOTP) return;
                if (!this.codeReceiveMethod) {
                    return this.$error(this.$tBnd("please_select_method_receive_2fa_code"));
                }
                this.seconds = 0;
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_LOGIN_2FA_SEND_CODE, {
                        codeReceiveMethod: this.codeReceiveMethod,
                        userName: this.userName
                    })
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd("code_has_been_sent"));
                        this.seconds = data.result.data.expirySecond;
                        this.twoFaCode = "";
                        this.isSendOtp = true;
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                        this.isSendOtp = true;
                    });
            },
            getList() {
                this.$axios
                    .get(this.$api.ACCOUNT_GETOTP_METHOD)
                    .then((data) => {
                        this.twoFactorTypes = data.result;
                        if (this.twoFactorTypes && this.twoFactorTypes.length > 0) {
                            this.codeReceiveMethod = this.twoFactorTypes[0].type;
                        }
                    })
                    .catch((ex) => {
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            }
        }
    };
</script>
