<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xl-7 box-l" v-if="!inIframe">
                <div class="picture-hst-banner">
                    <div class="block-circle-animation" v-if="staticLogo" v-html="staticLogo">

                    </div>
                    <div class="block-circle-animation" v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 700 700" style="enable-background:new 0 0 700 700;" xml:space="preserve">
                        <g id="circleBnD-bg-white">
                        <circle class="st0" cx="350" cy="349.9" r="317.4"></circle>
                                    </g>
                        <g id="circleBnD-outside">
                                        
                        <g id="cover-shadow-outside">
                        <path id="cover-guide" class="st0" d="M68.1,204.2c0,0-0.3-1.8,10.3-18.3    c6.8-10.7,50.4-5.7,50.4-5.7l9.5,12l5.3,10.2l-4.6,25.2c0,0-3.6,6.4-6.1,11.2c-7.3,13.8-60.2-17-60.2-17L68.1,204.2z"></path>
                        <path id="cover-exam" class="st0" d="M301.3,589.1c0,0,23.8,4.8,44.1,4.8    c11.7,0.1,21.9-0.3,39.5-2.3c50-5.8,9,72.7,9,72.7s-47.3,8-103.4-2.5L301.3,589.1z"></path>
                        <path id="cover-learn" class="st0" d="M448.6,123.2l58.9-24.6l98.1,63.1    l15,22.3l5.8,9.8l10.1,19.5l5.7,12.7l-51.5,17l-20.4,1.9C570.2,244.8,537.7,166.8,448.6,123.2z"></path>
                                        </g>
                        <g id="shape-flat-outside">
                        <a href="">
                        <path id="shape-guide-flat" class="st1" d="M133.7,236.9L110.8,224l-0.3-0.5    c-1.2-1.6-1.7-3.7-1.3-5.7c0.4-2,1.6-3.7,3.3-4.7c4.2-2.5,7.1-4.6,7.2-8.7c0-2.5-1-5.1-2.9-7c-1.9-1.9-4.5-3-7-2.9    c-2.4,0-4.4,0.8-5.8,2.3c-1.9,2.1-2.9,5.5-2.7,9.7c0.1,2.1-0.7,4.1-2.2,5.5c-1.5,1.4-3.6,2.2-5.6,2l-0.6,0l-23.1-13    C-11,352.7,43.8,542,194.5,626.6c48.8,27.4,101.6,40.5,153.9,40.7l-0.2-21.4c-3.6,1.4-7.3,1.7-10.6,0.9c-4-0.9-7.4-3.5-9.7-7.4    c-2.4-4-3.1-8.9-1.8-13.5c1.2-4.6,4.2-8.5,8.2-10.8c5-2.8,9.7-2.1,13.5-0.6l-0.2-20.6c-39.8-0.4-80-10.5-117.1-31.3    C115.1,497.9,72.8,353.3,133.7,236.9z"></path>
                                            </a>
                        <a href="">
                        <path id="shape-exam-flat" class="st2" d="M607.7,204.5c3,2.5,5,5.5,6,8.7    c1.2,4,0.6,8.1-1.6,12.1c-2.3,4-6.2,7-10.8,8.2c-4.6,1.2-9.5,0.5-13.4-1.9c-4.9-3-6.6-7.4-7.2-11.4l-17.8,10.3    c40.1,71.7,43.1,162.1-0.1,239c-43.2,76.9-121.9,121.3-204.1,124.3l0.1,26.3l-0.2,0.5c-0.8,1.8-2.4,3.3-4.3,3.9    c-1.9,0.6-4,0.4-5.8-0.6c-4.2-2.4-7.5-3.9-11.1-2c-2.2,1.2-3.9,3.4-4.6,6c-0.7,2.6-0.4,5.4,0.9,7.5c1.2,2.1,2.9,3.4,4.9,3.9    c2.8,0.7,6.2-0.2,9.7-2.4c1.8-1.1,3.9-1.4,5.9-0.8c2,0.6,3.6,2,4.5,3.9l0.2,0.5l0.1,26.5c107.7-3.1,211.1-61,267.8-161.8    c56.4-100.4,52.4-218.3-0.3-311.7L607.7,204.5z"></path>
                                            </a>
                                        
                        <a href="">
                        <path id="shape-learn-flat" class="st3" d="M556.1,219.3l23-12.6l0.6,0c2-0.2,4,0.5,5.5,1.9c1.5,1.4,2.3,3.3,2.3,5.4c-0.1,4.9,0.1,8.5,3.6,10.8     c2.1,1.4,4.9,1.8,7.5,1.2c2.7-0.6,4.9-2.2,6.2-4.4c1.3-2.1,1.6-4.2,1.1-6.2c-0.7-2.8-3.2-5.4-6.8-7.5c-1.9-1-3.1-2.8-3.5-4.8     c-0.4-2.1,0.1-4.2,1.3-5.9l0.3-0.5l23.3-12.8c-27.4-44.7-66.1-83.2-115-110.7C353.6-12.1,161.6,41,74.9,191.7l18.8,10.6     c0.6-3.8,2.2-7.1,4.5-9.6c2.8-3,6.7-4.7,11.2-4.7c4.6-0.1,9.2,1.8,12.6,5.1c3.4,3.4,5.3,7.9,5.2,12.5c-0.1,5.7-3,9.4-6.2,12     l17.9,10.1C205.7,112.2,353,71.7,469.6,137.2C506.1,157.7,535.2,186.2,556.1,219.3z"></path>
                                            </a>
                                        
                                        </g>
                        <g id="text-outside-circleBnD">
                        <g>
                        <path class="st0" d="M543.9,565.8l-4.2,3.7l-19.4-11.2l4.2-3.7l13,8.3l0.1,0l-6.6-14l4.2-3.7L543.9,565.8z"></path>
                        <path class="st0" d="M538.2,547.2l-2.5-2.6l3.4-3.3l2.5,2.6L538.2,547.2z M550.4,559.8l-11.1-11.4l3.4-3.3l11.1,11.4L550.4,559.8     z"></path>
                        <path class="st0" d="M551.7,541.1l8.9,8.5l-3.3,3.4l-8.9-8.5l-1.6,1.7l-2.6-2.5l1.6-1.7l-3.8-3.7l3.3-3.4l3.8,3.7l1.6-1.7     l2.6,2.5L551.7,541.1z"></path>
                        <path class="st0" d="M571.9,537l-1.3-1.1l0,0c0.3,0.8,0.3,1.7,0,2.7c-0.3,0.8-0.7,1.6-1.3,2.3c-1.6,1.8-3.5,2.7-5.7,2.8     c-2.2,0-4.1-0.7-6-2.3c-1.8-1.6-2.8-3.4-3.1-5.6c-0.3-2.2,0.4-4.3,1.9-6c0.6-0.7,1.3-1.3,2.1-1.6c0.9-0.4,1.8-0.6,2.6-0.4l0,0     l-1.3-1.1l3.1-3.6l12,10.4L571.9,537z M562.9,530.8c-1.1,0.1-2,0.5-2.7,1.4c-0.8,0.9-1.1,1.8-0.9,2.9c0.1,1.1,0.6,2,1.5,2.7     c0.8,0.7,1.8,1.1,2.9,1c1.1-0.1,2-0.5,2.7-1.4c0.8-0.9,1.1-1.8,1-2.9c-0.1-1.1-0.6-2-1.5-2.7C564.9,531.1,564,530.7,562.9,530.8z     "></path>
                        <path class="st0" d="M583.7,522.1l-6.9-5.2c-0.8-0.6-1.5-1-2.1-1c-0.8-0.1-1.4,0.3-2,1c-0.6,0.8-0.8,1.6-0.6,2.3     c0.2,0.6,0.7,1.2,1.6,1.9l6.9,5.2l-2.9,3.8l-12.7-9.6l2.7-3.5l1.4,1l0-0.1c-0.6-1.6-0.4-3.2,0.8-4.8c1-1.3,2.2-2.1,3.6-2.4     c0.9-0.2,1.8-0.1,2.8,0.2c0.8,0.3,1.6,0.7,2.4,1.4l8,6L583.7,522.1z"></path>
                        <path class="st0" d="M589,514.7L571.3,503l6.5-9.8l4,2.6l-3.6,5.5l2.8,1.8l3.5-5.3l4,2.6l-3.5,5.3l3,2l3.6-5.5l4,2.6L589,514.7z"></path>
                        <path class="st0" d="M602.8,492.5l-5.9,0.3l2.6,5.3l-2.9,5l-4.3-9.5l-9.1,0.9l2.9-4.9l4.9-0.1l-2.3-4.3l2.8-4.9l3.7,8.4l10.5-1.1     L602.8,492.5z"></path>
                        <path class="st0" d="M612.2,474.4l-1.5-0.8l0,0c0.5,0.7,0.7,1.6,0.7,2.6c0,0.9-0.3,1.7-0.7,2.6c-1.1,2.2-2.7,3.5-4.8,4.1     c-2.1,0.6-4.2,0.3-6.4-0.7c-2.1-1-3.6-2.6-4.4-4.6c-0.8-2.1-0.7-4.2,0.3-6.3c0.4-0.9,1-1.6,1.6-2.1c0.7-0.6,1.6-1,2.4-1.1l0-0.1     l-1.5-0.7l2.1-4.3l14.3,7L612.2,474.4z M602,470.7c-1,0.3-1.8,1-2.3,2.1c-0.5,1-0.6,2.1-0.2,3.1c0.4,1,1.1,1.7,2.1,2.3     c1,0.5,2,0.6,3,0.2c1-0.4,1.8-1,2.3-2c0.5-1,0.6-2.1,0.2-3.1c-0.4-1-1.1-1.8-2.1-2.3C604,470.4,603,470.3,602,470.7z"></path>
                        <path class="st0" d="M623.4,447.7l-7.9-3.1c-1-0.4-1.8-0.6-2.4-0.5c-0.8,0.1-1.4,0.6-1.8,1.5c-0.4,0.9-0.3,1.7,0.1,2.3     c0.4,0.5,1,1,2,1.3l8.1,3.1l-1.7,4.4l-8.1-3.1c-0.9-0.4-1.7-0.5-2.3-0.4c-0.8,0.1-1.4,0.6-1.7,1.4c-0.4,0.9-0.3,1.7,0.2,2.4     c0.4,0.5,1.1,1,2.1,1.4l7.9,3.1l-1.7,4.4l-14.8-5.7l1.6-4.1l1.6,0.6l0-0.1c-0.6-0.7-0.9-1.5-0.9-2.3c-0.1-0.8,0.1-1.6,0.4-2.6     c0.7-1.9,2.2-3,4.3-3.3c-1.3-1.8-1.6-3.7-0.8-5.7c0.6-1.6,1.6-2.7,3-3.3c1.1-0.5,2.1-0.6,3.2-0.4c0.4,0.1,1,0.2,1.5,0.4     c0.2,0.1,0.5,0.2,0.8,0.3l8.9,3.5L623.4,447.7z"></path>
                        <path class="st0" d="M622.7,430.4l-4.5-1.3l2.2-7.8l4.5,1.3L622.7,430.4z"></path>
                        <path class="st0" d="M618.8,401.8l16.1,3.3l-1.1,5.3l-16.1-3.3l-0.8,4.1l-4.7-0.9l2.7-13.5l4.7,0.9L618.8,401.8z"></path>
                        <path class="st0" d="M637.7,388.5l-8.6-1.2c-1-0.1-1.8-0.1-2.4,0.2c-0.7,0.3-1.2,1-1.3,1.9c-0.1,1,0.1,1.7,0.6,2.2     c0.5,0.4,1.2,0.7,2.2,0.9l8.7,1.2l-0.7,4.7l-21-3l0.7-4.7l6.7,1l0-0.1c-0.6-0.5-1.1-1.1-1.3-2c-0.2-0.7-0.2-1.5-0.1-2.4     c0.3-1.9,1-3.2,2.3-4c0.9-0.6,1.8-0.9,2.9-0.9c0.4,0,0.9,0,1.5,0.1c0.2,0,0.5,0,0.8,0.1l9.6,1.4L637.7,388.5z"></path>
                        <path class="st0" d="M621.2,378.2l-3.6-0.3l0.4-4.7l3.6,0.3L621.2,378.2z M638.7,379.8l-15.8-1.5l0.4-4.7l15.8,1.5L638.7,379.8z"></path>
                                            </g>
                        <g>
                        <path class="st0" d="M63.8,299.6l1-5.5L87,291l-1,5.5L70.6,298l0,0.1l13.9,6.9l-1,5.5L63.8,299.6z"></path>
                        <path class="st0" d="M62.5,308.4l15.8,2.1l-0.6,4.7l-15.8-2.1L62.5,308.4z M79.9,310.8l3.6,0.5l-0.6,4.7l-3.6-0.5L79.9,310.8z"></path>
                        <path class="st0" d="M73,323.9l-12.3-1.2l0.5-4.7l12.3,1.2l0.2-2.3l3.6,0.4l-0.2,2.3l5.3,0.5l-0.5,4.7l-5.3-0.5l-0.2,2.3     l-3.6-0.4L73,323.9z"></path>
                        <path class="st0" d="M59.7,339.6l1.7,0.1l0-0.1c-0.7-0.4-1.3-1.1-1.7-2.1c-0.3-0.8-0.4-1.7-0.4-2.7c0.1-2.4,1-4.3,2.8-5.7     c1.7-1.4,3.7-2,6.1-1.9c2.4,0.1,4.3,0.9,5.9,2.5c1.6,1.6,2.3,3.6,2.2,5.9c0,1-0.3,1.8-0.7,2.6c-0.4,0.9-1,1.5-1.8,1.9l0,0.1     l1.7,0.1l-0.2,4.8l-15.9-0.7L59.7,339.6z M70.6,339c0.8-0.7,1.2-1.7,1.3-2.8c0.1-1.1-0.3-2.1-1.1-2.9c-0.7-0.8-1.7-1.2-2.8-1.2     c-1.1-0.1-2.1,0.3-2.9,1c-0.8,0.7-1.3,1.7-1.3,2.8c-0.1,1.1,0.3,2.1,1,2.9c0.7,0.8,1.7,1.2,2.8,1.3C68.8,340,69.8,339.7,70.6,339     z"></path>
                        <path class="st0" d="M59.6,358.7l8.7-0.2c1,0,1.8-0.2,2.3-0.5c0.6-0.4,1-1.1,0.9-2.1c0-1-0.4-1.8-1-2.2c-0.5-0.3-1.3-0.5-2.4-0.5     l-8.7,0.2l-0.1-4.8l15.9-0.3l0.1,4.4l-1.7,0l0,0.1c1.5,0.9,2.3,2.3,2.3,4.3c0,1.6-0.4,3-1.3,4.1c-0.6,0.7-1.3,1.2-2.3,1.5     c-0.8,0.3-1.7,0.4-2.8,0.4l-10,0.2L59.6,358.7z"></path>
                        <path class="st0" d="M72.9,388.5c-3.2,0.3-6.1-0.5-8.4-2.4c-2.4-1.9-3.7-4.5-4-7.8c-0.3-3.1,0.5-5.9,2.4-8.3     c1.9-2.4,4.5-3.7,7.6-4.1c3.1-0.3,5.8,0.5,8.2,2.6c2.3,2,3.5,4.5,3.8,7.7c0.2,2.3-0.2,4.6-1.4,6.7c-1.2,2.2-2.9,3.7-5,4.6     l-0.6-6.1c1.7-1.2,2.5-2.8,2.3-4.9c-0.2-1.7-0.9-3-2.2-4c-1.3-0.9-2.8-1.3-4.5-1.2c-1.8,0.2-3.2,0.8-4.3,2     c-1.1,1.2-1.6,2.7-1.5,4.4c0.1,1.2,0.5,2.2,1.1,3.1c0.7,0.9,1.6,1.4,2.8,1.6l-0.6-6.3l4.3-0.4l1.2,12.5c-0.2,0-0.4,0.1-0.6,0.1     L72.9,388.5z"></path>
                        <path class="st0" d="M64.5,403.2l1.7-0.3l0-0.1c-0.9-0.2-1.5-0.7-2-1.4c-0.4-0.6-0.7-1.4-0.9-2.3c-0.4-2,0-3.6,1.2-5     c0.6-0.7,1.3-1.2,2.1-1.6c0.6-0.3,1.4-0.5,2.5-0.7l9.4-1.7l0.8,4.7l-8.7,1.5c-1,0.2-1.7,0.5-2.2,0.9c-0.5,0.5-0.7,1.2-0.5,2.2     c0.3,1.8,1.5,2.5,3.7,2.1l8.6-1.5l0.8,4.7l-15.7,2.8L64.5,403.2z"></path>
                        <path class="st0" d="M66.2,411.6l15.5-3.5l1,4.6l-15.5,3.5L66.2,411.6z M83.3,407.7l3.5-0.8l1,4.6l-3.5,0.8L83.3,407.7z"></path>
                        <path class="st0" d="M71.3,431.8l1.7-0.5l0-0.1c-0.9-0.2-1.6-0.7-2.2-1.5c-0.5-0.7-0.9-1.5-1.2-2.5c-0.6-2.3-0.4-4.4,0.8-6.3     c1.2-1.8,2.9-3.1,5.2-3.7c2.3-0.6,4.4-0.5,6.3,0.5c2,1,3.3,2.6,4,4.9c0.5,1.8,0.3,3.5-0.6,5l6.4-1.8l1.3,4.6l-20.5,5.7     L71.3,431.8z M81.5,427.8c0.5-0.9,0.6-2,0.3-3.1c-0.3-1.1-0.9-1.9-1.9-2.5c-0.9-0.5-2-0.7-3.1-0.3c-1.1,0.3-2,0.9-2.5,1.9     c-0.6,0.9-0.7,2-0.4,3.1c0.3,1.1,1,2,1.9,2.5c1,0.5,2,0.6,3.1,0.3C80.1,429.3,81,428.7,81.5,427.8z"></path>
                        <path class="st0" d="M85.3,453l-4-11.2c-1.8,1-2.3,2.5-1.6,4.5c0.4,1.1,1.2,1.8,2.4,2.2l1.6,4.6c-1.8-0.1-3.4-0.6-4.7-1.6     c-1.4-1-2.5-2.3-3-3.9c-0.8-2.2-0.7-4.3,0.3-6.4c1-2.1,2.6-3.6,4.8-4.3c2.2-0.8,4.4-0.7,6.5,0.3c2.1,1,3.6,2.5,4.4,4.7     c0.8,2.3,0.7,4.5-0.4,6.5c-1,2-2.7,3.5-5,4.3C86.1,452.8,85.6,452.9,85.3,453z M86.7,447.5c1.6-1,2-2.4,1.4-4.3     c-0.7-1.9-1.9-2.7-3.8-2.5L86.7,447.5z"></path>
                        <path class="st0" d="M88.8,462.8l4.3-1.9l3.3,7.4l-4.3,1.9L88.8,462.8z"></path>
                        <path class="st0" d="M97.7,494.1l7.4-4.1l-3.4-6.1l-7.4,4.1l-2.6-4.7l18.6-10.3l2.6,4.7l-7,3.9l3.4,6.1l7-3.9l2.6,4.7l-18.6,10.3     L97.7,494.1z"></path>
                        <path class="st0" d="M108.4,511.2l1.4-0.9l0,0c-0.9,0.1-1.7,0-2.4-0.5c-0.6-0.4-1.2-1-1.7-1.8c-1.1-1.7-1.4-3.4-0.9-5     c0.3-0.9,0.7-1.7,1.3-2.3c0.4-0.5,1.1-1,2-1.6l8-5.2l2.6,4l-7.4,4.8c-0.9,0.6-1.4,1.1-1.6,1.6c-0.3,0.7-0.2,1.4,0.4,2.2     c1,1.5,2.4,1.7,4.2,0.5l7.3-4.8l2.6,4l-13.3,8.7L108.4,511.2z M127.2,504.2l1.8,2.7l-2.8,1.8c-1.2,0.6-2.3,0.8-3.3,0.5     c-1-0.3-1.8-0.8-2.4-1.5l-0.9-1.1l1.7-1l0.3,0.5c0.1,0.2,0.4,0.4,0.9,0.8c0.9,0.6,1.8,0.6,2.5,0.1l-0.6-0.9L127.2,504.2z"></path>
                        <path class="st0" d="M132.6,522.5c-0.5,1.7-1.5,3.1-3.1,4.3c-1.9,1.5-4,2-6.3,1.7c-2.3-0.3-4.1-1.5-5.6-3.4     c-1.5-2-2.1-4.1-1.8-6.3c0.3-2.3,1.4-4.1,3.4-5.6c1.9-1.5,4.1-2,6.3-1.7c2.3,0.3,4.1,1.5,5.6,3.4c1.3,1.7,1.9,3.6,1.8,5.5     c0.8,0.3,1.5,0.2,2.1-0.3l-0.7-0.9l2.6-1.9l1.9,2.6l-2.6,2c-1.2,0.7-2.2,1-3.2,0.8C132.9,522.6,132.8,522.6,132.6,522.5z      M128.3,520.5c0.2-1.1,0-2-0.7-2.9c-0.7-0.9-1.5-1.4-2.6-1.5c-1.1-0.1-2,0.2-2.9,0.8c-0.9,0.7-1.4,1.5-1.6,2.6     c-0.2,1.1,0,2,0.7,2.9c0.7,0.9,1.5,1.4,2.6,1.5c1.1,0.1,2-0.2,2.9-0.8C127.5,522.4,128.1,521.5,128.3,520.5z M130.9,512.3     l1.4-2.8l7.1,3.3l-1.8,3.3L130.9,512.3z"></path>
                        <path class="st0" d="M131.9,541.9l6.6-5.7c0.8-0.7,1.3-1.3,1.5-1.9c0.2-0.7,0-1.4-0.6-2.2c-0.7-0.8-1.4-1.1-2.2-1     c-0.6,0.1-1.3,0.5-2.1,1.2l-6.5,5.7l-3.1-3.6l12-10.4l2.9,3.4l-1.3,1.1l0,0.1c1.7-0.3,3.2,0.3,4.5,1.8c1.1,1.2,1.6,2.6,1.6,4     c0,0.9-0.3,1.8-0.8,2.7c-0.4,0.7-1.1,1.4-1.9,2.1l-7.5,6.5L131.9,541.9z"></path>
                        <path class="st0" d="M150.5,559.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.3-0.7,0.6-1,0.9c-0.8,0.6-1.6,1-2.4,1.2     c-1.4,0.4-2.9,0.2-4.5-0.5c-1.4-0.6-2.7-1.4-3.8-2.6c-1-1-1.8-2.1-2.3-3.3c-0.5-1.3-0.7-2.6-0.4-3.9c0.2-0.9,0.5-1.8,1.1-2.4     l3.9,3.9c-0.4,1.1-0.1,2.1,0.8,3.1c1.8,1.8,3.8,1.7,5.8-0.3l0.3-0.3l0,0c-0.8,0.2-1.6,0.2-2.5-0.1c-0.8-0.3-1.5-0.7-2.2-1.4     c-1.7-1.7-2.4-3.6-2.4-5.8c0.1-2.2,1-4.1,2.6-5.8c1.7-1.7,3.7-2.6,5.8-2.7c2.3-0.1,4.2,0.7,5.9,2.3c0.6,0.6,1.1,1.3,1.4,2.1     c0.4,0.9,0.4,1.7,0.3,2.5l0,0l1.1-1.1l3.4,3.4L150.5,559.5z M153.1,548.6c0-1.1-0.3-2-1.2-2.8c-0.8-0.8-1.7-1.1-2.8-1.1     c-1.1,0-2,0.4-2.7,1.2c-0.8,0.8-1.2,1.7-1.3,2.8c-0.1,1.1,0.3,2,1.1,2.8c0.8,0.8,1.7,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2     C152.6,550.6,153.1,549.7,153.1,548.6z"></path>
                        <path class="st0" d="M167.3,575.8l5.5-6.7c0.7-0.8,1-1.5,1.1-2.1c0.1-0.7-0.2-1.4-1-2.1c-0.8-0.7-1.6-0.9-2.3-0.7     c-0.6,0.2-1.2,0.7-1.9,1.5l-5.5,6.7l-3.7-3l10.1-12.3l3.4,2.8l-1.1,1.3l0.1,0c1.7-0.6,3.2-0.2,4.8,1c1.2,1,2,2.2,2.3,3.7     c0.2,0.9,0.1,1.8-0.3,2.8c-0.3,0.7-0.8,1.5-1.5,2.4l-6.3,7.7L167.3,575.8z"></path>
                        <path class="st0" d="M188.5,590c-0.1,0.2-0.3,0.4-0.4,0.5c-0.3,0.4-0.6,0.7-0.9,1c-0.7,0.7-1.4,1.3-2.2,1.6     c-1.3,0.6-2.8,0.7-4.5,0.3c-1.5-0.3-2.9-1-4.2-1.9c-1.2-0.8-2.1-1.8-2.8-2.8c-0.7-1.2-1.1-2.4-1.1-3.8c0-1,0.2-1.8,0.6-2.6     l4.5,3.2c-0.2,1.1,0.2,2.1,1.3,2.9c2.1,1.5,4,1.1,5.7-1.3l0.3-0.4l0,0c-0.8,0.4-1.6,0.5-2.5,0.3c-0.8-0.1-1.6-0.5-2.4-1     c-1.9-1.4-3-3.2-3.3-5.4c-0.3-2.2,0.3-4.2,1.6-6.1c1.4-2,3.2-3.2,5.3-3.6c2.2-0.5,4.3,0,6.2,1.3c0.7,0.5,1.3,1.1,1.7,1.8     c0.5,0.8,0.7,1.6,0.7,2.4l0,0l0.9-1.2l3.9,2.8L188.5,590z M189.3,578.8c-0.1-1.1-0.7-1.9-1.6-2.6c-0.9-0.6-1.9-0.8-2.9-0.6     c-1,0.2-1.9,0.7-2.5,1.6c-0.7,0.9-0.9,1.9-0.8,2.9c0.1,1.1,0.6,1.9,1.5,2.6c0.9,0.7,1.9,0.9,2.9,0.7c1-0.2,1.9-0.7,2.5-1.7     C189.2,580.8,189.4,579.8,189.3,578.8z"></path>
                        <path class="st0" d="M199.8,598.5l4.6-7.4c0.5-0.9,0.8-1.6,0.8-2.2c0-0.8-0.4-1.5-1.2-2c-0.8-0.5-1.6-0.6-2.3-0.3     c-0.6,0.2-1.1,0.8-1.7,1.6l-4.6,7.5l-4.1-2.5l11.2-18.1l4.1,2.5l-3.6,5.8l0,0c0.7-0.4,1.5-0.5,2.3-0.4c0.7,0.1,1.5,0.4,2.2,0.8     c1.6,1,2.5,2.2,2.8,3.8c0.2,1,0.1,2-0.3,3c-0.1,0.4-0.4,0.9-0.6,1.3c-0.1,0.2-0.2,0.4-0.4,0.7l-5.1,8.2L199.8,598.5z"></path>
                        <path class="st0" d="M207.3,602.9L215,589l4.2,2.3l-7.7,13.9L207.3,602.9z M215.8,587.5l1.8-3.2l4.2,2.3l-1.8,3.2L215.8,587.5z"></path>
                        <path class="st0" d="M216.6,615.7l1.9-3.9l4.1,2l-1.9,3.9L216.6,615.7z M232,608.4l-10.7-5.3c-0.6,1.9,0,3.4,2,4.4     c1,0.5,2.1,0.5,3.3,0l4.4,2.2c-1.4,1.2-2.9,1.9-4.5,2.2c-1.7,0.3-3.4,0.1-4.9-0.7c-2.1-1-3.5-2.6-4.2-4.9     c-0.8-2.2-0.6-4.4,0.4-6.4c1-2.1,2.6-3.5,4.8-4.3c2.2-0.8,4.4-0.7,6.4,0.3c2.2,1.1,3.6,2.7,4.3,5c0.7,2.2,0.5,4.4-0.6,6.6     C232.5,607.7,232.3,608.1,232,608.4z M229.3,603.4c0.5-1.8-0.2-3.2-2-4.1c-1.8-0.9-3.3-0.6-4.5,0.9L229.3,603.4z M231.1,594.7     l-0.4-2.1l-1.9,0.9l-4.2-2.1l6.1-2.3l2.8,1.4l1.7,6.2L231.1,594.7z"></path>
                        <path class="st0" d="M247.2,619.6c-2,1-4.1,1-6.2,0.2c-1.8-0.8-3.1-2-3.7-3.6l-2.6,6.2l-4.4-1.8l8.1-19.7l4.1,1.7l-0.7,1.6l0.1,0     c1.7-1,3.5-1,5.5-0.2c2.2,0.9,3.6,2.4,4.3,4.6c0.6,2,0.5,4.1-0.4,6.4C250.6,617.2,249.2,618.7,247.2,619.6z M247.1,610     c-0.4-1-1.1-1.8-2.2-2.2c-1.1-0.4-2.1-0.4-3.1,0c-1,0.4-1.7,1.2-2.1,2.2c-0.4,1-0.4,2.1,0,3.1c0.4,1,1.1,1.7,2.2,2.2     c1,0.4,2.1,0.4,3.1,0c1-0.4,1.7-1.2,2.1-2.2C247.5,612,247.5,611,247.1,610z"></path>
                                            </g>
                        <g>
                        <path class="st0" d="M238.4,106.3l-5.1,2.4l-15.5-16.3l5.1-2.4l10.3,11.7l0.1,0l-2.5-15.4l5.1-2.4L238.4,106.3z"></path>
                        <path class="st0" d="M239.1,86.7l-1.4-3.4l4.4-1.8l1.4,3.4L239.1,86.7z M245.8,103.1l-6.1-14.8l4.4-1.8l6.1,14.8L245.8,103.1z"></path>
                        <path class="st0" d="M254.4,86.4l4.3,11.6l-4.5,1.7l-4.3-11.6l-2.2,0.8l-1.3-3.4l2.2-0.8l-1.9-5l4.5-1.7l1.9,5l2.2-0.8l1.3,3.4     L254.4,86.4z"></path>
                        <path class="st0" d="M274,93l-0.5-1.7l-0.1,0c-0.2,0.9-0.6,1.6-1.4,2.3c-0.7,0.6-1.5,1-2.4,1.3c-2.3,0.7-4.4,0.5-6.4-0.6     c-1.9-1.1-3.2-2.8-3.9-5.2c-0.7-2.3-0.6-4.4,0.3-6.4c1-2.1,2.6-3.4,4.8-4.1c0.9-0.3,1.8-0.4,2.7-0.3c1,0.1,1.8,0.5,2.4,1l0.1,0     l-0.5-1.6l4.6-1.4l4.7,15.3L274,93z M269.7,82.9c-1-0.5-2-0.6-3.1-0.2c-1.1,0.3-1.9,1-2.4,2c-0.5,1-0.5,2-0.2,3.1     c0.3,1.1,1,1.9,1.9,2.4c1,0.5,2,0.6,3.1,0.3c1.1-0.3,1.9-1,2.4-2c0.5-1,0.6-2,0.2-3.1C271.3,84.2,270.7,83.4,269.7,82.9z"></path>
                        <path class="st0" d="M291.4,88.5l-2-8.5c-0.2-1-0.6-1.7-1-2.2c-0.5-0.6-1.3-0.7-2.2-0.5c-1,0.2-1.7,0.7-1.9,1.4     c-0.2,0.6-0.2,1.4,0,2.5l2,8.5l-4.7,1.1L278,75.2l4.3-1l0.4,1.7l0.1,0c0.6-1.7,1.8-2.7,3.7-3.2c1.6-0.4,3-0.2,4.3,0.4     c0.8,0.4,1.5,1.1,2,2c0.4,0.7,0.7,1.6,1,2.7l2.3,9.8L291.4,88.5z"></path>
                        <path class="st0" d="M299.5,86.8l-3.7-21.1l5.4-0.9l2.8,16.3l6.2-1.1l0.8,4.7L299.5,86.8z"></path>
                        <path class="st0" d="M327.8,76.2l-11.9,1.3c0.5,2,1.9,2.8,4.1,2.6c1.1-0.1,2-0.7,2.7-1.8l4.9-0.5c-0.5,1.7-1.4,3.2-2.6,4.3     c-1.3,1.2-2.8,1.9-4.5,2.1c-2.3,0.3-4.4-0.3-6.2-1.8c-1.8-1.5-2.9-3.3-3.1-5.7c-0.3-2.3,0.3-4.4,1.7-6.3c1.4-1.9,3.3-3,5.6-3.2     c2.4-0.3,4.5,0.4,6.3,1.9c1.8,1.5,2.8,3.5,3.1,5.9C327.8,75.4,327.8,75.8,327.8,76.2z M322.8,73.5c-0.6-1.8-1.9-2.6-3.9-2.3     c-2,0.2-3.1,1.3-3.3,3.1L322.8,73.5z"></path>
                        <path class="st0" d="M342.9,82l-0.1-1.7l-0.1,0c-0.4,0.8-1,1.4-1.9,1.8c-0.8,0.4-1.7,0.6-2.6,0.6c-2.4,0.1-4.4-0.7-6-2.3     c-1.5-1.6-2.3-3.5-2.4-6c-0.1-2.4,0.6-4.4,2-6.1c1.5-1.7,3.4-2.7,5.7-2.8c1,0,1.8,0.1,2.7,0.4c0.9,0.4,1.6,0.9,2.1,1.6l0.1,0     l-0.1-1.7l4.8-0.2l0.7,16L342.9,82z M341.3,71.1c-0.8-0.7-1.8-1.1-2.9-1c-1.2,0-2.1,0.5-2.8,1.3c-0.7,0.8-1,1.8-1,3     c0,1.1,0.5,2.1,1.3,2.8c0.8,0.8,1.8,1.1,2.9,1.1c1.2,0,2.1-0.5,2.8-1.3c0.7-0.8,1.1-1.8,1-3C342.6,72.8,342.1,71.9,341.3,71.1z"></path>
                        <path class="st0" d="M359.6,70.3c-1.2,0-2.1,0.2-2.7,0.5c-0.7,0.4-1.1,1.2-1.1,2.4l-0.1,8.8l-4.8-0.1l0.3-16l4.5,0.1l0,1.7l0.1,0     c0.8-1.5,2.1-2.2,4-2.2L359.6,70.3z"></path>
                        <path class="st0" d="M371.7,82.8l0.6-8.7c0.1-1,0-1.8-0.3-2.4c-0.3-0.7-1-1.1-2-1.1c-1-0.1-1.8,0.2-2.3,0.8     c-0.4,0.5-0.6,1.3-0.7,2.4l-0.6,8.7l-4.8-0.3l1.1-16l4.5,0.3l-0.1,1.7l0.1,0c1-1.4,2.5-2.1,4.5-1.9c1.6,0.1,3,0.7,4,1.7     c0.7,0.6,1.1,1.4,1.3,2.5c0.2,0.8,0.2,1.7,0.2,2.8l-0.7,10L371.7,82.8z"></path>
                        <path class="st0" d="M387.5,78.5l0.7-4.7l8,1.2l-0.7,4.7L387.5,78.5z"></path>
                        <path class="st0" d="M416.9,90.4l2-8.3l-6.8-1.7l-2,8.3l-5.3-1.3l5.1-20.8l5.3,1.3l-1.9,7.8l6.8,1.7l1.9-7.8l5.3,1.3l-5.1,20.8     L416.9,90.4z"></path>
                        <path class="st0" d="M439.3,95.2c-2.1,1-4.3,1.2-6.6,0.4c-2.3-0.8-4-2.2-5.1-4.2c-1.1-2-1.2-4.2-0.5-6.6c0.8-2.3,2.2-4,4.3-5     c2.1-1,4.3-1.2,6.6-0.4c2.3,0.8,4,2.2,5.1,4.2c1.1,2,1.2,4.2,0.5,6.6C442.8,92.5,441.4,94.1,439.3,95.2z M428.5,101l1.4-4.1     l4.4,1.4l-1.4,4.1L428.5,101z M438.8,85.6c-0.5-1-1.2-1.7-2.3-2c-1-0.3-2-0.2-3,0.3c-0.9,0.5-1.6,1.3-1.9,2.4     c-0.3,1-0.3,2.1,0.2,3c0.5,1,1.2,1.7,2.2,2c1,0.3,2,0.2,3-0.3c0.9-0.5,1.6-1.3,1.9-2.4C439.3,87.6,439.3,86.6,438.8,85.6z"></path>
                        <path class="st0" d="M460.6,99c-1.2,1.6-2.7,2.7-4.6,3.3c-1.9,0.5-3.8,0.4-5.7-0.3c-2.2-0.9-3.8-2.4-4.7-4.6     c-0.9-2.2-0.9-4.3,0-6.6c0.9-2.2,2.4-3.8,4.5-4.7c2.1-0.9,4.3-0.9,6.5-0.1c1.9,0.8,3.4,2,4.4,3.8c1,1.7,1.3,3.6,1,5.7l-4.5-1.8     c-0.1-1.7-0.9-2.8-2.5-3.4c-1-0.4-2-0.4-3,0.1c-0.9,0.5-1.6,1.2-2,2.3c-0.4,1.1-0.5,2.1-0.1,3.1c0.4,1,1.1,1.7,2.1,2.1     c1.5,0.6,2.8,0.4,4-0.7L460.6,99z"></path>
                                            </g>
                                        </g>
                                    </g>
                        <g id="circleBnD-mid">
                                        
                        <g id="cover-shadow-mid">
                        <path id="cover-job" class="st0" d="M248.3,472.7c0,0-12.4-9.9-25.9-26.5    s-36.5-3.9-36.5-3.9l-12.3,16.5l-5.2,21.7c0,0,14.6,19.9,27.2,30.7L248.3,472.7z"></path>
                        <path id="cover-train" class="st0" d="M489.5,425.7c0,0-7.5,14-21.5,30.4    s2.9,36.7,2.9,36.7l18.6,9L512,503c0,0,17.1-18,25.4-32.4L489.5,425.7z"></path>
                        <polygon id="cover-admission" class="st0" points="407.8,132 430.8,172.6     386.1,197.2 382,185.2 400.4,134.2   "></polygon>
                                        </g>
                        <g id="shape-flat-mid">
                        <a href="">
                        <path id="shape-job-flat" class="st4" d="M187.5,453.1l0.1-0.3L187.5,453.1    l43.3,2.9c-25.7-28.6-41.1-66.4-40.8-107.9c0.6-64,38.8-118.8,93.5-143.7c0.1,0,0.2-0.1,0.2-0.1l15.5-40l-38.1-20.2    c-0.3,0.1-0.7,0.3-1,0.4c-78.4,34.1-133.5,111.9-134.3,203c-0.5,55.7,19.4,106.8,52.7,146.2l8.6-40.7L187.5,453.1z"></path>
                                        </a>
                        <a href="">
                        <path id="shape-train-flat" class="st5" d="M475.9,446.6    C446.5,484.9,400,509.5,348,509c-43.1-0.4-82-17.9-110.4-45.9l-45.1-3.1l-8.5,39.9c40.4,44.5,98.5,72.6,163.4,73.2    c72.6,0.7,137.5-33.4,178.8-86.8l-45.5-1.1L475.9,446.6z"></path>
                                        </a>
                        <a href="">
                        <path id="shape-admission-flat" class="st6" d="M535,474.2    c23.6-35,37.5-77.1,37.9-122.5c0.9-103.4-68.5-191.1-163.7-217.4l18.3,38.7l-41.4,21.6c70.9,16.7,123.4,80.7,122.7,156.5    c-0.3,30.5-9.1,58.9-24.2,83c-0.6,1-1.3,2-2,3c-0.2,0.2-0.3,0.5-0.5,0.7l4.4,41L531,480c0.1-0.2,0.3-0.4,0.4-0.5    C532.6,477.7,533.8,476,535,474.2z"></path>
                                            </a>
                                        </g>
                        <g id="text-mid-circleBnD">
                        <g>
                        <path class="st0" d="M442.3,192l-3.7-2.3l3.9-16.8l3.6,2.2l-3.2,11.5l0,0l8.8-8l3.7,2.3L442.3,192z"></path>
                        <path class="st0" d="M447.4,195.3l7-10l3,2.1l-7,10L447.4,195.3z M455.2,184.3l1.6-2.3l3,2.1l-1.6,2.3L455.2,184.3z"></path>
                        <path class="st0" d="M461.7,194l-5.7,7.6l-2.9-2.2l5.7-7.6l-1.4-1.1l1.7-2.2l1.4,1.1l2.5-3.3l2.9,2.2l-2.5,3.3l1.4,1.1l-1.7,2.2     L461.7,194z"></path>
                        <path class="st0" d="M465.5,209.4l0.9-1l0,0c-0.6,0.2-1.3,0.3-2,0c-0.6-0.2-1.2-0.5-1.8-1c-1.4-1.2-2.1-2.6-2.2-4.4     c0-1.7,0.5-3.2,1.7-4.6c1.2-1.4,2.6-2.2,4.3-2.4c1.7-0.2,3.3,0.2,4.6,1.4c0.6,0.5,1,1,1.3,1.6c0.3,0.7,0.4,1.3,0.3,2l0,0l0.8-1     l2.8,2.4l-7.9,9.3L465.5,209.4z M470.2,202.4c-0.1-0.8-0.4-1.5-1.1-2.1c-0.7-0.6-1.4-0.8-2.3-0.7c-0.8,0.1-1.5,0.5-2.1,1.2     c-0.6,0.7-0.8,1.4-0.7,2.2c0.1,0.8,0.4,1.5,1.1,2.1c0.7,0.6,1.4,0.8,2.3,0.7c0.8-0.1,1.5-0.5,2.1-1.1     C470.1,204,470.3,203.2,470.2,202.4z"></path>
                        <path class="st0" d="M475.6,218.7l4.6-4.8c0.6-0.6,0.9-1.1,1-1.5c0.1-0.6-0.1-1.1-0.6-1.6c-0.6-0.6-1.1-0.8-1.7-0.6     c-0.5,0.1-1,0.4-1.6,1l-4.6,4.8l-2.6-2.5l8.5-8.8l2.5,2.4l-0.9,0.9l0,0c1.3-0.3,2.5,0,3.6,1.1c0.9,0.9,1.4,1.8,1.5,2.9     c0.1,0.7-0.1,1.4-0.4,2.1c-0.3,0.5-0.7,1.1-1.3,1.7l-5.3,5.5L475.6,218.7z"></path>
                        <path class="st0" d="M487.1,230.8l1.2-2.2l-3.8-4.2l-2.3,0.9l-3-3.3l16.5-6.1l2.2,2.4l-7.8,15.8L487.1,230.8z M493,220.6     L493,220.6l-5.3,2.4l2.3,2.5L493,220.6z"></path>
                        <path class="st0" d="M496.3,242.2l1-0.8l0,0c-0.7,0.1-1.4,0-2-0.4c-0.6-0.3-1.1-0.8-1.6-1.4c-1.1-1.4-1.5-3-1.2-4.7     c0.3-1.6,1.2-3,2.6-4.2c1.4-1.1,3-1.6,4.6-1.5c1.7,0.1,3.1,0.9,4.2,2.3c0.9,1.1,1.2,2.4,1.1,3.7l4-3.2l2.3,2.9l-12.9,10.1     L496.3,242.2z M502.4,236.3c0.1-0.8-0.1-1.6-0.7-2.3c-0.5-0.7-1.2-1.1-2-1.2c-0.8-0.1-1.6,0.1-2.3,0.7c-0.7,0.6-1.1,1.2-1.2,2.1     c-0.1,0.8,0.1,1.6,0.7,2.3c0.6,0.7,1.3,1.1,2.1,1.2c0.8,0.1,1.6-0.1,2.3-0.7C501.9,237.8,502.3,237.2,502.4,236.3z"></path>
                        <path class="st0" d="M508.1,259.4l5.5-3.5c0.7-0.5,1.2-0.9,1.4-1.3c0.3-0.5,0.3-1.1-0.1-1.8c-0.4-0.6-0.9-1-1.5-1     c-0.5,0-1.1,0.2-1.7,0.6l-5.6,3.6l-2-3.1l5.6-3.6c0.6-0.4,1.1-0.8,1.3-1.2c0.3-0.5,0.2-1.1-0.1-1.7c-0.4-0.6-1-1-1.6-1     c-0.5,0-1.1,0.2-1.8,0.7l-5.5,3.5l-2-3.1l10.3-6.7l1.9,2.9l-1.1,0.7l0,0c0.7,0,1.3,0.1,1.9,0.5c0.5,0.3,1,0.8,1.4,1.4     c0.9,1.3,0.9,2.7,0.1,4.2c1.7,0,3,0.7,3.9,2.1c0.7,1.1,1,2.3,0.7,3.4c-0.2,0.9-0.6,1.6-1.2,2.2c-0.3,0.2-0.6,0.5-0.9,0.8     c-0.1,0.1-0.3,0.2-0.5,0.4l-6.2,4L508.1,259.4z"></path>
                        <path class="st0" d="M511.1,264.6l10.7-6l1.8,3.2l-10.7,6L511.1,264.6z M522.9,258l2.4-1.4l1.8,3.2l-2.4,1.4L522.9,258z"></path>
                        <path class="st0" d="M518.1,276.5c-1-0.5-1.9-1.4-2.5-2.6c-0.6-1.2-0.8-2.3-0.6-3.5c0.2-1.2,0.9-2.1,2-2.8l1.6,3.3     c-0.6,0.4-0.8,1-0.4,1.7c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.8,0.1c0.3-0.2,0.5-0.5,0.6-0.9l0-0.6l-0.3-2.2     c-0.1-0.9-0.1-1.7,0.1-2.3c0.2-0.8,0.7-1.4,1.4-1.7c1.1-0.5,2.2-0.5,3.3,0c1,0.5,1.8,1.3,2.4,2.4c0.6,1.2,0.8,2.2,0.6,3.3     c-0.2,1.1-0.9,2-1.9,2.7l-1.6-3.2c0.5-0.3,0.6-0.8,0.3-1.4c-0.3-0.6-0.7-0.8-1.2-0.6c-0.4,0.2-0.5,0.6-0.5,1.3     c0,0.2,0.1,0.6,0.1,1c0,0,0,0.1,0,0.1l0.2,1c0.1,0.5,0.1,0.9,0.1,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.8-0.8,1.5-1.7,1.9     C520.4,277.1,519.2,277.1,518.1,276.5z"></path>
                        <path class="st0" d="M522.4,286.4c-1-0.6-1.8-1.5-2.3-2.7c-0.5-1.2-0.6-2.4-0.4-3.5c0.3-1.2,1-2.1,2.2-2.7l1.4,3.3     c-0.7,0.4-0.8,1-0.5,1.7c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.8,0.1c0.4-0.1,0.6-0.5,0.6-0.9l0-0.6l-0.1-2.3     c-0.1-0.9,0-1.7,0.3-2.3c0.3-0.8,0.8-1.3,1.5-1.6c1.1-0.5,2.2-0.4,3.3,0.2c1,0.6,1.8,1.4,2.3,2.6c0.5,1.2,0.6,2.3,0.4,3.3     c-0.3,1.1-1,1.9-2.1,2.5L529,281c0.6-0.3,0.7-0.7,0.4-1.4c-0.3-0.6-0.7-0.9-1.2-0.6c-0.4,0.2-0.6,0.6-0.5,1.3c0,0.2,0,0.6,0.1,1     c0,0,0,0.1,0,0.1l0.1,1c0,0.5,0,0.9,0,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.3,0.8-0.9,1.4-1.8,1.8C524.7,287.1,523.5,287,522.4,286.4z"></path>
                        <path class="st0" d="M523,289.6l11.5-4.3l1.3,3.4l-11.5,4.3L523,289.6z M535.7,284.9l2.6-1l1.3,3.4l-2.6,1L535.7,284.9z"></path>
                        <path class="st0" d="M529.7,305.1c-1.5-0.8-2.6-2.2-3.1-4c-0.5-1.8-0.4-3.5,0.4-5c0.8-1.6,2.1-2.6,3.9-3.2c1.8-0.5,3.5-0.4,5,0.5     c1.5,0.8,2.6,2.2,3.1,4c0.5,1.8,0.4,3.5-0.4,5c-0.8,1.6-2.1,2.6-3.9,3.2C532.9,306.1,531.2,306,529.7,305.1z M535.4,300.7     c0.4-0.7,0.5-1.5,0.3-2.3c-0.2-0.8-0.7-1.4-1.5-1.7c-0.7-0.4-1.5-0.4-2.3-0.2c-0.8,0.2-1.4,0.7-1.8,1.4c-0.4,0.7-0.5,1.5-0.3,2.3     c0.2,0.8,0.7,1.4,1.5,1.8c0.7,0.4,1.5,0.4,2.3,0.2C534.4,301.8,535,301.4,535.4,300.7z"></path>
                        <path class="st0" d="M530.8,316.6l6.5-1.4c0.8-0.2,1.3-0.4,1.7-0.7c0.4-0.4,0.6-1,0.4-1.7c-0.2-0.8-0.5-1.3-1.1-1.5     c-0.5-0.2-1.1-0.2-1.9,0l-6.5,1.4l-0.8-3.6l12-2.5l0.7,3.3l-1.3,0.3l0,0.1c1.3,0.4,2.1,1.4,2.4,2.9c0.3,1.2,0.1,2.3-0.4,3.3     c-0.3,0.6-0.8,1.1-1.5,1.5c-0.5,0.3-1.2,0.5-2,0.7l-7.5,1.6L530.8,316.6z"></path>
                        <path class="st0" d="M537.2,327.3l3.6-0.4l0.7,6.2l-3.6,0.4L537.2,327.3z"></path>
                        <path class="st0" d="M546.5,347.8l-12.7,0.1l0-4.2l12.7-0.1l0-3.2l3.7,0l0.1,10.6l-3.7,0L546.5,347.8z"></path>
                        <path class="st0" d="M533.5,358.5l1.3,0.1l0,0c-0.6-0.3-1-0.8-1.3-1.4c-0.2-0.5-0.3-1.1-0.3-1.9c0.1-1.6,0.7-2.7,1.7-3.5     c0.6-0.4,1.2-0.7,1.8-0.8c0.5-0.1,1.1-0.1,2-0.1l7.3,0.4l-0.2,3.7l-6.8-0.4c-0.8,0-1.4,0-1.8,0.3c-0.5,0.3-0.7,0.8-0.8,1.6     c-0.1,1.4,0.7,2.1,2.4,2.2l6.7,0.4l-0.2,3.7l-12.2-0.7L533.5,358.5z"></path>
                        <path class="st0" d="M528.3,368.9l0.5-3.8l4.4,2.6l12.1-2.9l-0.5,3.9l-7.4,1.5l0,0l6.7,3.3l-0.5,3.8L528.3,368.9z"></path>
                        <path class="st0" d="M533.6,389.4l1.9-9c-1.6-0.1-2.5,0.7-2.9,2.3c-0.2,0.9,0,1.7,0.6,2.4l-0.8,3.7c-1.1-0.8-2-1.8-2.5-2.9     c-0.5-1.2-0.7-2.5-0.4-3.8c0.4-1.7,1.3-3.1,2.8-4.1c1.5-1,3.1-1.3,4.9-0.9c1.8,0.4,3.1,1.3,4.1,2.8c1,1.5,1.3,3.1,1,4.9     c-0.4,1.8-1.4,3.2-2.9,4.1c-1.5,0.9-3.2,1.2-5,0.8C534.2,389.6,533.9,389.5,533.6,389.4z M536.8,386.5c1.4,0,2.3-0.8,2.6-2.3     c0.3-1.5-0.2-2.6-1.5-3.2L536.8,386.5z M543.7,386.2l1.5-0.7l-1-1.3l0.8-3.5l2.8,4.1l-0.5,2.3l-4.3,2.5L543.7,386.2z      M547.8,392.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.4l-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.4-0.6-0.5     l0.4-1.8c0.3,0.1,0.6,0.2,0.9,0.5c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.4l0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4     c0.2,0.2,0.4,0.4,0.6,0.4c0.4,0.1,0.7-0.1,0.8-0.5c0.1-0.3,0-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.7-0.3l0.4-1.9c0,0,0.1,0,0.1,0     l0.1,0c0.8,0.2,1.3,0.6,1.7,1.2c0.4,0.6,0.5,1.3,0.3,2.1c-0.2,1.1-0.8,1.7-1.8,2c-0.4,0.1-0.7,0.1-1,0     C548.4,392.7,548.1,392.5,547.8,392.2z"></path>
                        <path class="st0" d="M526.1,397.5l6.4,1.9c0.8,0.2,1.4,0.3,1.8,0.2c0.6-0.1,1-0.6,1.2-1.3c0.2-0.8,0.2-1.4-0.2-1.8     c-0.3-0.4-0.9-0.7-1.6-0.9l-6.4-1.9l1.1-3.5l11.7,3.5l-1,3.3l-1.2-0.4l0,0.1c0.9,1,1.1,2.2,0.7,3.7c-0.4,1.2-1,2.1-1.9,2.7     c-0.6,0.4-1.3,0.6-2.1,0.6c-0.6,0-1.3-0.1-2.1-0.4L525,401L526.1,397.5z"></path>
                        <path class="st0" d="M519.6,416.8c-0.3-1.1-0.2-2.3,0.3-3.6c0.5-1.2,1.2-2.1,2.2-2.7c1-0.6,2.2-0.7,3.4-0.4l-1.4,3.4     c-0.7-0.2-1.3,0.1-1.6,0.9c-0.1,0.3-0.2,0.6-0.1,0.9c0.1,0.3,0.2,0.6,0.5,0.7c0.4,0.1,0.7,0.1,1.1-0.2l0.4-0.4l1.5-1.7     c0.6-0.7,1.2-1.1,1.8-1.4c0.8-0.3,1.5-0.4,2.2-0.1c1.1,0.5,1.9,1.3,2.2,2.5c0.3,1.1,0.2,2.3-0.3,3.4c-0.5,1.2-1.2,2-2.1,2.6     c-1,0.6-2.1,0.7-3.3,0.3l1.4-3.3c0.6,0.2,1,0,1.3-0.7c0.3-0.6,0.1-1.1-0.4-1.3c-0.4-0.2-0.8,0-1.3,0.5c-0.2,0.2-0.4,0.4-0.7,0.8     c0,0,0,0.1-0.1,0.1l-0.7,0.8c-0.3,0.4-0.6,0.6-0.8,0.9c-0.3,0.3-0.7,0.5-1,0.7c-0.8,0.4-1.6,0.4-2.5,0     C520.7,418.9,519.9,418,519.6,416.8z"></path>
                        <path class="st0" d="M517.7,419.5l11.1,5.1l-1.5,3.3l-11.1-5.1L517.7,419.5z M530,425.1l2.5,1.2l-1.5,3.3l-2.5-1.2L530,425.1z"></path>
                        <path class="st0" d="M511.7,431.9l5.9,3.1c0.7,0.4,1.3,0.5,1.7,0.5c0.6,0,1-0.4,1.4-1.1c0.4-0.7,0.4-1.3,0.1-1.8     c-0.2-0.4-0.7-0.8-1.4-1.2l-5.9-3l1.7-3.3l10.9,5.6l-1.6,3l-1.2-0.6l0,0c0.7,1.2,0.7,2.4,0,3.7c-0.6,1.1-1.4,1.9-2.4,2.3     c-0.6,0.3-1.3,0.3-2.1,0.2c-0.6-0.1-1.3-0.4-2-0.7l-6.8-3.5L511.7,431.9z"></path>
                        <path class="st0" d="M504.9,443.8l5.7,3.4c0.7,0.4,1.2,0.6,1.7,0.6c0.6,0,1.1-0.3,1.5-1c0.4-0.6,0.5-1.2,0.2-1.7     c-0.2-0.4-0.6-0.9-1.3-1.3l-5.9-3.5l1.9-3.2l14.1,8.3l-1.9,3.2l-4.5-2.6l0,0c0.3,0.5,0.4,1.1,0.4,1.8c-0.1,0.6-0.3,1.1-0.6,1.7     c-0.7,1.2-1.7,2-2.8,2.2c-0.8,0.1-1.6,0.1-2.3-0.2c-0.3-0.1-0.7-0.3-1-0.5c-0.2-0.1-0.3-0.2-0.5-0.3l-6.4-3.8L504.9,443.8z"></path>
                                            </g>
                        <g>
                        <path class="st0" d="M232,508.8l-3.3-2.6l5.8-15.7l3.2,2.6l-4.5,10.7l0,0l9.5-6.7l3.3,2.6L232,508.8z"></path>
                        <path class="st0" d="M236.7,512.4l7.1-9.6l2.9,2.1l-7.1,9.6L236.7,512.4z M244.6,501.8l1.6-2.2l2.9,2.1l-1.6,2.2L244.6,501.8z"></path>
                        <path class="st0" d="M250.2,510.8l-5.3,7.6l-2.9-2l5.3-7.6l-1.4-1l1.5-2.2l1.4,1l2.3-3.3l2.9,2l-2.3,3.3l1.4,1l-1.5,2.2     L250.2,510.8z"></path>
                        <path class="st0" d="M255.2,524.9l0.7-1.1l0,0c-0.6,0.3-1.2,0.4-2,0.3c-0.6-0.1-1.3-0.3-1.9-0.7c-1.5-0.9-2.4-2.2-2.7-3.9     c-0.3-1.6,0.1-3.2,1-4.7c0.9-1.5,2.2-2.5,3.8-3c1.6-0.5,3.2-0.2,4.7,0.7c0.6,0.4,1.1,0.8,1.5,1.4c0.4,0.6,0.6,1.2,0.6,1.9l0,0     l0.7-1.1l3,1.9l-6.3,10.1L255.2,524.9z M258.8,517.5c-0.2-0.8-0.6-1.4-1.4-1.9c-0.7-0.5-1.5-0.6-2.3-0.4     c-0.8,0.2-1.4,0.7-1.8,1.4c-0.4,0.7-0.6,1.5-0.4,2.2c0.2,0.8,0.6,1.4,1.3,1.9c0.7,0.5,1.5,0.6,2.3,0.4c0.8-0.2,1.4-0.7,1.9-1.4     C258.8,519.1,259,518.3,258.8,517.5z"></path>
                        <path class="st0" d="M267.3,531.6l3-5.8c0.4-0.7,0.5-1.3,0.5-1.7c0-0.6-0.4-1-1-1.4c-0.7-0.4-1.3-0.4-1.8-0.1     c-0.4,0.2-0.8,0.7-1.2,1.4l-3,5.7l-3.2-1.6l5.5-10.6l2.9,1.5l-0.6,1.1l0,0c1.1-0.7,2.3-0.7,3.6,0c1.1,0.6,1.8,1.3,2.2,2.3     c0.3,0.6,0.3,1.3,0.2,2.1c-0.1,0.6-0.4,1.2-0.7,2l-3.4,6.6L267.3,531.6z"></path>
                        <path class="st0" d="M283.4,525.5l-5,11.3l-3.7-1.6l5-11.3l-2.9-1.3l1.4-3.3l9.4,4.2l-1.4,3.3L283.4,525.5z"></path>
                        <path class="st0" d="M291.3,532.5c-0.9-0.3-1.5-0.4-2-0.3c-0.6,0.1-1.1,0.6-1.4,1.4l-2.4,6.1l-3.3-1.3l4.3-11.1l3.1,1.2l-0.5,1.2     l0,0c0.9-0.8,2.1-1,3.4-0.5L291.3,532.5z"></path>
                        <path class="st0" d="M298.1,543.9l0.4-1.2l0,0c-0.5,0.5-1.1,0.7-1.8,0.8c-0.7,0.1-1.3,0-2-0.2c-1.7-0.5-2.9-1.6-3.6-3.1     c-0.7-1.5-0.7-3.1-0.2-4.8c0.5-1.7,1.5-3,2.9-3.8c1.5-0.9,3-1,4.7-0.5c0.7,0.2,1.3,0.5,1.8,1c0.6,0.5,0.9,1,1.1,1.7l0,0l0.4-1.2     l3.4,1.1l-3.5,11.4L298.1,543.9z M299.7,535.9c-0.4-0.7-1-1.2-1.8-1.5c-0.8-0.3-1.6-0.2-2.3,0.2c-0.7,0.4-1.2,1-1.4,1.8     c-0.2,0.8-0.2,1.6,0.2,2.3c0.4,0.7,1,1.2,1.8,1.5c0.8,0.3,1.6,0.2,2.3-0.2c0.7-0.4,1.2-1,1.4-1.8     C300.2,537.4,300.1,536.6,299.7,535.9z"></path>
                        <path class="st0" d="M304.1,545.5l2.8-11.6l3.5,0.8l-2.8,11.6L304.1,545.5z M307.2,532.7l0.6-2.6l3.5,0.8l-0.6,2.6L307.2,532.7z"></path>
                        <path class="st0" d="M317.4,548.3l1.2-6.4c0.1-0.8,0.1-1.4,0-1.8c-0.2-0.5-0.7-0.9-1.4-1c-0.8-0.1-1.3,0-1.7,0.4     c-0.3,0.3-0.6,0.9-0.7,1.7l-1.2,6.4l-3.5-0.6l2.1-11.7l3.3,0.6l-0.2,1.2l0.1,0c0.9-1,2-1.3,3.5-1.1c1.2,0.2,2.1,0.7,2.8,1.6     c0.4,0.5,0.7,1.2,0.8,1.9c0.1,0.6,0,1.3-0.1,2.1l-1.3,7.3L317.4,548.3z"></path>
                        <path class="st0" d="M323.5,549.3l1.4-11.8l3.5,0.4l-1.4,11.8L323.5,549.3z M325.1,536.2l0.3-2.7l3.5,0.4l-0.3,2.7L325.1,536.2z"></path>
                        <path class="st0" d="M337,550.5l0.4-6.5c0-0.8,0-1.4-0.2-1.7c-0.3-0.5-0.7-0.8-1.5-0.8c-0.8,0-1.3,0.2-1.7,0.6     c-0.3,0.4-0.5,1-0.5,1.8l-0.4,6.5l-3.6-0.2l0.7-11.9l3.3,0.2l-0.1,1.3l0.1,0c0.8-1.1,1.9-1.6,3.3-1.5c1.2,0.1,2.2,0.5,3,1.2     c0.5,0.5,0.8,1.1,1,1.8c0.1,0.6,0.2,1.3,0.1,2.1l-0.5,7.4L337,550.5z"></path>
                        <path class="st0" d="M355.7,549.6c0,0.2,0,0.3,0,0.5c0,0.4,0,0.7-0.1,1c-0.1,0.7-0.3,1.4-0.6,1.9c-0.5,0.9-1.4,1.7-2.6,2.2     c-1,0.5-2.2,0.7-3.4,0.7c-1.1,0-2.1-0.1-2.9-0.5c-1-0.4-1.8-1-2.3-1.8c-0.4-0.6-0.7-1.2-0.8-1.8l4.1-0.1c0.4,0.8,1.1,1.2,2.1,1.1     c1.9,0,2.9-1.1,2.8-3.3l0-0.4l0,0c-0.3,0.6-0.7,1-1.4,1.3c-0.6,0.3-1.2,0.4-1.9,0.5c-1.8,0-3.2-0.5-4.4-1.7     c-1.1-1.2-1.7-2.6-1.8-4.4c0-1.8,0.5-3.3,1.5-4.6c1.1-1.3,2.6-2,4.3-2c0.7,0,1.3,0.1,1.9,0.3c0.6,0.3,1.1,0.6,1.5,1.1l0,0l0-1.1     l3.6-0.1L355.7,549.6z M351.2,542.5c-0.6-0.6-1.3-0.9-2.1-0.8c-0.8,0-1.5,0.3-2,0.9c-0.5,0.6-0.8,1.3-0.8,2.1     c0,0.8,0.3,1.6,0.8,2.1c0.6,0.6,1.2,0.9,2.1,0.9c0.9,0,1.6-0.3,2.1-0.9c0.5-0.6,0.8-1.3,0.8-2.1     C352,543.8,351.7,543.1,351.2,542.5z"></path>
                        <path class="st0" d="M362.9,545.4l-0.4-3.5l6-0.7l0.4,3.5L362.9,545.4z"></path>
                        <path class="st0" d="M388.8,543.2c-1.1,1.7-2.8,2.8-5.1,3.4l-5.9,1.3l-1.3-6.2l-1.4,0.3l-0.7-3.5l1.4-0.3l-1.3-5.8l5.2-1.1     c0.8-0.2,1.4-0.3,2-0.3c0.7-0.1,1.3,0,1.9,0c1.3,0.2,2.4,0.8,3.5,1.7c1.2,1.1,2.1,2.6,2.5,4.5     C390.1,539.5,389.8,541.5,388.8,543.2z M384.1,535.2c-0.8-0.7-1.9-0.9-3.1-0.6l-1.7,0.4l0.5,2.4l2.3-0.5l0.7,3.5l-2.3,0.5     l0.6,2.7l1.7-0.4c1.3-0.3,2.2-0.9,2.7-1.8c0.4-0.9,0.5-1.9,0.2-3.3C385.4,536.8,384.8,535.8,384.1,535.2z"></path>
                        <path class="st0" d="M402,541.8l-0.4-1.2l0,0c-0.1,0.6-0.5,1.2-1.1,1.7c-0.5,0.4-1.1,0.7-1.8,0.9c-1.7,0.5-3.3,0.3-4.7-0.5     c-1.4-0.8-2.3-2.1-2.8-3.9c-0.5-1.7-0.4-3.3,0.3-4.8c0.7-1.5,1.9-2.5,3.6-3c0.7-0.2,1.4-0.3,2-0.2c0.7,0.1,1.3,0.4,1.8,0.8l0,0     l-0.4-1.2l3.4-1l3.4,11.4L402,541.8z M397.3,529.4l-5.8-0.7l0.3-2.7l5.8,1.1L397.3,529.4z M398.9,534.2c-0.7-0.4-1.5-0.5-2.3-0.2     c-0.8,0.2-1.4,0.7-1.8,1.5c-0.4,0.7-0.4,1.5-0.2,2.3c0.2,0.8,0.7,1.4,1.4,1.8c0.7,0.4,1.5,0.5,2.3,0.2c0.8-0.2,1.4-0.7,1.8-1.4     c0.4-0.7,0.4-1.5,0.2-2.3C400.1,535.2,399.6,534.6,398.9,534.2z"></path>
                        <path class="st0" d="M417.3,534.8c-0.7,1.6-1.9,2.7-3.7,3.3c-1.7,0.6-3.4,0.6-4.9-0.1c-1.6-0.7-2.7-1.9-3.3-3.6     c-0.6-1.7-0.6-3.3,0.1-4.9c0.7-1.6,1.9-2.7,3.6-3.3c1.7-0.6,3.4-0.6,4.9,0.1c1.6,0.7,2.7,1.9,3.3,3.6     C418,531.6,418,533.3,417.3,534.8z M412.6,529.5c-0.7-0.4-1.5-0.4-2.2-0.1c-0.8,0.3-1.3,0.8-1.6,1.6c-0.3,0.7-0.3,1.5,0,2.3     c0.3,0.8,0.8,1.3,1.5,1.7c0.7,0.4,1.5,0.4,2.2,0.1c0.8-0.3,1.3-0.8,1.6-1.6c0.3-0.7,0.3-1.5,0-2.3     C413.8,530.4,413.3,529.9,412.6,529.5z"></path>
                        <path class="st0" d="M427,522l3.9,8.4l-3.2,1.5l-3.9-8.4l-1.6,0.7l-1.1-2.5l1.6-0.7l-1.7-3.6l3.2-1.5l1.7,3.6l1.6-0.7l1.1,2.5     L427,522z"></path>
                        <path class="st0" d="M441.7,524.9l-0.6-1.1l0,0c0,0.6-0.2,1.3-0.7,1.9c-0.4,0.5-0.9,0.9-1.6,1.3c-1.6,0.8-3.2,1-4.7,0.4     c-1.5-0.6-2.7-1.7-3.5-3.3c-0.8-1.6-1-3.2-0.6-4.7c0.4-1.6,1.4-2.9,3-3.7c0.6-0.3,1.3-0.5,1.9-0.6c0.7-0.1,1.4,0.1,1.9,0.4l0,0     l-0.6-1.1l3.2-1.7l5.5,10.6L441.7,524.9z M437.2,518.1c-0.8-0.2-1.5-0.2-2.3,0.2c-0.8,0.4-1.3,1-1.5,1.8     c-0.2,0.8-0.1,1.5,0.3,2.3c0.4,0.7,1,1.2,1.7,1.5c0.8,0.3,1.5,0.2,2.3-0.2c0.8-0.4,1.3-1,1.5-1.8c0.2-0.8,0.1-1.5-0.3-2.3     C438.6,518.9,438,518.4,437.2,518.1z M440.5,531.4l-1.5-2.9l3-1.6l1.5,2.9L440.5,531.4z"></path>
                        <path class="st0" d="M455.4,515.4c-0.4,1.7-1.4,3-3,3.9c-1.6,0.9-3.2,1.2-4.8,0.8c-1.7-0.4-3-1.4-3.9-2.9     c-0.9-1.6-1.2-3.2-0.8-4.8c0.4-1.7,1.4-3,3-3.9s3.2-1.2,4.9-0.8c1.7,0.4,3,1.4,3.9,2.9C455.6,512.1,455.8,513.7,455.4,515.4z      M449.8,511c-0.8-0.2-1.5-0.1-2.2,0.3c-0.7,0.4-1.1,1-1.3,1.8c-0.2,0.8,0,1.5,0.4,2.2c0.4,0.7,1,1.2,1.8,1.4     c0.8,0.2,1.5,0.1,2.2-0.3c0.7-0.4,1.1-1,1.3-1.8c0.2-0.8,0-1.5-0.4-2.2C451.2,511.7,450.6,511.2,449.8,511z"></path>
                                            </g>
                        <g>
                        <path class="st0" d="M174.6,400.9l1.2,4.1l-14.1,9.9l-1.2-4.1l10-6.4l0-0.1l-11.9,0.1l-1.2-4.1L174.6,400.9z"></path>
                        <path class="st0" d="M159.9,398.8l-2.7,0.7l-0.9-3.6l2.7-0.7L159.9,398.8z M173.1,395.4l-11.9,3l-0.9-3.5l11.9-3L173.1,395.4z"></path>
                        <path class="st0" d="M161.5,387.7l9.2-2l0.8,3.6l-9.2,2l0.4,1.8l-2.7,0.6l-0.4-1.8l-4,0.9l-0.8-3.6l4-0.9l-0.4-1.7l2.7-0.6     L161.5,387.7z"></path>
                        <path class="st0" d="M168.7,374.1l-1.3,0.2l0,0c0.6,0.2,1.2,0.6,1.6,1.3c0.4,0.6,0.6,1.2,0.7,1.9c0.3,1.8-0.1,3.4-1.2,4.7     c-1,1.3-2.5,2.1-4.3,2.4c-1.8,0.3-3.4,0-4.8-0.9c-1.5-0.9-2.3-2.3-2.6-4.1c-0.1-0.7-0.1-1.4,0.1-2.1c0.2-0.7,0.5-1.3,1-1.7l0,0     l-1.3,0.2l-0.6-3.6l12.1-2L168.7,374.1z M160.6,376.3c-0.5,0.7-0.7,1.4-0.5,2.3c0.1,0.9,0.6,1.5,1.3,2c0.7,0.5,1.5,0.6,2.3,0.5     c0.8-0.1,1.5-0.5,2-1.2c0.5-0.7,0.7-1.5,0.5-2.3c-0.1-0.9-0.6-1.5-1.2-2c-0.7-0.5-1.5-0.6-2.3-0.5     C161.7,375.2,161,375.7,160.6,376.3z"></path>
                        <path class="st0" d="M167.2,361l-6.7,0.6c-0.8,0.1-1.4,0.2-1.7,0.5c-0.5,0.3-0.7,0.9-0.6,1.6c0.1,0.8,0.4,1.3,0.9,1.6     c0.4,0.2,1,0.3,1.9,0.2l6.6-0.6l0.3,3.6l-12.2,1.1l-0.3-3.4l1.3-0.1l0-0.1c-1.2-0.6-1.9-1.7-2-3.2c-0.1-1.2,0.2-2.3,0.8-3.2     c0.4-0.6,1-1,1.7-1.3c0.6-0.2,1.3-0.4,2.1-0.5l7.6-0.7L167.2,361z"></path>
                        <path class="st0" d="M160.1,345.8c0.5,0,0.9,0,1.2,0c0.5,0,0.9,0,1.2,0.1c0.9,0.1,1.6,0.4,2.2,0.8c1.4,1.1,2.2,2.6,2.2,4.6     c0,0.8-0.1,1.5-0.4,2.2c-0.4,1.1-1,2-2,2.5c-0.9,0.5-1.9,0.8-3.1,0.8l-0.1-4.4c0.5,0,1-0.1,1.2-0.3c0.4-0.2,0.6-0.5,0.6-1     c0-0.5-0.2-0.9-0.7-1.1c-0.3-0.1-0.8-0.2-1.4-0.2l-10.9,0.2l-0.1-4.2L160.1,345.8z"></path>
                        <path class="st0" d="M165.7,332.9c1.2,1.3,1.7,2.9,1.6,4.8c-0.1,1.9-0.8,3.4-2.1,4.6c-1.3,1.2-2.9,1.7-4.8,1.6     c-1.9-0.1-3.4-0.8-4.5-2.2c-1.2-1.3-1.7-2.9-1.6-4.8c0.1-1.9,0.8-3.4,2.1-4.6c1.3-1.2,2.9-1.7,4.8-1.6     C163.1,330.9,164.6,331.6,165.7,332.9z M158.8,335.1c-0.6,0.5-1,1.2-1,2.1c0,0.8,0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,1     c0.8,0,1.6-0.2,2.2-0.7c0.6-0.5,1-1.2,1-2c0-0.8-0.2-1.6-0.8-2.2c-0.6-0.6-1.3-0.9-2.1-1C160.1,334.4,159.4,334.6,158.8,335.1z"></path>
                        <path class="st0" d="M167.6,318.3c1.1,1.3,1.5,2.9,1.3,4.7c-0.1,0.7-0.3,1.3-0.7,1.9c-0.4,0.7-0.9,1.1-1.5,1.3l0,0l1.3,0.2     l-0.5,3.5l-16.2-2.3l0.5-3.6l5,0.7c-0.8-1-1.1-2.2-0.9-3.7c0.1-0.8,0.4-1.7,1-2.5c0.7-1.1,1.6-1.8,2.7-2.2     c1.1-0.4,2.3-0.5,3.6-0.3C165,316.2,166.5,317,167.6,318.3z M160.4,320.1c-0.7,0.5-1.1,1.2-1.2,2c-0.1,0.9,0.1,1.6,0.6,2.3     c0.5,0.7,1.2,1,2.1,1.2c0.9,0.1,1.6,0,2.3-0.5c0.7-0.5,1.1-1.1,1.2-2s-0.1-1.6-0.6-2.3c-0.5-0.7-1.2-1.1-2.1-1.2     C161.9,319.5,161.1,319.6,160.4,320.1z"></path>
                        <path class="st0" d="M166.2,309.8l-3.5-0.9l1.5-6l3.5,0.9L166.2,309.8z"></path>
                        <path class="st0" d="M176.7,290.2l-1.4,4.1l-17.2-0.3l1.4-4l11.9,0.7l0-0.1l-9.7-6.9l1.4-4.1L176.7,290.2z"></path>
                        <path class="st0" d="M166.2,279.8l-2.6-1.1l1.4-3.4l2.6,1.1L166.2,279.8z M178.7,285l-11.3-4.7l1.4-3.4l11.3,4.7L178.7,285z"></path>
                        <path class="st0" d="M171.1,267.5l-1.6,0.4l0.7,1.5l-1.6,3.2l-1.8-4.7l1-2.1l4.7-1.4L171.1,267.5z M181.6,266.7l-4,8.3     c1.5,0.5,2.6-0.1,3.3-1.6c0.4-0.8,0.4-1.6-0.1-2.5l1.6-3.4c0.9,1,1.5,2.2,1.7,3.4c0.2,1.3,0.1,2.6-0.5,3.8     c-0.8,1.6-2,2.7-3.7,3.3c-1.7,0.6-3.3,0.5-5-0.3c-1.6-0.8-2.7-2-3.3-3.7c-0.6-1.7-0.6-3.4,0.2-5c0.8-1.7,2.1-2.8,3.8-3.3     c1.7-0.5,3.4-0.4,5,0.4C181.1,266.4,181.3,266.5,181.6,266.7z M177.8,268.9c-1.4-0.3-2.4,0.2-3.1,1.6c-0.7,1.4-0.4,2.5,0.7,3.4     L177.8,268.9z M187.3,278.5l-3-1.4l1.5-3.2l3,1.4L187.3,278.5z"></path>
                        <path class="st0" d="M188.9,254.7c1.1,1.1,1.8,2.3,2,3.8c0.2,1.5-0.1,2.9-0.9,4.3c-0.9,1.6-2.2,2.6-4,3.1c-1.7,0.5-3.4,0.2-5-0.7     c-1.6-0.9-2.6-2.2-3-3.9c-0.5-1.7-0.2-3.4,0.7-4.9c0.8-1.4,1.9-2.3,3.3-2.9c1.4-0.6,2.9-0.6,4.4-0.1l-1.9,3.2     c-1.3-0.1-2.2,0.4-2.9,1.5c-0.4,0.7-0.5,1.5-0.2,2.3c0.3,0.7,0.8,1.3,1.5,1.7c0.8,0.4,1.5,0.6,2.3,0.4c0.8-0.2,1.4-0.6,1.8-1.4     c0.6-1,0.6-2.1-0.1-3.1L188.9,254.7z"></path>
                        <path class="st0" d="M197.1,251l-13.3-9.4l2.1-3l13.3,9.4L197.1,251z"></path>
                        <path class="st0" d="M194.7,231.6l-5.3,2.8l-1.3-2.5l5.5-2.4L194.7,231.6z M205.9,239.2l-1-0.8l0,0c0.3,0.6,0.3,1.3,0.1,2     c-0.2,0.6-0.5,1.3-0.9,1.8c-1.1,1.4-2.6,2.2-4.3,2.3c-1.7,0.1-3.2-0.4-4.7-1.6c-1.4-1.1-2.3-2.5-2.6-4.2     c-0.3-1.7,0.1-3.3,1.2-4.7c0.5-0.6,1-1,1.6-1.3c0.7-0.4,1.3-0.5,2-0.4l0,0l-1-0.8l2.3-2.9l9.6,7.6L205.9,239.2z M198.8,234.7     c-0.8,0.1-1.5,0.5-2.1,1.2c-0.5,0.7-0.8,1.5-0.6,2.3c0.1,0.8,0.5,1.5,1.2,2c0.7,0.5,1.4,0.8,2.2,0.7c0.8-0.1,1.5-0.5,2.1-1.1     c0.5-0.7,0.8-1.5,0.7-2.3c-0.1-0.8-0.5-1.5-1.2-2.1C200.4,234.9,199.7,234.6,198.8,234.7z"></path>
                        <path class="st0" d="M219.7,223.9l-4.7-4.5c-0.6-0.6-1.1-0.9-1.6-1.1c-0.6-0.2-1.2,0-1.7,0.6c-0.5,0.5-0.7,1.1-0.6,1.7     c0.1,0.5,0.4,1,1,1.5l4.8,4.6l-2.5,2.7l-4.8-4.6c-0.6-0.5-1.1-0.8-1.5-1c-0.6-0.2-1.1,0-1.6,0.5c-0.5,0.6-0.7,1.2-0.6,1.8     c0.1,0.5,0.5,1,1.1,1.6l4.7,4.5l-2.5,2.7l-8.9-8.4l2.4-2.5l0.9,0.9l0,0c-0.2-0.7-0.2-1.3,0-1.9c0.2-0.6,0.5-1.1,1.1-1.7     c1.1-1.1,2.4-1.5,4-1.1c-0.4-1.7-0.1-3.1,1.1-4.3c0.9-1,2-1.5,3.1-1.5c0.9,0,1.7,0.2,2.4,0.7c0.3,0.2,0.6,0.4,1,0.7     c0.1,0.1,0.3,0.3,0.5,0.4l5.3,5.1L219.7,223.9z"></path>
                                            </g>
                                        </g>
                        <g id="temple-circleBnD">
                        <g>
                        <defs>
                        <path id="SVGID_1_" d="M376.4,151.6h-52.7l-7.7-6.1l6-5l0.3,0.6c0.5,0.4,3.2,2.6,5,4.1h45.4l5.6-4.5l5.7,4.7L376.4,151.6z       M324.1,150.4H376l6.2-5.1l-3.9-3.2l-5.1,4.2h-46.2l-0.2-0.1c-3-2.4-4.4-3.5-5-4l-3.9,3.3L324.1,150.4z" style="fill: #4c4a48;"></path>
                                                </defs>
                        <use xlink:href="#SVGID_1_" style="overflow:visible;fill:#4C4A48;"></use>
                        <clipPath id="SVGID_00000176737529917739180830000000196946533889456002_">
                        <use xlink:href="#SVGID_1_" style="overflow:visible;"></use>
                                                </clipPath>
                                            
                                            </g>
                        <g>
                        <path class="st8" d="M369.3,142.4h-9.1l-0.7-2.4h-18.6l-0.8,2.4H331l5.1-11h7.5l-0.3,1.5H357l-0.4-1.5h7.7L369.3,142.4z      M361.1,141.3h6.4l-4-8.6h-5.4l0.4,1.5h-16.8l0.3-1.5h-5.3l-4,8.6h6.5l0.8-2.4h20.3L361.1,141.3z"></path>
                        <path class="st8" d="M373.3,196.6h-8.8V178l-29.2-0.4v19H327v-25.5l7.2-0.3v-9l-15-0.1l-11.3-9.3l6.3-4.9l8.2,6.6h20.5v16.8     l14.4,0.1v-16.7h20.4l8.2-6.8l6.1,5l-11.2,9.3H366l-0.1,9.3l7.4,0.1L373.3,196.6z M365.7,195.5h6.4l0-1.9v-21.3l-7.4-0.1     l0.1-11.6h15.6l9.8-8.2L386,149l-7.8,6.5h-19.7v16.7l-16.7-0.1v-16.8H322l-7.8-6.3l-4.4,3.4l9.9,8.2l15.7,0.1V172l-7.2,0.3v23.2     h6.1v-19l31.5,0.4V195.5z"></path>
                                            </g>
                                        </g>
                                    </g>
                        <g id="circleBnD-inside">
                        <a href="">
                        <g id="vitantool">
                        <path id="area-part-tool" class="st9" d="M349.4,498.5    c61.9,0,115-37.9,137.3-91.7H212.1C234.4,460.7,287.5,498.5,349.4,498.5z"></path>
                        <path id="line-tool-inside" class="st10" d="M476,387.8    c-15.8,54.8-66.4,94.9-126.3,94.9c-62.3,0-114.5-43.3-128-101.5"></path>
                        <g>
                        <path class="st11" d="M238.5,430.2l-2.2-3.5l10.5-12.7l2.2,3.5l-7.7,8.5l0,0l11-3.1l2.2,3.5L238.5,430.2z"></path>
                        <path class="st11" d="M242.4,435.9l9.4-7l2.1,2.8l-9.4,7L242.4,435.9z M252.8,428.2l2.2-1.6l2.1,2.8l-2.2,1.6L252.8,428.2z"></path>
                        <path class="st11" d="M256,438.6l-7,5.8l-2.2-2.7l7-5.8l-1.1-1.3l2.1-1.7l1.1,1.3l3-2.5l2.2,2.7l-3,2.5l1.1,1.3l-2.1,1.7     L256,438.6z"></path>
                        <path class="st11" d="M257.8,453.8l0.9-0.9l0,0c-0.6,0.2-1.3,0.1-2-0.1c-0.6-0.2-1.1-0.6-1.6-1.1c-1.2-1.3-1.8-2.7-1.7-4.3     c0.1-1.6,0.8-3,2-4.3c1.2-1.2,2.7-1.9,4.3-2c1.7-0.1,3.1,0.5,4.3,1.7c0.5,0.5,0.9,1.1,1.1,1.7c0.3,0.7,0.3,1.3,0.2,1.9l0,0     l0.9-0.9l2.5,2.5l-8.3,8.3L257.8,453.8z M262.9,447.5c0-0.8-0.3-1.5-0.9-2.1c-0.6-0.6-1.3-0.9-2.1-0.9c-0.8,0-1.5,0.3-2.1,0.9     c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9     C262.6,449,262.9,448.3,262.9,447.5z"></path>
                        <path class="st11" d="M268.8,463.3l4-5c0.5-0.6,0.7-1.1,0.8-1.5c0.1-0.6-0.2-1.1-0.7-1.5c-0.6-0.5-1.2-0.6-1.7-0.5     c-0.4,0.1-0.9,0.5-1.4,1.1l-4,5l-2.7-2.2l7.3-9.2l2.6,2l-0.8,1l0,0c1.2-0.4,2.4-0.2,3.5,0.7c0.9,0.7,1.5,1.6,1.7,2.7     c0.1,0.7,0.1,1.3-0.2,2c-0.2,0.5-0.6,1.1-1.1,1.8l-4.6,5.7L268.8,463.3z"></path>
                        <path class="st11" d="M286,460.5l-6.6,10.2l-3.4-2.2l6.6-10.2l-2.6-1.7l1.9-2.9l8.5,5.5l-1.9,2.9L286,460.5z"></path>
                        <path class="st11" d="M292,476.2c-1.6,0.5-3.2,0.3-4.8-0.6c-1.6-0.9-2.6-2.1-3.1-3.7c-0.5-1.6-0.3-3.2,0.5-4.8     c0.9-1.6,2.1-2.6,3.7-3.1c1.6-0.5,3.2-0.3,4.8,0.6c1.6,0.9,2.6,2.1,3.1,3.7c0.5,1.6,0.3,3.2-0.5,4.8     C294.8,474.7,293.6,475.7,292,476.2z M292.9,469.2c-0.2-0.8-0.7-1.4-1.4-1.7c-0.7-0.4-1.4-0.4-2.2-0.2c-0.7,0.3-1.3,0.7-1.7,1.5     c-0.4,0.7-0.5,1.4-0.3,2.2c0.2,0.8,0.7,1.4,1.4,1.7c0.7,0.4,1.4,0.4,2.2,0.2c0.7-0.3,1.3-0.7,1.7-1.5     C293,470.7,293.1,470,292.9,469.2z"></path>
                        <path class="st11" d="M305.8,482c-1.6,0.7-3.2,0.7-4.9,0c-1.7-0.7-2.9-1.8-3.5-3.3c-0.7-1.5-0.7-3.2,0-4.8     c0.7-1.7,1.8-2.8,3.3-3.5c1.6-0.7,3.2-0.7,4.8,0c1.7,0.7,2.9,1.8,3.5,3.3c0.7,1.5,0.7,3.2,0,4.8     C308.5,480.2,307.4,481.4,305.8,482z M305.9,475c-0.3-0.8-0.8-1.3-1.6-1.6c-0.7-0.3-1.5-0.3-2.2,0.1c-0.7,0.3-1.2,0.9-1.5,1.6     c-0.3,0.7-0.3,1.5,0,2.2c0.3,0.7,0.8,1.3,1.5,1.6c0.7,0.3,1.5,0.3,2.2-0.1c0.7-0.3,1.2-0.9,1.5-1.6     C306.2,476.5,306.2,475.8,305.9,475z"></path>
                        <path class="st11" d="M309.6,484.7l4.6-15l3.4,1l-4.6,15L309.6,484.7z"></path>
                        <path class="st11" d="M324.1,481.7l2.8,7.4l-3.9-0.9l-2-6.2l0,0l-1.2,5.5l-3.4-0.8l3.4-15.3l3.4,0.8l-1.8,8l0,0l4.1-3.4l4.1,0.9     L324.1,481.7z"></path>
                        <path class="st11" d="M329.1,489.4l1.7-11.6l3.5,0.5l-1.7,11.6L329.1,489.4z M330.9,476.5l0.4-2.7l3.5,0.5l-0.4,2.7L330.9,476.5z     "></path>
                        <path class="st11" d="M340.6,481.6l-0.8,9.1l-3.5-0.3l0.8-9.1l-1.7-0.2l0.2-2.7l1.7,0.2l0.3-3.9l3.5,0.3l-0.3,3.9l1.7,0.2     l-0.2,2.7L340.6,481.6z"></path>
                        <path class="st11" d="M349.2,486.6l-0.1-3.5l5.9-0.1l0.1,3.5L349.2,486.6z"></path>
                        <path class="st11" d="M374.1,487.5c-0.6,0.7-1.4,1.2-2.2,1.5c-0.4,0.1-0.8,0.2-1.2,0.3c-0.2,0-0.4,0.1-0.7,0.1l-5.7,0.8     l-2.3-15.5l4.8-0.7c0.6-0.1,1.1-0.1,1.4-0.2c0.5,0,0.9-0.1,1.3,0c1,0.1,1.8,0.4,2.5,1c0.7,0.6,1.1,1.4,1.3,2.4     c0.2,1.4-0.2,2.5-1.3,3.4c1.8,0.3,2.8,1.4,3.1,3.2C375.3,485.4,375,486.6,374.1,487.5z M369,477.6c-0.3-0.1-0.6-0.1-1.1-0.1     l-1.5,0.2l0.4,2.4l1.5-0.2c1-0.2,1.5-0.6,1.4-1.5C369.5,478,369.3,477.7,369,477.6z M370.5,483.2c-0.3-0.1-0.8-0.1-1.3-0.1     l-2,0.3l0.4,2.8l2-0.3c0.6-0.1,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.4-1.3C371.1,483.7,370.9,483.4,370.5,483.2z"></path>
                        <path class="st11" d="M388.4,484c-0.9,1.5-2.2,2.4-3.9,2.9c-1.7,0.5-3.3,0.3-4.8-0.6c-1.5-0.8-2.4-2.1-2.9-3.8     c-0.5-1.7-0.3-3.3,0.6-4.8c0.9-1.5,2.2-2.4,3.9-2.9c1.7-0.5,3.4-0.3,4.8,0.6c1.5,0.8,2.4,2.1,2.9,3.9     C389.4,480.9,389.2,482.5,388.4,484z M381.8,473.1l-1.3-0.9l-0.6,1.4l-3.3,0.9l2.3-4.3l2.2-0.6l4,2.6L381.8,473.1z M384.3,478.3     c-0.7-0.4-1.4-0.5-2.2-0.3c-0.8,0.2-1.3,0.7-1.7,1.4c-0.4,0.7-0.4,1.4-0.2,2.2c0.2,0.8,0.6,1.4,1.3,1.8c0.7,0.4,1.4,0.5,2.2,0.3     c0.8-0.2,1.4-0.7,1.7-1.4c0.4-0.7,0.4-1.4,0.2-2.2C385.4,479.3,384.9,478.7,384.3,478.3z M384.1,491.8l-0.8-3.1l3.2-0.9l0.8,3.1     L384.1,491.8z"></path>
                        <path class="st11" d="M408.9,472.4c0.3,1.4,0.1,2.8-0.6,4.1c-0.7,1.3-1.7,2.2-3,2.9c-1.6,0.7-3.2,0.8-4.8,0.2     c-1.6-0.6-2.8-1.7-3.5-3.3c-0.7-1.6-0.8-3.2-0.2-4.8c0.6-1.6,1.7-2.8,3.3-3.5c1.4-0.6,2.8-0.8,4.2-0.4c1.4,0.3,2.6,1.1,3.5,2.3     l-3.2,1.5c-0.9-0.8-1.9-0.9-3.1-0.4c-0.7,0.3-1.2,0.9-1.4,1.6c-0.2,0.7-0.1,1.4,0.2,2.2c0.4,0.8,0.9,1.3,1.6,1.6     c0.7,0.3,1.5,0.3,2.2-0.1c1-0.5,1.6-1.3,1.7-2.5L408.9,472.4z"></path>
                        <path class="st11" d="M412.2,459.9l-1.5-0.5l-0.2,1.6l-2.9,1.8l1-4.7l2-1.2l4.5,1.4L412.2,459.9z M421.5,468.5     c-0.4,1.6-1.4,2.9-3,3.9c-1.5,0.9-3.1,1.2-4.8,0.8c-1.7-0.4-2.9-1.4-3.9-2.9c-0.9-1.5-1.2-3.1-0.8-4.8c0.4-1.6,1.4-2.9,2.9-3.9     c1.5-0.9,3.1-1.2,4.8-0.8c1.7,0.4,2.9,1.4,3.9,2.9C421.7,465.3,421.9,466.8,421.5,468.5z M416,464.2c-0.8-0.2-1.5-0.1-2.2,0.3     c-0.7,0.4-1.1,1-1.3,1.8c-0.2,0.8,0,1.5,0.4,2.2c0.4,0.7,1,1.1,1.8,1.4c0.8,0.2,1.5,0.1,2.2-0.3c0.7-0.4,1.1-1,1.3-1.8     c0.2-0.8,0-1.5-0.4-2.2C417.3,464.8,416.7,464.4,416,464.2z"></path>
                        <path class="st11" d="M432.1,462.5l-3.9-5.1c-0.5-0.6-0.9-1-1.3-1.2c-0.5-0.2-1.1-0.1-1.6,0.3c-0.6,0.5-0.9,1-0.9,1.5     c0,0.5,0.3,1,0.8,1.6l3.9,5.1l-2.8,2.1l-7.1-9.3l2.6-2l0.8,1l0,0c-0.1-1.3,0.4-2.4,1.5-3.3c0.9-0.7,2-1.1,3-1     c0.7,0,1.3,0.3,1.9,0.7c0.5,0.3,1,0.8,1.5,1.5l4.5,5.8L432.1,462.5z"></path>
                        <path class="st11" d="M445.8,449c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.5,0.5,0.6,0.8c0.4,0.6,0.7,1.2,0.9,1.8     c0.2,1,0.1,2.1-0.4,3.3c-0.4,1-1.1,2-2,2.8c-0.8,0.7-1.6,1.3-2.4,1.6c-1,0.4-1.9,0.5-2.9,0.3c-0.7-0.1-1.3-0.4-1.8-0.8l2.9-2.8     c0.8,0.3,1.6,0.1,2.3-0.6c1.4-1.3,1.3-2.8-0.2-4.3l-0.3-0.3l0,0c0.2,0.6,0.1,1.2-0.1,1.9c-0.2,0.6-0.6,1.1-1,1.6     c-1.2,1.2-2.7,1.8-4.3,1.7c-1.6-0.1-3-0.8-4.2-2c-1.2-1.3-1.9-2.7-1.9-4.3c-0.1-1.7,0.5-3.1,1.8-4.3c0.5-0.5,1-0.8,1.5-1     c0.6-0.3,1.2-0.3,1.8-0.2l0,0l-0.8-0.8l2.5-2.4L445.8,449z M437.8,446.9c-0.8,0-1.5,0.2-2.1,0.8c-0.6,0.6-0.9,1.2-0.9,2     c0,0.8,0.3,1.5,0.8,2c0.6,0.6,1.3,0.9,2,1c0.8,0.1,1.5-0.2,2.1-0.8c0.6-0.6,0.9-1.3,0.9-2c0-0.8-0.3-1.5-0.9-2.1     C439.2,447.3,438.6,446.9,437.8,446.9z"></path>
                        <path class="st11" d="M456.8,429.4c1,1.1,1.5,2.4,1.5,3.9c0,1.4-0.4,2.8-1.3,4c-1,1.4-2.4,2.2-4.1,2.5c-1.7,0.3-3.3-0.1-4.7-1.2     c-1.4-1-2.2-2.4-2.5-4.1c-0.3-1.7,0.1-3.2,1.2-4.6c0.9-1.2,2.1-2,3.5-2.4c1.4-0.4,2.8-0.3,4.2,0.4l-2.1,2.9     c-1.2-0.2-2.2,0.1-2.9,1.1c-0.5,0.7-0.6,1.4-0.5,2.1c0.2,0.7,0.6,1.3,1.2,1.8c0.7,0.5,1.4,0.7,2.1,0.6c0.8-0.1,1.4-0.5,1.9-1.1     c0.7-0.9,0.8-1.9,0.3-3L456.8,429.4z"></path>
                        <path class="st11" d="M465.8,423l-1.1-0.6l0,0c0.4,0.6,0.5,1.1,0.5,1.7c0,0.5-0.2,1.1-0.6,1.7c-0.8,1.3-1.8,2-3,2.2     c-0.7,0.1-1.3,0.1-1.9-0.1c-0.4-0.1-1-0.4-1.7-0.8l-6.1-3.5l1.8-3l5.6,3.3c0.7,0.4,1.2,0.6,1.6,0.5c0.5,0,1-0.4,1.3-1     c0.7-1.2,0.3-2.1-1.1-2.9l-5.6-3.2l1.8-3l10.2,5.9L465.8,423z M468.5,428.6l-2.8-1.6l1.7-2.9l2.8,1.6L468.5,428.6z"></path>
                                                </g>
                                            </g>
                                        </a>
                        <a href="">
                        <g id="vitanedu">
                        <rect x="220.8" y="324.4" class="st0" width="258.3" height="54"></rect>
                        <g id="main-text-vitanedu">
                                                    
                        <linearGradient id="SVGID_00000148619426565466652950000010468321477018572945_" gradientUnits="userSpaceOnUse" x1="-531.3596" y1="890.3234" x2="-531.2649" y2="890.3234" gradientTransform="matrix(305.7926 -427.0008 -427.0008 -305.7926 542884.625 45720.4648)">
                        <stop offset="0" style="stop-color:#653614"></stop>
                        <stop offset="9.488110e-02" style="stop-color:#653614"></stop>
                        <stop offset="0.134" style="stop-color:#703E18"></stop>
                        <stop offset="0.1786" style="stop-color:#834A1F"></stop>
                        <stop offset="0.1911" style="stop-color:#7D451C"></stop>
                        <stop offset="0.2337" style="stop-color:#6C3815"></stop>
                        <stop offset="0.2659" style="stop-color:#663313"></stop>
                        <stop offset="0.57" style="stop-color:#4F3315"></stop>
                        <stop offset="0.7219" style="stop-color:#4F3315"></stop>
                        <stop offset="1" style="stop-color:#4F3315"></stop>
                                                    </linearGradient>
                        <polygon style="fill:url(#SVGID_00000148619426565466652950000010468321477018572945_);" points="266,330.7 269.8,330.7      274.1,324.4 274.1,324.4 270.2,324.4 270.2,324.4 262.6,324.4 260,328.3 243.4,352.9 233.7,338.6 222.3,338.6 237.7,361.3      241.5,367 247.2,358.5 266,330.7    "></polygon>
                        <rect x="409.9" y="363.2" class="st13" width="4.2" height="4.2"></rect>
                        <polygon class="st14" points="339.6,367.4 339.6,339 355.3,339 355.3,345.3 346.5,345.3 346.5,349.8 355.1,349.8 355.1,356.1      346.5,356.1 346.5,361 355.3,361 355.3,367.4    "></polygon>
                        <path class="st14" d="M375.3,367v-2.3h-0.1c-0.6,1.1-1.5,1.8-2.8,2.3c-1,0.4-2.2,0.7-3.6,0.7c-3.2,0-5.8-1.1-7.8-3.3     c-1.9-2.2-2.9-4.8-2.9-8.1c0-3.2,1-5.8,2.9-8c2-2.2,4.6-3.4,7.7-3.4c2.5,0,4.6,0.9,6.3,2.6v-8.9h6.4V367H375.3z M373.8,352.4     c-1-1-2.3-1.5-3.8-1.5c-1.6,0-2.8,0.5-3.9,1.5c-1,1-1.6,2.3-1.6,3.8c0,1.6,0.5,2.9,1.5,3.9c1,1.1,2.3,1.6,3.9,1.6     c1.6,0,2.9-0.5,3.9-1.6c1-1,1.5-2.3,1.5-3.9C375.3,354.7,374.8,353.5,373.8,352.4"></path>
                        <path class="st14" d="M399.3,367v-2.3h-0.1c-0.5,1.1-1.3,1.9-2.3,2.3c-0.9,0.4-1.9,0.6-3.2,0.6c-2.7,0-4.8-0.9-6.3-2.7     c-0.8-1-1.3-2-1.6-3.1c-0.2-0.8-0.3-2-0.3-3.5v-12.8h6.4v11.8c0,1.4,0.2,2.4,0.6,3c0.5,0.8,1.5,1.2,2.8,1.2     c2.4,0,3.7-1.4,3.7-4.3v-11.7h6.4V367H399.3z"></path>
                        <path class="st13" d="M432.2,361.6c-0.4,1.7-1.3,3.2-2.7,4.2c-1.4,1.1-3,1.6-4.9,1.6c-2.2,0-4-0.7-5.5-2.2     c-1.5-1.5-2.3-3.3-2.3-5.4c0-2.1,0.7-3.9,2.2-5.4c1.5-1.5,3.3-2.2,5.5-2.2c1.9,0,3.5,0.5,5,1.6c1.4,1.1,2.4,2.6,2.7,4.3h-4.4     c-0.6-1.3-1.7-2-3.2-2c-1,0-1.8,0.4-2.5,1.1c-0.6,0.7-0.9,1.5-0.9,2.5c0,1,0.3,1.9,0.9,2.6c0.6,0.7,1.5,1.1,2.5,1.1     c1.4,0,2.5-0.6,3.1-1.9H432.2z"></path>
                        <path class="st13" d="M447.1,365.3c-1.5,1.4-3.4,2.2-5.6,2.2c-2.2,0-4.1-0.7-5.6-2.2c-1.5-1.4-2.2-3.2-2.2-5.4     c0-2.2,0.7-4,2.2-5.4c1.5-1.4,3.3-2.2,5.6-2.2c2.2,0,4.1,0.7,5.6,2.2c1.5,1.4,2.2,3.2,2.2,5.4C449.3,362,448.6,363.8,447.1,365.3      M444,357.3c-0.7-0.7-1.5-1.1-2.5-1.1c-1,0-1.8,0.4-2.5,1.1c-0.7,0.7-1,1.5-1,2.5c0,1,0.3,1.8,1,2.5c0.7,0.7,1.5,1.1,2.5,1.1     c1,0,1.8-0.4,2.5-1.1c0.7-0.7,1-1.5,1-2.5C445,358.9,444.7,358,444,357.3"></path>
                        <path class="st13" d="M469.5,367v-7.6c0-1-0.1-1.7-0.4-2.2c-0.3-0.7-1-1-1.9-1c-0.9,0-1.5,0.3-1.9,0.9c-0.3,0.5-0.5,1.2-0.5,2.1     v7.8h-4.3v-7.7c0-0.9-0.1-1.6-0.4-2c-0.3-0.6-0.9-0.9-1.8-0.9c-0.9,0-1.6,0.3-2,1c-0.3,0.5-0.5,1.2-0.5,2.2v7.6h-4.3v-14.2h4.1     v1.5h0.1c0.4-0.7,1-1.2,1.7-1.5c0.7-0.3,1.4-0.5,2.3-0.5c1.9,0,3.3,0.8,4.2,2.5c1.1-1.7,2.6-2.5,4.6-2.5c1.6,0,2.9,0.5,3.8,1.4     c0.7,0.7,1.2,1.6,1.4,2.6c0.1,0.4,0.1,0.9,0.2,1.4c0,0.2,0,0.5,0,0.8v8.6H469.5z"></path>
                        <rect x="266" y="345.7" class="st13" width="6.4" height="21.3"></rect>
                        <polygon class="st13" points="284.2,350.5 284.2,367 277.8,367 277.8,350.5 274.6,350.5 274.6,345.7 277.8,345.7 277.8,338.6      284.2,338.6 284.2,345.7 287.2,345.7 287.2,350.5    "></polygon>
                        <path class="st13" d="M305.6,367v-2.3h-0.1c-0.5,1-1.4,1.8-2.7,2.3c-1.1,0.5-2.3,0.7-3.5,0.7c-3.2,0-5.8-1.1-7.8-3.3     c-1.9-2.1-2.9-4.8-2.9-8.1c0-3.2,1-5.9,2.9-8c2-2.2,4.6-3.4,7.8-3.4c1.3,0,2.4,0.2,3.5,0.7c1.2,0.5,2.1,1.3,2.7,2.3h0.1v-2.3h6.4     V367H305.6z M304.1,352.4c-1-1-2.3-1.5-3.8-1.5c-1.5,0-2.8,0.5-3.8,1.6c-1,1-1.5,2.3-1.5,3.9c0,1.5,0.5,2.7,1.5,3.8     c1,1,2.3,1.6,3.8,1.6c1.5,0,2.8-0.5,3.8-1.6c1-1,1.5-2.3,1.5-3.9C305.6,354.8,305.1,353.5,304.1,352.4"></path>
                        <path class="st13" d="M329.5,367v-11.6c0-1.4-0.2-2.4-0.6-3.1c-0.5-0.9-1.4-1.3-2.7-1.3c-1.4,0-2.4,0.4-2.9,1.3     c-0.5,0.7-0.7,1.8-0.7,3.2V367h-6.4v-21.3h5.9v2.3h0.1c1.2-2,3.2-3,5.8-3c2.2,0,4,0.6,5.4,1.9c0.9,0.8,1.6,1.8,2,3.1     c0.3,1,0.5,2.3,0.5,3.7V367H329.5z"></path>
                        <rect x="266" y="338.6" class="st13" width="6.4" height="5.3"></rect>
                                                </g>
                        <rect x="228.4" y="371.7" class="st15" width="247.9" height="1.2"></rect>
                                            </g>
                                        </a>
                        <a href="">
                        <g id="vitannet">
                        <path id="area-part-net" class="st9" d="M349.4,201.3    c-64.8,0-119.9,41.4-140.2,99.3h280.4C469.3,242.8,414.2,201.3,349.4,201.3z"></path>
                        <g>
                        <path class="st16" d="M239.3,291l-1.7,3.4l-15.3-2.2l1.7-3.4l10.5,1.9l0,0l-7.9-7.2l1.7-3.4L239.3,291z"></path>
                        <path class="st16" d="M231.5,280l-2.2-1.3l1.7-2.8l2.2,1.3L231.5,280z M241.9,286.2l-9.4-5.6l1.7-2.8l9.4,5.6L241.9,286.2z"></path>
                        <path class="st16" d="M239.8,273.5l7.1,4.7l-1.8,2.7l-7.1-4.7l-0.9,1.4l-2.1-1.4l0.9-1.4l-3.1-2l1.8-2.7l3.1,2l0.9-1.3l2.1,1.4     L239.8,273.5z"></path>
                        <path class="st16" d="M253.5,269.4l-0.9-0.7l0,0c0.2,0.5,0.3,1.2,0.1,1.8c-0.2,0.6-0.4,1.1-0.8,1.7c-1,1.3-2.3,2-3.8,2.1     c-1.5,0.1-2.9-0.4-4.2-1.4c-1.3-1-2.1-2.3-2.3-3.7c-0.3-1.5,0.1-2.9,1.1-4.2c0.4-0.5,0.9-0.9,1.4-1.2c0.6-0.3,1.2-0.4,1.8-0.4     l0,0l-0.9-0.7l2-2.6l8.6,6.8L253.5,269.4z M247.1,265.4c-0.7,0.1-1.4,0.4-1.8,1.1c-0.5,0.6-0.7,1.3-0.6,2.1     c0.1,0.7,0.5,1.3,1.1,1.8c0.6,0.5,1.3,0.7,2,0.6c0.8-0.1,1.4-0.4,1.8-1c0.5-0.6,0.7-1.3,0.6-2c-0.1-0.7-0.5-1.3-1.1-1.8     C248.5,265.5,247.8,265.3,247.1,265.4z"></path>
                        <path class="st16" d="M261.7,260.4l-4.3-4.2c-0.5-0.5-1-0.8-1.4-0.9c-0.5-0.1-1,0.1-1.5,0.5c-0.5,0.5-0.7,1-0.6,1.5     c0.1,0.4,0.4,0.9,0.9,1.4l4.3,4.2l-2.3,2.4l-7.9-7.6l2.1-2.2l0.8,0.8l0,0c-0.3-1.2,0-2.2,1-3.2c0.8-0.8,1.7-1.3,2.6-1.4     c0.6-0.1,1.2,0.1,1.9,0.4c0.5,0.3,1,0.6,1.5,1.2l4.9,4.8L261.7,260.4z"></path>
                        <path class="st16" d="M272.4,250.7l-10-3.3l0,0l5.8,6.8l-2.8,2.3l-9.4-11.3l2.5-2.1l10.2,3.5l0-0.1l-6-6.9l2.8-2.3l9.4,11.3     L272.4,250.7z"></path>
                        <path class="st16" d="M283.3,237.7l-6.9,4.5c0.9,1,2,1.1,3.3,0.3c0.7-0.4,1-1.1,1.1-2l2.8-1.9c0.2,1.2,0.1,2.4-0.3,3.4     c-0.4,1.1-1.1,2-2.1,2.7c-1.3,0.9-2.8,1.2-4.4,0.8c-1.6-0.3-2.8-1.1-3.7-2.5c-0.9-1.3-1.2-2.8-0.9-4.4c0.3-1.6,1.1-2.8,2.4-3.7     c1.4-0.9,2.9-1.2,4.4-0.8c1.5,0.4,2.8,1.2,3.7,2.6C283,237.1,283.2,237.4,283.3,237.7z M279.4,237.6c-0.9-0.9-2-1-3.1-0.2     c-1.2,0.8-1.5,1.8-1,2.9L279.4,237.6z"></path>
                        <path class="st16" d="M286.5,231.3l4.1,7.4l-2.9,1.6l-4.1-7.4l-1.4,0.8l-1.2-2.2l1.4-0.8l-1.8-3.2l2.9-1.6l1.8,3.2l1.4-0.8     l1.2,2.2L286.5,231.3z"></path>
                        <path class="st16" d="M295.8,231.6l-1.3-3l5.1-2.2l1.3,3L295.8,231.6z"></path>
                        <path class="st16" d="M320.5,227.3l-2.8-10.6l0,0l-0.1,11.4l-2.4,0.7l-5.9-9.8l0,0l3,10.5l-3.3,0.9l-3.9-14.1l4.6-1.3l5.4,8.5     l0,0l0.2-10.1l4.6-1.3l3.9,14.1L320.5,227.3z"></path>
                        <path class="st16" d="M334.1,224.9l-0.2-1.2l0,0c-0.2,0.6-0.6,1-1.2,1.4c-0.5,0.3-1.1,0.5-1.8,0.6c-1.6,0.2-3-0.1-4.2-1.1     c-1.1-1-1.8-2.3-2.1-3.9c-0.2-1.6,0.1-3.1,0.9-4.3c0.9-1.3,2.1-2.1,3.7-2.3c0.7-0.1,1.3-0.1,1.8,0.1c0.7,0.2,1.2,0.5,1.5,1l0,0     l-0.2-1.2l3.3-0.5l1.6,10.9L334.1,224.9z M332.2,217.6c-0.6-0.4-1.3-0.6-2.1-0.5c-0.8,0.1-1.4,0.5-1.8,1.1     c-0.4,0.6-0.6,1.3-0.5,2.1c0.1,0.8,0.5,1.4,1.1,1.8c0.6,0.5,1.3,0.6,2,0.5c0.8-0.1,1.4-0.5,1.8-1.1c0.4-0.6,0.6-1.3,0.5-2.1     C333.2,218.7,332.8,218,332.2,217.6z M331,230.1l-0.4-3l3.1-0.4l0.4,3L331,230.1z"></path>
                        <path class="st16" d="M346.2,224l-0.2-6c0-0.7-0.2-1.2-0.4-1.6c-0.3-0.4-0.8-0.6-1.4-0.6c-0.7,0-1.2,0.3-1.5,0.7     c-0.2,0.4-0.3,0.9-0.3,1.7l0.2,6l-3.3,0.1l-0.4-11l3.1-0.1l0,1.2l0,0c0.6-1.1,1.6-1.6,2.9-1.7c1.1,0,2.1,0.2,2.8,0.8     c0.5,0.4,0.9,0.9,1.1,1.6c0.2,0.5,0.3,1.2,0.3,1.9l0.3,6.9L346.2,224z"></path>
                        <path class="st16" d="M362.9,223.7c0,0.2,0,0.3,0,0.5c0,0.3-0.1,0.7-0.1,0.9c-0.1,0.7-0.4,1.2-0.7,1.7c-0.6,0.8-1.4,1.4-2.6,1.8     c-1,0.3-2,0.5-3.2,0.4c-1-0.1-1.9-0.3-2.7-0.6c-0.9-0.4-1.5-1-2-1.8c-0.3-0.6-0.5-1.1-0.6-1.7l3.8,0.2c0.3,0.7,0.9,1.1,1.8,1.2     c1.8,0.1,2.7-0.8,2.9-2.8l0-0.3l0,0c-0.3,0.5-0.8,0.9-1.4,1.1c-0.5,0.2-1.1,0.3-1.7,0.3c-1.6-0.1-2.9-0.7-3.9-1.9     c-1-1.2-1.4-2.5-1.3-4.2c0.1-1.7,0.7-3,1.8-4.1c1.1-1.1,2.5-1.6,4.1-1.5c0.6,0,1.2,0.2,1.7,0.4c0.6,0.3,1,0.7,1.3,1.2l0,0l0.1-1     l3.3,0.2L362.9,223.7z M359.3,216.9c-0.5-0.6-1.1-0.9-1.9-0.9c-0.7,0-1.4,0.2-1.9,0.7c-0.5,0.5-0.8,1.1-0.9,1.9     c0,0.8,0.2,1.5,0.6,2c0.5,0.6,1.1,0.9,1.8,1c0.8,0,1.5-0.2,2-0.6c0.5-0.5,0.8-1.1,0.9-1.9C360,218.1,359.8,217.4,359.3,216.9z"></path>
                        <path class="st16" d="M377.4,227.1l1.3-5.9c0.2-0.7,0.2-1.2,0-1.6c-0.2-0.5-0.6-0.8-1.2-1c-0.7-0.2-1.2,0-1.6,0.3     c-0.3,0.3-0.6,0.8-0.7,1.5l-1.3,5.8l-3.2-0.7L373,215l3,0.6l-0.2,1.1l0,0c0.8-0.9,1.9-1.2,3.2-0.9c1.1,0.2,1.9,0.7,2.5,1.5     c0.4,0.5,0.6,1.1,0.7,1.8c0,0.5,0,1.2-0.2,1.9l-1.4,6.7L377.4,227.1z"></path>
                        <path class="st16" d="M393.7,231c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.3-0.2,0.6-0.4,0.9c-0.3,0.6-0.7,1.1-1.1,1.5     c-0.7,0.7-1.7,1-2.9,1.1c-1.1,0.1-2.1,0-3.2-0.4c-1-0.3-1.8-0.7-2.4-1.3c-0.7-0.6-1.2-1.4-1.5-2.2c-0.2-0.6-0.2-1.2-0.1-1.8     l3.6,1.2c0.1,0.8,0.6,1.3,1.5,1.6c1.7,0.6,2.9-0.1,3.5-2l0.1-0.3l0,0c-0.4,0.4-1,0.6-1.6,0.7c-0.6,0.1-1.2,0-1.8-0.2     c-1.5-0.5-2.6-1.5-3.3-2.9c-0.6-1.4-0.7-2.8-0.2-4.4c0.5-1.6,1.4-2.7,2.7-3.5c1.4-0.8,2.8-0.9,4.4-0.4c0.6,0.2,1.1,0.5,1.5,0.8     c0.5,0.4,0.8,0.9,0.9,1.4l0,0l0.3-1l3.1,1L393.7,231z M391.9,223.5c-0.3-0.7-0.8-1.1-1.6-1.4c-0.7-0.2-1.4-0.2-2.1,0.2     c-0.6,0.3-1.1,0.9-1.3,1.6c-0.2,0.7-0.2,1.4,0.1,2.1c0.3,0.7,0.8,1.2,1.5,1.4c0.8,0.2,1.4,0.2,2.1-0.1c0.6-0.3,1.1-0.9,1.3-1.6     C392.2,224.9,392.2,224.2,391.9,223.5z"></path>
                        <path class="st16" d="M401.5,235.4l2.4-5.5c0.3-0.6,0.4-1.2,0.3-1.6c-0.1-0.5-0.4-1-1-1.2c-0.6-0.3-1.1-0.3-1.6,0     c-0.4,0.2-0.7,0.6-1,1.3l-2.5,5.6l-3-1.3l6-13.4l3,1.3l-1.9,4.3l0,0c0.4-0.3,1-0.5,1.6-0.5c0.5,0,1,0.1,1.6,0.4     c1.2,0.5,1.9,1.3,2.2,2.3c0.2,0.7,0.2,1.4,0.1,2.1c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.1-0.1,0.3-0.2,0.5l-2.7,6.1L401.5,235.4z"></path>
                        <path class="st16" d="M418,239.1l-7.1-4.1c-0.5,1.3-0.1,2.3,1.2,3.1c0.7,0.4,1.4,0.4,2.2,0.1l2.9,1.7c-1,0.7-2.1,1.2-3.2,1.3     c-1.2,0.1-2.3-0.1-3.4-0.7c-1.4-0.8-2.3-2-2.7-3.5c-0.4-1.5-0.2-3,0.6-4.4c0.8-1.4,2-2.3,3.5-2.8c1.6-0.5,3-0.3,4.4,0.5     c1.4,0.8,2.4,2,2.7,3.6c0.4,1.5,0.1,3-0.7,4.5C418.3,238.7,418.2,238.9,418,239.1z M416.3,235.6c0.4-1.2,0-2.2-1.2-2.9     c-1.2-0.7-2.2-0.6-3.1,0.4L416.3,235.6z M417.9,229.7l-0.2-1.4l-1.4,0.5l-2.8-1.6l4.3-1.3l1.9,1.1l0.9,4.3L417.9,229.7z      M421.3,228.5l-3.2-4.3l2-1.5l2.9,4.6L421.3,228.5z"></path>
                        <path class="st16" d="M427.4,251.5l3.7-4.7c0.4-0.6,0.7-1,0.7-1.4c0.1-0.5-0.2-1-0.7-1.4c-0.6-0.4-1.1-0.6-1.6-0.4     c-0.4,0.1-0.8,0.5-1.3,1.1l-3.7,4.7l-2.6-2l6.8-8.6l2.4,1.9l-0.7,0.9l0,0c1.1-0.4,2.2-0.2,3.3,0.6c0.9,0.7,1.4,1.5,1.6,2.5     c0.1,0.6,0.1,1.2-0.2,1.9c-0.2,0.5-0.5,1.1-1,1.7l-4.2,5.4L427.4,251.5z"></path>
                        <path class="st16" d="M440.2,262.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.2-0.5,0.4-0.7,0.6c-0.5,0.4-1.1,0.7-1.6,0.9     c-1,0.3-2,0.2-3.1-0.3c-1-0.4-1.9-1-2.7-1.7c-0.7-0.7-1.3-1.4-1.6-2.2c-0.4-0.9-0.5-1.8-0.4-2.7c0.1-0.6,0.3-1.2,0.7-1.7l2.7,2.6     c-0.3,0.7-0.1,1.5,0.6,2.1c1.3,1.2,2.6,1.1,4-0.3l0.2-0.2l0,0c-0.6,0.2-1.1,0.2-1.7,0c-0.6-0.2-1.1-0.5-1.5-0.9     c-1.2-1.1-1.7-2.5-1.7-4c0-1.5,0.6-2.8,1.7-4c1.2-1.2,2.5-1.8,4-1.9c1.6-0.1,2.9,0.4,4.1,1.5c0.4,0.4,0.8,0.9,1,1.4     c0.3,0.6,0.3,1.2,0.2,1.7l0,0l0.7-0.7l2.4,2.3L440.2,262.1z M441.9,254.6c0-0.7-0.3-1.4-0.8-1.9c-0.5-0.5-1.2-0.8-1.9-0.7     c-0.7,0-1.4,0.3-1.9,0.9c-0.5,0.6-0.8,1.2-0.9,1.9c0,0.7,0.2,1.4,0.8,1.9c0.6,0.6,1.2,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.9     C441.6,256,441.9,255.3,441.9,254.6z"></path>
                        <path class="st16" d="M445.4,269.5l4.6-3.8c0.5-0.4,0.9-0.9,1-1.3c0.2-0.5,0-1-0.4-1.5c-0.4-0.5-0.9-0.7-1.4-0.7     c-0.4,0-0.9,0.3-1.4,0.7l-4.7,3.9l-2.1-2.5l11.2-9.4l2.1,2.5l-3.6,3l0,0c0.5-0.1,1.1,0,1.6,0.2c0.5,0.2,0.9,0.6,1.3,1     c0.8,1,1.2,2,1,3.1c-0.1,0.7-0.4,1.3-0.9,1.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.1,0.1-0.2,0.2-0.4,0.4l-5.1,4.3L445.4,269.5z"></path>
                        <path class="st16" d="M448.7,273.7l8.9-6.5l1.9,2.7l-8.9,6.5L448.7,273.7z M458.5,266.6l2-1.5l1.9,2.7l-2,1.5L458.5,266.6z"></path>
                        <path class="st16" d="M449.8,283.6l2.5-1.6l1.7,2.7l-2.5,1.6L449.8,283.6z M461.5,284.9l-4.4-7c-1.1,0.9-1.2,2-0.4,3.3     c0.4,0.7,1.1,1.1,1.9,1.2l1.8,2.9c-1.2,0.2-2.4,0.1-3.4-0.3c-1.1-0.4-2-1.2-2.6-2.2c-0.8-1.4-1.1-2.8-0.7-4.4     c0.4-1.6,1.2-2.8,2.6-3.6c1.4-0.9,2.8-1.1,4.4-0.8c1.6,0.3,2.8,1.2,3.7,2.5c0.9,1.4,1.1,2.9,0.7,4.5c-0.4,1.5-1.3,2.7-2.7,3.6     C462,284.7,461.7,284.8,461.5,284.9z M461.7,281c0.9-0.9,1-1.9,0.3-3.1s-1.7-1.6-2.9-1.1L461.7,281z M465.9,276.6l0.5-1.4     L465,275l-1.7-2.7l4.4,0.9l1.1,1.8l-1.2,4.3L465.9,276.6z"></path>
                        <path class="st16" d="M464.2,297.5c-1.5-0.4-2.6-1.3-3.2-2.7c-0.6-1.2-0.7-2.4-0.2-3.6l-4.2,2l-1.4-3l13.2-6.4l1.3,2.8l-1.1,0.5     l0,0.1c1.3,0.3,2.3,1.1,2.9,2.4c0.7,1.5,0.8,2.9,0.1,4.3c-0.6,1.3-1.6,2.4-3.1,3.1C467.1,297.7,465.6,297.8,464.2,297.5z      M468.6,292.4c0.3-0.7,0.2-1.4-0.1-2.1c-0.3-0.7-0.9-1.2-1.6-1.4c-0.7-0.2-1.4-0.2-2.1,0.2c-0.7,0.3-1.2,0.9-1.4,1.6     c-0.3,0.7-0.2,1.4,0.1,2.1c0.3,0.7,0.9,1.2,1.6,1.4c0.7,0.2,1.4,0.2,2.1-0.1C467.9,293.7,468.3,293.1,468.6,292.4z"></path>
                                                </g>
                        <path id="line-net-inside" class="st17" d="M222.8,312.5    c15.8-54.8,66.4-94.9,126.3-94.9c62.3,0,114.5,43.3,128,101.5"></path>
                                            </g>
                                        </a>
                        <g id="cover-arrow-inside_">
                        <polygon id="cover-tool-2" class="st0" points="456.8,385.9 469,404.8     491.1,398.7 493.4,387.1 458.5,378.4   "></polygon>
                        <polygon id="cover-tool-1" class="st0" points="210.7,411.5 221.1,390.9     247.7,396.2 245,382.1 243.2,376.1 203,381.3   "></polygon>
                        <polygon id="cover-net-2_" class="st0" points="490,296.2 481.2,317.7     454.5,313.4 458,336 498.1,338.6 497.4,319.8   "></polygon>
                        <polygon id="cover-net-1" class="st0" points="204.8,307.1 226.3,302     240.7,318.6 238.6,334.3 207.2,327.9   "></polygon>
                                        </g>
                                    </g>
                                    </svg>


                    </div>
                    <div class="circle circle-banner-hst-big"></div>
                    <div class="circle circle-banner-hst-fit"></div>
                    <div class="circle circle-banner-hst-small"></div>
                </div>
                <h2>{{ $tBnd("system_slogan") }}</h2>
            </div>
            <div class="col-md-6 col-lg-5 box-r">
                <div class="inner">
                    <!-- html nhập tài khoản cần lấy lại -->
                    <div class="identity-acc" v-if="step == 1">
                        <div class="custom-input">
                            <input type="text" id="ipt-emailorphone" name="username" class="ipt" v-model="userInfo.username" @keyup.enter="checkAndSendOTP">
                            <label for="ipt-emailorphone" class="lbl">{{$tBnd('email_or_phonenumber')}}</label>
                        </div>
                        <div class="btn-area">
                            <button type="button" class="btn" @click="checkAndSendOTP"> {{ $tBnd("register_next_step") }}</button>
                        </div>
                        <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="cancel">{{ $tBnd("cancel") }}</a>
                    </div>


                    <!-- html form lấy lại mật khẩu -->
                    <div class="form-retrievalPass-login" v-else-if="step == 2">
                        <span class="txt-lbl mb-4"> {{ $tBnd("account") }}: <b>{{ userInfo.username }}</b></span>
                        <div class="custom-input" v-if="step == 2">
                            <input type="text" id="ipt-emailorphone" class="ipt" v-model="otp" @keyup.enter="checkOTP">
                            <label for="ipt-emailorphone" class="lbl">{{$tBnd('otp_input')}}</label>
                            <!--<a href="javascript:;" class="resend-authen-code disable" v-if="seconds > 0">
                        {{ $tBnd("otp_resend_in") }}
                        <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                    </a>
                    <a href="javascript:;" class="resend-authen-code" @click="resendOTP" v-else>
                        {{$tBnd('register_resend_otp') }}
                    </a>-->
                            <otpCountDown @sendCode="resendOTP()" :seconds="seconds" :isSendOtp="isSendOtp" :userName="userInfo.username" />
                        </div>
                        <div class="btn-area">
                            <button type="button" class="btn" @click="checkOTP"> {{ $tBnd("check") }}</button>
                        </div>
                    </div>
                    <div class="form-retrievalPass-login" v-else-if="step == 3">
                        <div class="custom-input">
                            <input-password v-model="userInfo.password" @enter="updatePassword" :label="$tBnd('password')" />
                        </div>
                        <!-- [D] Thêm class "is-err" vào class "custom-input" khi xuất hiện trạng thái error input-->
                        <div class="custom-input">
                            <input-password v-model="userInfo.rePassword" @enter="updatePassword" :label="$tBnd('repassword')" />
                        </div>
                        <!--<div class="txt-mess ml-0 text-left">Mật khẩu không trùng khớp</div>-->

                        <div class="btn-area">
                            <button type="button" @click="updatePassword" class="btn"> {{ $tBnd("update") }}</button>
                        </div>
                        <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="cancel">{{ $tBnd("cancel") }}</a>
                    </div>
                    <!--<p class="txt-register">{{ $tBnd("login_call_to_action_msg") }} <router-link to="/login" class="txt-link">{{ $tBnd("login_now") }}</router-link></p>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import otpCountDown from "@/components/common/otp-countdown";
    export default {
        components: { otpCountDown },
        data() {
            return {
                userInfo: {
                    username: '',
                },
                otp: '',
                seconds: 0,
                step: 1,
                showPass1: false,
                showPass2: false,
                isSendOtp: false
            };
        },
        created() {
            this.checkUserProcessRegist();
        },

        methods: {
            checkUserProcessRegist() {
                const cookieUserName = this.$cookie.get(this.$cookieConstants.FOGOT_PASWORD_USER_NAME);
                if (cookieUserName) {
                    this.userInfo.username = cookieUserName;
                    const $loading = this.$loading.show();
                    this.$axios
                        .post(this.$api.ACCOUNT_CHECK_PROCESS_FOGOT_PASSWORD, {
                            username: this.userInfo.username,
                        })
                        .then((data) => {
                            $loading.hide();
                            if (!data.result) {
                                this.$cookie.delete(this.$cookieConstants.FOGOT_PASWORD_USER_NAME);
                            } else {
                                this.step = 2;
                                this.setTimerOTP(data.result.times);
                            }
                        })
                        .catch((ex) => {
                            $loading.hide();
                            this.$cookie.delete(this.$cookieConstants.FOGOT_PASWORD_USER_NAME);
                        });
                }
            },
            checkAndSendOTP() {
                if (!this.userInfo.username) {
                    return this.$error(this.$tBnd('error_username_empty'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_FOGOT_PASSWORD, {
                        username: this.userInfo.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$tBnd('enter_security_code'));
                        this.setTimerOTP(data.result.times);
                        this.step = 2;
                        this.$cookie.set(this.$cookieConstants.FOGOT_PASWORD_USER_NAME, this.userInfo.username);
                    })
                    .catch((ex) => {
                        console.log(ex.response);
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        console.log(error);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            setTimerOTP(seconds) {
                this.seconds = seconds;
                this.isSendOtp = true;
                //this.intervalTimer = setInterval(() => {
                //    if (this.seconds <= 0) {
                //        clearInterval(this.intervalTimer);
                //    }

                //    this.seconds--;
                //}, 1000);
            },
            resendOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_RESEND_OTP_FOGOT_PASSWORD, {
                        username: this.userInfo.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.setTimerOTP(data.result.times);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            checkOTP() {
                if (!this.otp) {
                    return this.$error(this.$tBnd('otp_required'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_FOGOT_PASSWORD_OTP, {
                        username: this.userInfo.username,
                        otp: (!isNaN(this.otp) ? Number(this.otp) : 0) + "",
                    })
                    .then((data) => {
                        console.log(data);
                        $loading.hide();
                        this.userInfo.token = data.message;
                        this.step = 3;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            updatePassword() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_RESET_PASSWORD, this.userInfo)
                    .then((_) => {
                        $loading.hide();
                        this.$router.push('/');
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$tBnd(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            cancel() {
                this.$cookie.delete(this.$cookieConstants.ACCOUNT_RESEND_OTP_FOGOT_PASSWORD);
                this.$router.push('/');
            },
        },
    };
</script>
