<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box info-pay">
                    <div class="box-head">
                        <h2>{{$tBnd('order')}}</h2>
                    </div>
                </div>
                <div class="box lst-pay">
                    <div class="block-ct-talbe">
                        <table class="tbl-pay">
                            <thead>
                                <tr>
                                    <th>
                                        {{$tBnd('order_code')}}
                                    </th>
                                    <th>
                                        <div class="selbox" :class="{'open': isSearchOpen}" v-click-outside="hideSelect">
                                            <button type="button" class="btn-selbox" @click="isSearchOpen = true"> {{$tBnd('status')}}</button>
                                            <div class="selbox-lst-area" style="width:175px;">
                                                <ul class="selbox-lst">
                                                    <li>
                                                        <button type="button" class="sel-value" @click="search(null)">Tất cả</button>
                                                    </li>
                                                    <li v-for="item in convertDictToList($constants.orderStatus)" @click="search(item.value)" v-if="item.value != 4">
                                                        <button type="button" class="sel-value">
                                                            <span>{{item.label}}</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        {{$tBnd('time')}}
                                    </th>
                                    <th>
                                        {{$tBnd('amount_temp')}}
                                    </th>
                                    <th>
                                        {{$tBnd('payment')}}
                                    </th>
                                    <th>
                                        {{$tBnd('payment_method')}}
                                    </th>
                                    <th class="text-center">
                                        #
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="item in listData">
                                    <tr :key="item.id">
                                        <td> {{ item.id }}</td>
                                        <td><span class="status-dot" :class="`status-dot-${item.status}`"></span> {{ getStatus(item.status) }}</td>
                                        <td><span>{{item.createdDate | moment(fullDateFormat)}}</span></td>
                                        <td>  {{ formatCurrency(item.amount) }}</td>
                                        <td>  {{ formatCurrency(item.amount - item.discount) }}</td>
                                        <td>{{ getPaymentType(item.paymentMethod, item.paymentGatewayType) }}</td>
                                        <td>
                                            <ul class="d-flex justify-content-end">
                                                <li class="icon">
                                                    <button type="button" class="btn-drop" @click="detail(item.id)">
                                                        <bnd-web-static-img src="id/icon-edit.svg" alt="" />
                                                    </button>
                                                </li>
                                                <!--<li class="icon">
                                        <button type="button" class="btn-drop">
                                            <bnd-web-static-img src="id/icon-doccument.svg" alt="" />
                                        </button>
                                    </li>-->
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr class="no-border" :key="`key_${item.id}`"></tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="btn-area mt-4">
                        <button type="button" class="btn-view-more" v-if="total > filter.pageIndex * filter.pageSize" @click="pageChange()">{{$tBnd('view_more')}}</button>
                    </div>
                </div>
            </div>
            <div v-if="showDetail">
                <div class="modal fade popup show" id="popup-xt-security">
                    <div class="modal-dialog modal-dialog-centered" style="width:1000px; max-width:1000px">
                        <div class="modal-content">
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title">{{$tBnd("order_detail")}} #{{detailData.id}}</h5>
                            </div>
                            <div class="modal-body type2">
                                <table class="tbl-pay w-100">
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                #
                                            </th>
                                            <th>
                                                {{$tBnd('product')}}
                                            </th>
                                            <th>
                                                {{$tBnd('block')}}
                                            </th>
                                            <th>
                                                {{$tBnd('quantity')}}
                                            </th>
                                            <th class="text-left">
                                                {{$tBnd('price')}}
                                            </th>
                                            <th class="text-left">
                                                {{$tBnd('discountPrice')}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, i) in detailData.orderDetails">
                                            <tr :key="item.id">
                                                <td>{{i+1}}</td>
                                                <td><a :href="item.url" target="_blank">{{item.title}}</a></td>
                                                <td><p><bnd-web-block :value="item.blockId" :display="true" /> - {{item.objectTypeName}}</p></td>
                                                <td>{{ item.quantity }}</td>
                                                <td class="text-left">
                                                    <span class="price">{{ formatCurrency(item.price) }}</span>
                                                </td>
                                                <td class="text-left">{{ formatCurrency(item.discountPrice) }}
                                                </td>
                                            </tr>
                                            <tr class="no-border" :key="`key_${item.id}`"></tr>
                                        </template>
                                        <tr class="no-border">
                                            <td colspan="6" class="text-right">
                                                <span class="amount">
                                                    <span> {{$tBnd('amount_temp')}}:</span>  <b>{{ formatCurrency(detailData.amount) }}</b>
                                                    <span> {{$tBnd('payment')}}:</span>  <b>{{ formatCurrency(detailData.amount - detailData.discount) }}</b>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light" data-dismiss="modal" @click="closePopup()">{{$tBnd("close")}}</button>
                                <a :href="`${$config.paymentApiUrl}/checkout/payment/${detailData.id}`" target="_blank" class="" v-if="detailData.status == 0">
                                    <button type="button" class="btn btn-complete" data-dismiss="modal" @click="closePopup()">{{$tBnd("payment")}}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal16336021507332popupBackdrop" class="modal-backdrop fade show"></div>
            </div>
        </div>
    </main>
</template>
<style scoped>
    .tbl-pay tbody tr td, .tbl-pay thead th {
        font-size: 15px;
    }

    .amount span {
        min-width: 75px;
        display: inline-block;
        margin-left: 30px;
    }

    .selbox-lst-area {
        overflow: hidden;
    }

        .selbox-lst-area li:first-child {
            border-radius: 15px 15px 0 0;
        }

        .selbox-lst-area li:last-child {
            border-radius: 0 0 15px 15px;
        }

    .price {
        text-decoration: line-through;
        color: #808080;
        padding-top: 5px;
    }

    .status-dot {
        position: relative;
        top: -2px;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        vertical-align: middle;
        border-radius: 50%;
    }

    .status-dot-0 {
        background-color: #808080;
    }

    .status-dot-1 {
        background-color: #52c41a;
    }

    .status-dot-2 {
        background-color: #ff0000;
    }

    .status-dot-3 {
        background-color: #52c41a;
    }

    .status-dot-4 {
        background-color: #52c41a;
    }

    .status-dot-5 {
        background-color: #52c41a;
    }
</style>
<script>
    import vClickOutside from "v-click-outside";
    export default {
        directives: {
            clickOutside: vClickOutside.directive,
        },
        layout: 'manager',
        metaInfo: {
            title: 'Đơn hàng',
            titleTemplate: '%s | VitanID'
        },
        middleware: 'authenticated',
        data() {
            return {
                filter: {
                    pageIndex: 1,
                    pageSize: 10,
                },
                total: 0,
                listData: [],
                detailData: {},
                showDetail: false,
                isSearchOpen: false
            };
        },
        created() {
            this.getData();
        },
        methods: {
            pageChange(page) {
                this.filter.pageIndex++;
                this.getData();
            },
            search(stt) {
                this.filter.status = stt;
                if (+stt === this.$constants.orderStatusEnum.Paid)
                    this.filter.listStatus = [stt, this.$constants.orderStatusEnum.PaidAuto];
                else
                    this.filter.listStatus = [];
                this.filter.pageIndex = 1;
                this.getData();
                this.isSearchOpen = false;
            },
            hideSelect() {
                this.isSearchOpen = false;
            },
            getPaymentType(method, type) {
                let title = method? this.$constants.paymentMethod[method]:'[Không xác định]';
                if (type) { title = `[${this.$constants.paymentGatewayType[type]}] ${title}`; }
                return title;
            },
            getStatus(stt) {
                return this.$constants.orderStatus[stt];
            },
            getData() {
                //if (
                //    this.filter.createdDate &&
                //    this.filter.createdDate.length > 0
                //) {
                //    this.filter.dateFrom = this.filter.createdDate[0]
                //    this.filter.dateTo = this.filter.createdDate[1]
                //} else {
                //    this.filter.dateFrom = null
                //    this.filter.dateTo = null
                //}
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.GET_LIST_ORDER, this.filter)
                    .then((response) => {
                        $loading.hide();
                        if (this.filter.pageIndex > 1)
                            this.listData.push(...response.result.data);
                        else
                            this.listData = response.result.data;
                        this.total = response.result.totalRows;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        this.$error(error);
                    });
            },
            detail(id) {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.GET_DETAIL_ORDER, { params: { id } })
                    .then((response) => {
                        $loading.hide();
                        this.detailData = response.result;
                        this.showDetail = true;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        this.$error(error);
                    });
            },
            closePopup() {
                this.detailData = null,
                    this.showDetail = false;
            }
        },
    };
</script>
