import apiUrl from '@/constants/api'
import axios from '../../plugins/axios'
export default {
    state: () => ({
        menuList: [
            {
                title: 'Trang chủ',
                url: '/',
                name: 'home',
                icon: 'setting',
                hidden: true
            },
            {
                title: 'Danh mục',
                url: '',
                icon: 'folder',
                subMenus: [
                    {
                        title: 'Nhóm danh mục',
                        url: '/category/classify',
                        name: 'Category',
                        icon: 'folder'
                    },
                    {
                        title: 'Danh mục dùng chung',
                        url: '/category',
                        name: 'Category',
                        icon: 'folder',
                    },
                    {
                        title: 'Danh mục theo khối',
                        url: '/category/block',
                        name: 'Categoryblock',
                        icon: 'folder-open',
                    }
                ]
            }
        ],
        appSettings: null
    }),
    mutations: {
        INIT_SYSTEM: (state, data) => {
            state.appSettings = data;
        }
    },
    actions: {
        initSystem({ state, commit }) {
            return new Promise((resolve, reject) => {
                return axios.get(apiUrl.SYSTEM_INIT_DATA).then(res => {
                    commit('INIT_SYSTEM', res.result);
                    return resolve(res.result);
                }).catch(err => {
                    commit('INIT_SYSTEM', {});
                    return reject(err);
                });
            })
        }
    },
    getters: {
        menuList: state => state.menuList,
        appSettings: state => state.appSettings
    }
}
