<template>
    <main :class="{'content-page': !iframe}">
        <div class="container">
            <div class="main-ct">
                <div class="box type2 box-addr">
                    <div class="box-head" v-if="!iframe">
                        <h2>{{ $tBnd("address_manager") }}</h2>
                    </div>
                    <ul class="box-ct lst-ct">
                        <li v-if="addressDefault">
                            <div class="info-addr d-flex flex-column">
                                <strong class="txt-name">
                                    {{ addressDefault.name }}
                                    <span>
                                        {{
                    $tBnd("address_default")
                                        }}
                                    </span>
                                </strong>
                                <span class="txt-addr">
                                    {{ $tBnd("address_details") }}:
                                    <em>{{ addressDefault.address }}</em>
                                </span>
                                <span class="txt-phone">
                                    {{ $tBnd("address_phone") }}:
                                    <em>{{ addressDefault.phoneNumber }}</em>
                                </span>
                            </div>
                            <div class="lst-btn d-flex">
                                <a href="javascript:void(0);" v-if="iframe"
                                   @click="select(addressDefault)"
                                   class="itm-btn btn-select">{{ $tBnd("select") }}</a>
                                <a href="javascript:void(0);"
                                   @click="editAddress(addressDefault.id)"
                                   class="itm-btn itm-link">{{ $tBnd("edit") }}</a>
                            </div>
                        </li>
                        <li v-for="item in _.orderBy(listAddress.filter(o=>!o.isDefaultAddress), ['id'], 
             ['desc'])" :key="item.id">
                            <div class="info-addr d-flex flex-column">
                                <strong class="txt-name">
                                    {{ item.name }}
                                    <span v-if="item.isDefaultAddress">
                                        {{
                    $tBnd("address_default")
                                        }}
                                    </span>
                                </strong>
                                <span class="txt-addr">
                                    {{ $tBnd("address_address") }}:
                                    <em>{{ item.address }}</em>
                                </span>
                                <span class="txt-phone">
                                    {{ $tBnd("address_phone") }}:
                                    <em>{{ item.phoneNumber }}</em>
                                </span>
                            </div>
                            <div class="lst-btn d-flex">
                                <a href="javascript:void(0);" v-if="iframe"
                                   @click="select(item)"
                                   class="itm-btn btn-select">{{ $tBnd("select") }}</a>
                                <a href="javascript:void(0);"
                                   @click="editAddress(item.id)"
                                   class="itm-btn itm-link">{{ $tBnd("edit") }}</a>
                                <a href="javascript:void(0);"
                                   v-if="!item.isDefaultAddress"
                                   @click="deleteAddress(item.id)"
                                   class="btn-delete">
                                    {{ $tBnd("address_delete") }}
                                </a>
                            </div>
                        </li>
                    </ul>
                    <a href="javascript:void(0);"
                       @click="createAddress"
                       class="add-new-addr"><i class="plus">+</i> {{ $tBnd("address_add") }}</a>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        props: ['iframe'],
        metaInfo: {
            title: 'Sổ địa chỉ',
            titleTemplate: '%s | VitanID'
        },
        data() {
            return {
                isLoaded: false,
                listAddress: [],
            };
        },
        computed: {
            backUrl() {
                return this.iframe ? "/address/" : "/account/address/";
            },
            addressDefault() {
                return this.listAddress.find(o => o.isDefaultAddress);
            }
        },
        created() {
            this.getAddress();
        },
        methods: {
            getAddress() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.USER_ADDRESS_GET_ALL}`)
                    .then((data) => {
                        this.listAddress = data.result;
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            createAddress() {
                this.$router.push(this.backUrl + "0");
            },
            editAddress(id) {
                this.$router.push(this.backUrl + id);
            },
            select(item) {
                if (typeof (window) != undefined)
                    window.parent.postMessage({ event: 'address', data: item }, '*');
            },
            deleteAddress(id) {
                this.$confirm(
                    this.$tBnd("address_confirm_delete"),
                    this.$tBnd("address_confirm_confirm"),
                    this.$tBnd("address_confirm_cancel")
                ).then(() => {
                    const $loading = this.$loading.show();
                    this.$axios
                        .delete(`${this.$api.USER_ADDRESS_DELETE}?userAddressId=${id}`)
                        .then((response) => {
                            $loading.hide();
                            this.$success(this.$tBnd("address_noti_delete"));
                            this.getAddress();
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                });
            },
        },
    };
</script>
<style scoped>
    .box-addr li .itm-btn {
        margin-left: 10px;
        padding: 5px 9px;
        background: #F3F4F6;
        font-size: 16px;
        line-height: 22px;
        color: #222222;
    }

    .box-addr li .btn-select {
        background: #663300;
        color: #fff;
        border-radius: 5px;
    }
</style>
