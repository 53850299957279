/* Định nghĩa các cookie name */
export const cookieConstant = {
    ACCESS_TOKEN: 'bnd.ce.id.access_token',
    REFRESH_TOKEN: 'bnd.ce.id.refesh_token',
    TYPE_TOKEN: 'bnd.ce.id.token_type',
    REGISTER_USER_NAME: 'bnd.ce.id.register_username',
    FOGOT_PASWORD_USER_NAME: 'bnd.ce.id.fogotpassword_username',
    LANGUAGE_CODE: 'bnd.ce.language.code',
    LOGIN_INFO: 'bnd.ce.login.info'
};

