
export const KYC_STATUS = {
    NotVerified : 0,
    Wait : 1, //Chờ duyệt
    Verified : 2, //Đã xác minh
    Refuse : 3//Bị từ chối
}

export const paymentMethod = {
    'AUTO': 'Thanh toán tự động',
    'COD': 'Thanh toán khi nhận hàng',
    'ATM_ONLINE': 'Thẻ ATM nội địa/Internet Banking',
    'VISA': 'Thanh toán bằng thẻ quốc tế Visa, Master, JCB',
    'BANK_TRANSFER': 'Chuyển khoản ngân hàng'
}
export const paymentGatewayType = {
    'VNPay': 'VNPay',
    'ZaloPay': 'ZaloPay',
    'Momo': 'Momo'
}

export const orderStatusEnum = {
    Wait: 0, //Chờ thanh toán
    Failure: 2, //Thất bại
    Paid: 3,//Đã thanh toán,
    PaidAuto: 4//Đã thanh toán tự động
}
export const orderStatus = {
    0: 'Chờ thanh toán',
    // 1: '',
    2: 'Thất bại',
    3: 'Đã thanh toán',
    4: 'Đã thanh toán'
}

export const commonConfig = {
    MaximumEmailOrPhone: 3//số lượng email/phone được thêm tối đa
}
