import Vue from 'vue';
import { SUPPORTED_LOCALES } from '../locales/supported-locales';
export const commonMixin = {
    data() {
        return {
        }
    },
    computed: {
        appSettings() {
            return this.$store.state.system.appSettings;
        },
        dateFormatByLang() {
            const locale = SUPPORTED_LOCALES.find((c) => c.code == (this.languageCode ? this.languageCode : 'vi-VN'));
            if (locale) return locale.dateFormat;
        },
        fullDateFormat() {
            const locale = SUPPORTED_LOCALES.find((c) => c.code == (this.languageCode ? this.languageCode : 'vi-VN'));
            if (locale) return locale.fullDateFormat;
        }
    },
    methods: {
        copyText(value) {
            navigator.clipboard.writeText(value);
        },
        invalidPhone(val) {
            var regex = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/
            return !regex.test(val)
        },
        formatCurrency(value, moneyFormat) {
            const format = moneyFormat || 'đ'
            if (value) { return `${value} ${format}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
            else return `0 ${format}`
        },
        convertDictToList(dict) {
            const arr = [];
            for (const key in dict) {
                arr.push({ value: key, label: dict[key] });
            }
            return arr;
        },
        $tBnd(key) {
            let result = key
            if (this.appSettings.staticLanguage && this.appSettings.staticLanguage[this.languageCode]) {
                result = this.appSettings.staticLanguage[this.languageCode][key] || key;
            }
            return result
        },
        nl2br(str) {
            if (!str) return str;
            return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        checkPassword(inputtxt) {
            var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,200}$/;
            if (inputtxt.match(paswd)) {
                return true;
            }
            else {
                return false;
            }
        }
    }
};
Vue.mixin(commonMixin);
